















































































































































import { ref, defineComponent } from '@vue/composition-api';

import {Subject} from 'rxjs';
import {switchMap, debounceTime} from 'rxjs/operators';

import { useHttp } from '@/components/services/http.service';
import {useProfessionProvider} from '@/components/services/profession-provider.service';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import TableFilter from '@/components/common/forms/TableFilter.vue';
import DatatableCheckbox from '@/components/common/forms/DatatableCheckbox.vue';
import AddOffer from '@/views/offers/AddOffer.vue';


export default defineComponent({
    name: 'offersOverview',
    setup() {
        const professionProvider = ref();
        const http = ref();
        professionProvider.value = useProfessionProvider();
        http.value = useHttp();
        return { professionProvider, http };
    },
    components: {
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
        'datatable-checkbox': DatatableCheckbox,
        'add-offer': AddOffer,
    },
    mounted() {
        this.filters = this.filterDefs;

        this.searchQuery$ = new Subject().pipe(
            debounceTime(400),
        );
        this.searchQuerySubscription = this.searchQuery$.subscribe((query: string) => {
            this.searchQuery = query;
            this.items$.next(1);
        });

        this.items$ = new Subject().pipe(
            switchMap((page) => {
                this.isLoading = true;
                this.currentPage = page;
                this.allSelected = false;
                return this.http.post('/offers/filters', {
                     'offset': this.currentPage * this.rowsPerPage - this.rowsPerPage,
                     'limit': this.rowsPerPage,
                     'sortBy': this.sortBy,
                     'sortDir': this.sortDesc ? 'desc' : 'asc',
                     'filterInput': this.filterInput,
                     'searchQuery': this.searchQuery,
                });
            }));
        this.itemsSubscription = this.items$.subscribe({
            next: (response: any) => {
                this.numTotalRows = response.data['data']['num_total_rows'];
                this.items = response.data['data']['rows'];
                this.$refs.userTable.clearSelected();
                this.isLoading = false;
            }, error: () => {
                this.isLoading = false;
            }
        });
        this.items$.next(1);
    },
    data() {
        return {
            items$: null,
            itemsSubscription: null,
            searchQuery$: null,
            searchQuerySubscription: null,
            searchQuery: '',
            isLoading: false,
            currentPage: 1,
            sortBy: 'id',
            sortDesc: true,
            rowsPerPage: 10,
            numTotalRows: null,
            items: [],
            filterInput: {},
            filters: [],
            filterListExpanded: false,
            allSelected: false,
            actions: [
            ],
            fields: [
                {
                    key: 'selected',
                },
                {
                    key: 'id',
                    label: 'ID',
                    sortable: true,
                },
                {
                  key: 'application',
                  label: 'Einsatzgebiet',
                  sortable: true,
                },
                {
                  key: 'utilizationTimeShort',
                  label: 'Einsatzzeitraum',
                  sortable: true,
                },
                {
                  key: 'agentUser',
                  label: 'Zuständiger Benutzer',
                  sortable: true,
                },
                {
                    key: 'customer',
                    label: 'Kunde',
                    sortable: true,
                },
                {
                    key: 'contactPerson',
                    label: 'Ansprechpartner',
                    sortable: true,
                },
                {
                  key: 'professionCategory',
                  label: 'Berufsgruppe',
                  sortable: true,
                },
                {
                  key: 'created',
                  label: 'Erstellt',
                  sortable: true,
                },
                {
                    key: 'action',
                    label: 'Aktion',
                    sortable: false,
                },
            ],
            filterDefs: [
                {
                    title: 'ID',
                    type: 'text',
                    prop: 'id',
                },
                {
                    title: 'Einsatzgebiet',
                    type: 'text',
                    prop: 'application',
                },
                {
                  title: 'Einsatzzeitraum',
                  type: 'text',
                  prop: 'utilizationTimeShort',
                },
                {
                  title: 'Zuständiger Benutzer',
                  type: 'text',
                  prop: 'agentUser',
                },
                {
                    title: 'Kunde',
                    type: 'text',
                    prop: 'customer',
                },
                {
                    title: 'Ansprechpartner',
                    type: 'text',
                    prop: 'contactPerson',
                },
                {
                  title: 'Berufsgruppe',
                  type: 'select',
                  prop: 'professionCategory',
                  placeholder: 'Bitte auswählen',
                  options: (this as any).professionProvider.getProfessionCategoriesAsOptions(),
                },
                {
                  title: 'Erstellt',
                  type: 'date',
                  prop: 'created',
                },
            ],
        }
    },
    methods: {
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.items$.next(this.currentPage);
        },
        rowsPerPageChanged() {
            this.items$.next(1);
        },
        onPageChange(page) {
            this.items$.next(page);
        },
        filter(value) {
            Object.assign(this.filterInput, value);
            this.items$.next(1);
        },
        selectOrClearAll() {
            if (this.allSelected) {
                this.$refs.userTable.clearSelected();
            } else {
                this.$refs.userTable.selectAllRows();
            }
        },
        selectOrUnselect(row) {
            if (!row.rowSelected) {
                row.selectRow();
            } else {
                row.unselectRow();
                this.allSelected = false;
            }
        },
        toggleFilterList() {
            this.filterListExpanded = !this.filterListExpanded;
        },
        onOfferAdded() {
            this.sortBy = 'id';
            this.items$.next(1);
        }
    }
});
