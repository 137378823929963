





































































import {ref, defineComponent} from '@vue/composition-api';


import {useHttp} from '@/components/services/http.service';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import DatatableCheckbox from '@/components/common/forms/DatatableCheckbox.vue';
import {getNotifier} from "@/helpers";
import {useProfessionProvider} from "@/components/services/profession-provider.service";


export default defineComponent({
    name: 'offerMailingPoolTable',
    setup() {
        const professionProvider = ref();
        const http = ref();
        professionProvider.value = useProfessionProvider();
        http.value = useHttp();
        return {professionProvider, http};
    },
    components: {
        'transition-expand': TransitionExpand,
        'datatable-checkbox': DatatableCheckbox,
    },
    props: {
        parentLoading: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            selfLoading: true,
            isSending: false,
            selectItems: [],
            selectedPools: [],
            fields: [
                {
                    key: 'selected',
                },
                {
                    key: 'poolName',
                    label: 'Pool',
                },
                {
                    key: 'action',
                    label: 'Aktion',
                    sortable: false,
                },
            ],
            allSelected: false,
            swalSendOfferFirstData: [
                "Angebot versenden?",
                "Wollen Sie das Angebot an den Pool versenden?",
                "Versenden"
            ],
            swalSendOfferAgainData: [
                "Angebot erneut versenden?",
                "Das Angebot wurde bereits an diesen Pool versendet. Wollen Sie das Angebot erneut an den Pool versenden?",
                "Versenden"
            ],
        }
    },
    mounted() {
        this.notifier = getNotifier();
        this.syncMailingPools();
        this.getMailingPoolOptions();
    },
    methods: {
        unselectAll(){
            this.$refs.poolTable.clearSelected();
            this.allSelected = false;
        },
        selectOrClearAll() {
            if (this.allSelected) {
                this.$refs.poolTable.clearSelected();
            } else {
                this.$refs.poolTable.selectAllRows();
            }
        },
        selectOrUnselect(row) {
            if (!row.rowSelected) {
                row.selectRow();
            } else {
                row.unselectRow();
                this.allSelected = false;
            }
        },

        getSelectedRows() {
            const selectedRows = [];
            this.tableItems.forEach((item, index) => {
                if (this.$refs.poolTable.isRowSelected(index)) {
                    selectedRows.push(index);
                }
            });
            return selectedRows;
        },
        areRowsSelected(){
            return this.getSelectedRows().length !== 0;
        },
        getSelectedIds() {
            const selectedRows = this.getSelectedRows();
            const ids = [];
            for (const selectedRow of selectedRows) {
                ids.push(this.tableItems[selectedRow]['poolId']);
            }
            return ids;
        },
        async syncMailingPools(){
            this.selfLoading = true;
            return this.http.post('/offers/mailingpools/sync').then((response) => {
                if(response.data.failure)
                    this.notifier.displayError('CleverReach API', response.data.failure);
                this.selfLoading = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.selfLoading = false;
            });
        },
        async getMailingPoolOptions(){
            this.selfLoading = true;
            const selectItems = [];
            const selectSentItems: any = [{
                header: 'Bereits gesendet'
            }];
            let last = null;
            return this.http.get('/offers/'+ this.$route.params.offerId +'/mailingpools').then(response => {
                response.data['data'].forEach(obj => {
                    if(obj.sent){
                        selectSentItems.push({
                            name: obj.poolName,
                            value: [obj.id, obj.poolName, obj.sent]
                        });
                        return;
                    }
                    if(last !== null && last !== obj.refType){
                        selectItems.push({
                            divider: true
                        });
                    }else{
                        selectItems.push({
                            name: obj.poolName,
                            value: [obj.id, obj.poolName, obj.sent]
                        });
                    }
                    last = obj.refType;
                });
                this.selectItems = [...selectItems, ...selectSentItems];
                this.selfLoading = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.selfLoading = false;
            });
        },
        sendOfferToSelectedItems() {
            this.sendOfferToIds(this.getSelectedIds())
        },
        async sendOfferToIds(poolIds){
            this.selfLoading = true;
            this.isSending = true;
            return this.http.post('/offers/'+ this.$route.params.offerId +'/mailingpools/send', {
                'ids': poolIds
            }).then((response) => {
                if(response.data.failure){
                    this.notifier.displayError('CleverReach API', response.data.failure);
                }else{
                    this.unselectAll();
                    // Update selected items by resetting them and reincluding the unsent ones
                    const oldSelectedPoolIds = this.selectedPools.map(value => {
                        return value[0];
                    });
                    this.getMailingPoolOptions().then(()=>{
                        const selectedPools = [];
                        this.selectItems.map(obj => {
                            obj.value && oldSelectedPoolIds.includes(obj.value[0]) && (obj.value[2] !== true) && selectedPools.push(obj.value)
                        });
                        this.selectedPools = selectedPools;
                    });
                    this.notifier.displaySuccess('Mailing Pools', "Angebot erfolgreich versandent");
                }
                this.selfLoading = false;
                this.isSending = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.selfLoading = false;
                this.isSending = false;
            });
        },
    },
    computed: {
        isLoading(){
            return this.parentLoading || this.selfLoading;
        },
        tableItems(){
            return this.selectedPools.map( item => {
                return {
                    poolId: item[0],
                    poolName: item[1],
                    sent: item[2],
                }
            });
        }
    }
});
