


















































































































































































































































import { ref } from '@vue/composition-api';
import { useHttp } from '@/components/services/http.service';
import ExecutePaystub from "@/views/billing/ExecutePaystub.vue";
import {getNotifier} from "@/helpers";
import {useAuth} from "@/components/services/auth.service";


function initialState() {
    return {
        isLoading: true,
        status: 200,
        paystub: null,
        oustandingPaystubsDateCount: null,
        paystubTransferNextMonth: null,
        swalDelete: ['Auszahlung löschen?', 'Das kann nicht rückgängig gemacht werden.'],
        STATUS_MULTIPLE_EMPLOYMENT_CONTRACTS: -2,
        STATUS_NO_EMPLOYMENT_CONTRACTS: -3,
    }
}

export default {
    name: 'paystubPaystub',
    setup() {
        const http = ref();
        const auth = ref();
        http.value = useHttp();
        auth.value = useAuth();
        return { http, auth };
    },
    components: {
        'execute-paystub': ExecutePaystub,
    },
    data() {
        return initialState();
    },
    props:{
        date: {
            type: Object,
            required: true
        },
        employeeNumber: {
            required: true,
        }
    },
    mounted() {

        this.notifier = getNotifier();
        this.getPaystub();
        this.getOutstandingPaystubs();
        this.getNextMonthsPaystubs();
    },
    filters: {
        decimal: function(value){
            return new Intl.NumberFormat('de',{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);
        }
    },
    methods:{
        getPaystub(){
            this.isLoading = true;
            this.http.get('/billing/paystub/paystub/' + this.employeeNumber + '/' + this.date.month + '-' + this.date.year).then((response) => {
                this.status = 200;
                this.paystub = response.data['data'];
                if(response.data['failure']){
                    if(response.data['failure'] === 'multiple_employment_contracts')
                        this.status = this.STATUS_MULTIPLE_EMPLOYMENT_CONTRACTS;
                    else if(response.data['failure'] === 'no_employment_contracts')
                        this.status = this.STATUS_NO_EMPLOYMENT_CONTRACTS;
                }
                this.isLoading = false;
            }).catch((error: any) => {
                console.log(error);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
        updatePaystubTransfer(){
            this.isLoading = true;
            this.http.get('/billing/paystub/transfer/' + this.employeeNumber + '/' + this.date.month + '-' + this.date.year).then((response) => {
                this.paystub['paystub'] = response.data['data'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
        getOutstandingPaystubs(){
            this.isLoading = true;
            this.http.get('/billing/paystub/outstanding/' + this.employeeNumber + '/' + this.date.month + '-' + this.date.year).then((response) => {
                this.oustandingPaystubsDateCount = response.data['data'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
        getNextMonthsPaystubs(){
            this.isLoading = true;
            this.http.get('/billing/paystub/transfer/' + this.employeeNumber + '/' + (this.dateNextMonth.getMonth()+1) + '-' + this.dateNextMonth.getFullYear()).then((response) => {
                this.paystubTransferNextMonth = response.data['data'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
        deletePaystubTransfer(){
            this.isLoading = true;
            this.http.post('/billing/paystub/delete/' + this.paystub['paystub']['id']).then(() => {
                this.paystub['paystub'] = null;
                this.isLoading = false;
                this.notifier.displaySuccess('Auszahlung gelöscht', 'Die Auszahlung wurde erfolgreich gelöscht.');
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.status = error.response.status;
                this.isLoading = false;
            });

        },
        onPaystubExecuted(){
            this.updatePaystubTransfer();
            this.getOutstandingPaystubs();
        },


        facility(contract){
            return contract.facility;
        },

        normalServiceHours(contract){
            return contract.normalService.minutes / 60;
        },
        normalServiceActiveBonus(contract){
            const hours = this.normalServiceHours(contract);
            if(hours === 0)
                return 0;
            return this.normalServiceSum(contract) / hours;

        },
        normalServiceSum(contract){
            return contract.normalService.activeCents / 100;
        },

        onCallServiceHours(contract){
            return contract.onCallService.minutes / 60;
        },
        onCallServiceActiveBonus(contract){
            const hours = this.onCallServiceHours(contract);
            if(hours === 0)
                return 0;
            return this.onCallServiceSum(contract) / hours;
        },
        onCallServiceSum(contract){
            return contract.onCallService.activeCents / 100;
        },

        onDutyServiceHours(contract){
            return contract.onDutyService.minutes / 60;
        },
        onDutyServiceActiveBonus(contract){
            const hours = this.onDutyServiceHours(contract);
            if(hours === 0)
                return 0;
            return this.onDutyServiceSum(contract) / hours;
        },
        onDutyServiceSum(contract){
            return contract.onDutyService.activeCents / 100;
        },

        nightBonusHours(contract){
            return contract.nightBonus.minutes / 60;
        },
        nightBonusSum(contract){
            return contract.nightBonus.cents / 100;
        },

        saturdayBonusHours(contract){
            return contract.saturdayBonus.minutes / 60;
        },
        saturdayBonusSum(contract){
            return contract.saturdayBonus.cents / 100;
        },

        sundayBonusHours(contract){
            return contract.sundayBonus.minutes / 60;
        },
        sundayBonusSum(contract){
            return contract.sundayBonus.cents / 100;
        },

        holidayBonusHours(contract){
            return contract.holidayBonus.minutes / 60;
        },
        holidayBonusSum(contract){
            return contract.holidayBonus.cents / 100;
        },
    },
    computed: {

        contractColumnMap(){
            return [
                {colName: 'Einrichtung', dataName: 'facility', fnName: this.facility, colFooterName: 'Summe'},

                {colName: 'Regulär', dataName: 'normalService', fnHours: this.normalServiceHours, fnActiveBonus: this.normalServiceActiveBonus,
                    fnSum: this.normalServiceSum, contractSumHours: this.contractSumNormalHours, contractSumSum: this.contractSumNormalSum},
                {colName: 'Rufbereitschaft', dataName: 'onCallService', fnHours: this.onCallServiceHours, fnActiveBonus: this.onCallServiceActiveBonus,
                    fnSum: this.onCallServiceSum, contractSumHours: this.contractSumOnCallHours, contractSumSum: this.contractSumOnCallSum},
                {colName: 'Bereitschaft', dataName: 'onDutyService', fnHours: this.onDutyServiceHours, fnActiveBonus: this.onDutyServiceActiveBonus,
                    fnSum: this.onDutyServiceSum, contractSumHours: this.contractSumOnDutyHours, contractSumSum: this.contractSumOnDutySum},

                {colName: 'Nacht', dataName: 'nightBonus', fnHours: this.nightBonusHours, fnSum: this.nightBonusSum,
                    contractSumHours: this.contractSumNightBonusHours, contractSumSum: this.contractSumNightBonusSum},
                {colName: 'Samstag', dataName: 'saturdayBonus', fnHours: this.saturdayBonusHours, fnSum: this.saturdayBonusSum,
                    contractSumHours: this.contractSumSaturdayBonusHours, contractSumSum: this.contractSumSaturdayBonusSum},
                {colName: 'Sonntag', dataName: 'sundayBonus', fnHours: this.sundayBonusHours, fnSum: this.sundayBonusSum,
                    contractSumHours: this.contractSumSundayBonusHours, contractSumSum: this.contractSumSundayBonusSum},
                {colName: 'Feiertag', dataName: 'holidayBonus', fnHours: this.holidayBonusHours, fnSum: this.holidayBonusSum,
                    contractSumHours: this.contractSumHolidayBonusHours, contractSumSum: this.contractSumHolidayBonusSum},
            ];
        },
        dateNextMonth(){
            return new Date(this.date.year, parseInt(this.date.month)-1+1);
        },

        isPaystubTransferred(){
            return Boolean(this.paystub && this.paystub['paystub'] && this.paystub['paystub']['transferred']);
        },
        isPaystubPending(){
            return Boolean(this.paystub && this.paystub['paystub'] && !this.paystub['paystub']['transferred']);
        },
        arePaystubsOustanding(){
            return Boolean(this.oustandingPaystubsDateCount && this.oustandingPaystubsDateCount.length)
        },
        paystubTransferEnabled(){
            const curMonth = new Date();
            curMonth.setDate(1);

            const selectedMonth = new Date(curMonth.getTime());
            selectedMonth.setFullYear(this.date.year);
            selectedMonth.setMonth(this.date.month-1);
            return Boolean(!this.isLoading && (selectedMonth < curMonth) && !this.isPaystubTransferred && !this.arePaystubsOustanding);
        },
        isLatestProcessedPaystubTransfer(){
            return Boolean(!this.paystubTransferNextMonth || !this.paystubTransferNextMonth.transferred);
        },


        paystubHoursTransferred(){
            if(!this.paystub.paystub)
                return null;
            return this.paystub.paystub.minutesTransferred / 60;
        },
        paystubHoursRemaining(){
            if(!this.paystub.paystub)
                return null;
            return this.paystub.paystub.minutesRemaining / 60;
        },
        paystubOther(){
            if(!this.paystub.paystub)
                return null;
            return this.paystub.paystub.other;
        },


        plannedHours(){
            return this.paystub.plannedMinutes / 60;
        },
        workedHours(){
            return this.paystub.workedMinutes / 60;
        },
        hurdleRate(){
            return this.paystub.hurdleRate / 100;
        },
        avgDailyHours(){
            return this.paystub.avgDailyMinutes / 60;
        },
        actualWorkingDays(){
            return this.paystub.workingDays;
        },

        vacationDays(){
            return this.paystub.vacationDays;
        },
        sickDays(){
            return this.paystub.sickDays;
        },
        workingDayHolidayDays(){
            return this.paystub.workingDayHolidayDays;
        },
        workingDayHolidayWorkedHours(){
            return this.paystub.workingDayHolidayWorkedMinutes / 60;
        },

        vacationDaysSum(){
            return this.vacationDays * this.avgDailyHours * this.hurdleRate;
        },
        sickDaysSum(){
            return this.sickDays * this.avgDailyHours * this.hurdleRate;
        },
        workingDayHolidayDaysSum(){
            return Math.max(0, this.workingDayHolidayDays * this.avgDailyHours - this.workingDayHolidayWorkedHours) * this.hurdleRate;
        },
        baseHours(){
            return this.paystubHoursTransferred || this.plannedHours;
        },
        baseHoursSum(){
            return this.baseHours * this.hurdleRate;
        },
        activeHoursSum(){
            return this.contractSumNormalSum + this.contractSumOnCallSum + this.contractSumOnDutySum
                + this.contractSumNightBonusSum + this.contractSumSaturdayBonusSum + this.contractSumSundayBonusSum + this.contractSumHolidayBonusSum;
        },
        totalSum(){
            return this.activeHoursSum + this.baseHoursSum + this.workingDayHolidayDaysSum + this.sickDaysSum + this.vacationDaysSum;
        },

        contractSumNormalHours(){
            return this.paystub.contractsSum.normalService.minutes / 60;
        },
        contractSumOnCallHours(){
            return this.paystub.contractsSum.onCallService.minutes / 60;
        },
        contractSumOnDutyHours(){
            return this.paystub.contractsSum.onDutyService.minutes / 60;
        },
        contractSumNightBonusHours(){
            return this.paystub.contractsSum.nightBonus.minutes / 60;
        },
        contractSumSaturdayBonusHours(){
            return this.paystub.contractsSum.saturdayBonus.minutes / 60;
        },
        contractSumSundayBonusHours(){
            return this.paystub.contractsSum.sundayBonus.minutes / 60;
        },
        contractSumHolidayBonusHours(){
            return this.paystub.contractsSum.holidayBonus.minutes / 60;
        },

        contractSumNormalSum(){
            return this.paystub.contractsSum.normalService.activeCents / 100;
        },
        contractSumOnCallSum(){
            return this.paystub.contractsSum.onCallService.activeCents / 100;
        },
        contractSumOnDutySum(){
            return this.paystub.contractsSum.onDutyService.activeCents / 100;
        },
        contractSumNightBonusSum(){
            return this.paystub.contractsSum.nightBonus.cents / 100;
        },
        contractSumSaturdayBonusSum(){
            return this.paystub.contractsSum.saturdayBonus.cents / 100;
        },
        contractSumSundayBonusSum(){
            return this.paystub.contractsSum.sundayBonus.cents / 100;
        },
        contractSumHolidayBonusSum(){
            return this.paystub.contractsSum.holidayBonus.cents / 100;
        },


    }
};
