















export default {
    name: 'AdminPrivilegeNotification',
    props: {
        contract: null,
    },
}

