



































































































































import { ref, defineComponent } from '@vue/composition-api';

import {Subject} from 'rxjs';
import {switchMap, debounceTime} from 'rxjs/operators';

import { useHttp } from '@/components/services/http.service';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import TableFilter from '@/components/common/forms/TableFilter.vue';
import DatatableCheckbox from '@/components/common/forms/DatatableCheckbox.vue';
import AddCustomer from '@/views/customers/AddCustomer.vue';


export default defineComponent({
    name: 'customers',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    components: {
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
        'datatable-checkbox': DatatableCheckbox,
        'add-customer': AddCustomer,
    },
    mounted() {
        this.filters = this.filterDefs;

        this.searchQuery$ = new Subject().pipe(
            debounceTime(400),
        );
        this.searchQuerySubscription = this.searchQuery$.subscribe((query: string) => {
            this.searchQuery = query;
            this.items$.next(1);
        });

        this.items$ = new Subject().pipe(
            switchMap((page) => {
                this.isLoading = true;
                this.currentPage = page;
                this.allSelected = false;
                return this.http.post('/customers', {
                    'offset': this.currentPage * this.rowsPerPage - this.rowsPerPage,
                    'limit': this.rowsPerPage,
                    'sortBy': this.sortBy,
                    'sortDir': this.sortDesc ? 'desc' : 'asc',
                    'filterInput': this.filterInput,
                    'searchQuery': this.searchQuery,
                });
            }));
        this.itemsSubscription = this.items$.subscribe({
            next: (response: any) => {
                this.numTotalRows = response.data['data']['num_total_rows'];
                this.items = response.data['data']['rows'];
                this.$refs.userTable.clearSelected();
                this.isLoading = false;
            }, error: () => {
                this.isLoading = false;
            }
        });
        this.items$.next(1);
    },
    data() {
        return {
            items$: null,
            itemsSubscription: null,
            searchQuery$: null,
            searchQuerySubscription: null,
            searchQuery: '',
            isLoading: false,
            currentPage: 1,
            sortBy: 'id',
            sortDesc: true,
            rowsPerPage: 10,
            numTotalRows: null,
            items: [],
            filterInput: {},
            filters: [],
            filterListExpanded: false,
            allSelected: false,
            actions: [
            ],
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                    sortable: true,
                },
                {
                    key: 'facility',
                    label: 'Einrichtung',
                    sortable: true,
                },
                {
                    key: 'addressAddition',
                    label: 'Adresszusatz',
                    sortable: true,
                },
                {
                    key: 'street',
                    label: 'Straße',
                    sortable: true,
                },
                {
                    key: 'zip',
                    label: 'PLZ / Ort',
                    sortable: true,
                },
                {
                    key: 'regionShort',
                    label: 'Region',
                    sortable: true,
                },
                {
                    key: 'numContactPersons',
                    label: 'Anz. Kontaktpers.',
                    sortable: true,
                    class: 'td-num-contact-persons',
                },
                {
                    key: 'action',
                    label: 'Aktion',
                    sortable: false,
                },
            ],
            filterDefs: [
                {
                    title: 'ID',
                    type: 'text',
                    prop: 'id',
                },
                {
                    title: 'Einrichtung',
                    type: 'text',
                    prop: 'facility',
                },
                {
                    title: 'Adresszusatz',
                    type: 'text',
                    prop: 'addressAddition',
                },
                {
                    title: 'Straße',
                    type: 'text',
                    prop: 'street',
                },
                {
                    title: 'PLZ',
                    type: 'text',
                    prop: 'zip',
                },
                {
                    title: 'Region',
                    type: 'text',
                    prop: 'regionShort',
                },
                {
                    title: 'Kontaktperson',
                    type: 'text',
                    prop: 'contactPerson',
                },
            ],
        }
    },
    methods: {
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.items$.next(this.currentPage);
        },
        rowsPerPageChanged() {
            this.items$.next(1);
        },
        onPageChange(page) {
            this.items$.next(page);
        },
        filter(value) {
            Object.assign(this.filterInput, value);
            this.items$.next(1);
        },
        selectOrClearAll() {
            if (this.allSelected) {
                this.$refs.userTable.clearSelected();
            } else {
                this.$refs.userTable.selectAllRows();
            }
        },
        selectOrUnselect(row) {
            if (!row.rowSelected) {
                row.selectRow();
            } else {
                row.unselectRow();
                this.allSelected = false;
            }
        },
        toggleFilterList() {
            this.filterListExpanded = !this.filterListExpanded;
        },
        onCustomerAdded() {
            this.sortBy = 'id';
            this.items$.next(1);
        }
    }
});
