























































































































































import { ref } from '@vue/composition-api';
import { MonthPickerInput } from 'vue-month-picker';


import { useHttp } from '@/components/services/http.service';
import {Subject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import TransitionExpand from "@/components/common/animations/TransitionExpand.vue";
import TableFilter from "@/components/common/forms/TableFilter.vue";

function initialState() {
    return {
        date: {
            month: null,
            year: null,
        },
        monthPickerDefaultDate: {
            month: null,
            year: null,
        },
        isLoading: false,
        items$: null,
        itemsSubscription: null,
        searchQuery$: null,
        searchQuerySubscription: null,
        searchQuery: '',
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        rowsPerPage: 10,
        numTotalRows: null,
        items: [],
        filterInput: {},
        filters: [],
        filterListExpanded: false,
        fields: [
            {
            key: 'facilityName',
            label: 'Kunden',
            sortable: true,
            },
            {
            key: 'employeeNumber',
            label: 'Mitarbeiternummer',
            sortable: true,
            },
            {
            key: 'firstName',
            label: 'Vorname',
            sortable: true,
            },
            {
            key: 'lastName',
            label: 'Nachname',
            sortable: true,
            },
            {
            key: 'processed',
            label: 'Berechnet',
            sortable: true,
            },
            {
            key: 'action',
            label: 'Aktion',
            sortable: false,
            },
        ],
        filterDefs: [
            {
                title: 'Kunden',
                type: 'text',
                prop: 'facilityName',
            },
            {
                title: 'Mitarbeiternummer',
                type: 'text',
                prop: 'employeeNumber',
            },
            {
                title: 'Vorname',
                type: 'text',
                prop: 'firstName',
            },
            {
                title: 'Nachname',
                type: 'text',
                prop: 'lastName',
            },
            {
                title: 'Status',
                type: 'select',
                placeholder: 'Bitte auswählen',
                prop: 'processed',
                options: [
                    {name: 'Berechnet', value: true},
                    {name: 'Noch nicht Berechnet', value: false},
                ]
            }
        ],
    }
}

declare const jQuery: any;

export default {
    name: 'CustomerInvoicesOverview',
        components: {
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
        'month-picker-input': MonthPickerInput,
        },
        setup() {
            const http = ref();
            http.value = useHttp();
            return { http };
        },
        data() {
            return initialState();
        },
        mounted() {
            this.setTableProperties();
            this.filters = this.filterDefs;

            this.filterInput['processed'] = this.$route.query.processed ?? '';

            if (this.$route.query.notProcessed) {
              this.saveTableProperty('filterInput', {processed : false});
            }

            const passedDate = this.$route.query.date ? this.$moment(this.$route.query.date, 'MM-YYYY', true) :
                ((this.savedTableState.monthYear) ? this.$moment(this.savedTableState.monthYear, 'MM-YYYY', true) : null);

            if (this.$route.query.date) {
              const routeDate = this.$moment(this.$route.query.date, 'MM-YYYY', true);
              this.saveTableProperty('monthYear', parseInt(routeDate.format('MM')) + '-' + parseInt(routeDate.format('YYYY')));
            }

            if (passedDate && passedDate.isValid()) {
                this.monthPickerDefaultDate.month = parseInt(passedDate.format('MM'));
                this.monthPickerDefaultDate.year = parseInt(passedDate.format('YYYY'));
                this.date.month = this.monthPickerDefaultDate.month;
                this.date.year = this.monthPickerDefaultDate.year;

                // To avoid vue Error NavigationDuplicated: Avoided redundant navigation
                if (this.$route.path !== this.$route.fullPath) {
                    this.$router.push(this.$route.path);
                }
            } else {
                this.monthPickerDefaultDate.month = new Date().getMonth() + 1;
                this.monthPickerDefaultDate.year = new Date().getFullYear();
                this.date.month = this.monthPickerDefaultDate.month;
                this.date.year = this.monthPickerDefaultDate.year;
            }

            this.searchQuery$ = new Subject().pipe(
                debounceTime(400),
            );
            this.searchQuerySubscription = this.searchQuery$.subscribe((query: string) => {
                this.saveTableProperty('searchQuery', query);
                this.searchQuery = query;
                this.items$.next(1);
            });

            this.items$ = new Subject().pipe(
                switchMap((page) => {
                    this.isLoading = true;
                    this.currentPage = page;
                    return this.http.post('/billing/invoices-to-customer/invoices/filters/' + this.date.month + '-' + this.date.year, {
                    'offset': this.currentPage * this.rowsPerPage - this.rowsPerPage,
                    'limit': this.rowsPerPage,
                    'sortBy': this.sortBy,
                    'sortDir': this.sortDesc ? 'desc' : 'asc',
                    'filterInput': this.filterInput,
                    'searchQuery': this.searchQuery,
                    });
                }));
            this.itemsSubscription = this.items$.subscribe({
                next: (response: any) => {
                    this.numTotalRows = response.data['data']['num_total_rows'];
                    this.items = response.data['data']['rows'];
                    this.$refs.customerInvoicesTable.clearSelected();
                    this.isLoading = false;
                }, error: (err) => {
                    console.log(err);
                    this.isLoading = false;
                }
            });

            this.items$.next(this.currentPage);
        },
        watch: {
            '$route'() {
                this.setTableProperties();
                this.items$.next(1);
            }
        },
        methods: {
            sortingChanged(ctx) {
                this.saveTableProperty('sortBy', ctx.sortBy);
                this.saveTableProperty('sortDesc', ctx.sortDesc);
                this.sortBy = ctx.sortBy;
                this.sortDesc = ctx.sortDesc;
                this.items$.next(1);
            },
            rowsPerPageChanged() {
                this.saveTableProperty('rowsPerPage', this.rowsPerPage);
                this.items$.next(1);
            },
            onPageChange(page) {
                //this.saveTableProperty('page', page);
                this.items$.next(page);
            },
            toggleFilterList() {
                this.filterListExpanded = !this.filterListExpanded;
            },
            clearFilters() {
                this.$refs.tableFilter.clearValues();
                this.filterInput = {};
                this.clearTableProperties();
                this.items$.next(1);
            },
            filter(values) {
                for (const prop in values) {
                    if (Object.prototype.hasOwnProperty.call(values, prop)) {
                      // Properties of 'filterInput' need to be reactive as computed property 'filterInputEmpty' depends on them.
                      this.$set(this.filterInput, prop, values[prop]);
                    }
                }
                this.saveTableProperty('filterInput', this.filterInput);
                this.items$.next(1);
            },
            onDateChanged(date){
                this.saveTableProperty('monthYear', date.monthIndex.toString().padStart(2, '0') + '-' +date.year);
                this.date.year = date.year;
                this.date.month = date.monthIndex;
                this.items$.next(1);
            },
            setTableProperties() {

                this.loadTableProperties();

                if (this.savedTableState.filterInput) {
                    setTimeout(() => {
                        this.$refs.tableFilter.setValues(this.savedTableState.filterInput);
                        // expand the filters if at least one filter value is pre-set
                        for (const prop in this.savedTableState.filterInput) {
                            if (this.savedTableState.filterInput[prop] !== null && this.savedTableState.filterInput[prop] !== '') {
                                this.filterListExpanded = true;
                                break;
                            }
                        }
                    });
                }
            },
            saveTableProperty(prop, value) {
                this.savedTableState[prop] = value;
                window.sessionStorage.setItem('customer-invoices-table', JSON.stringify(this.savedTableState));
            },
            loadTableProperties() {
                this.savedTableState = JSON.parse(window.sessionStorage.getItem('customer-invoices-table'));
                if (this.savedTableState === null) {
                    this.savedTableState = {};
                    window.sessionStorage.setItem('customer-invoices-table', JSON.stringify(this.savedTableState));
                }
                Object.assign(this.$data, this.savedTableState);
            },
            clearTableProperties() {
                this.savedTableState = {};
                window.sessionStorage.removeItem('customer-invoices-table');
            },
          },

        computed: {
            filterInputEmpty() {
                for (const prop in this.filterInput) {
                    if (Object.prototype.hasOwnProperty.call(this.filterInput, prop)) {
                        if (this.filterInput[prop] !== null && this.filterInput[prop] !== '' && this.filterInput[prop] !== undefined) {
                            return false;
                        }
                    }
                }
                return true;
            },
            processedStatus() {
                return {
                    true: {
                        title: 'Berechnet',
                        icon: 'check_circle',
                        class: 'green--text'
                    },
                    false: {
                        title: 'Noch nicht Berechnet',
                        icon: 'pending',
                        class: ''
                    },
                    null: {
                        title: 'Noch nicht Berechnet',
                        icon: 'remove_circle',
                        class: 'text--disabled'
                    },
                };
            },
            prevDate() {
                const prev = new Date(this.date.year, this.date.month - 1);
                prev.setMonth(prev.getMonth() - 1);
                return {date: (prev.getMonth() + 1).toString().padStart(2, '0')+ '-'+ prev.getFullYear() }
            },
            nextDate() {
                const next = new Date(this.date.year, this.date.month + 1);
                return {date: next.getMonth().toString().padStart(2, '0') + '-' + next.getFullYear()}
            },
            dateLastMonth() {
                const d = new Date();
                d.setDate(1);
                d.setMonth(d.getMonth() - 1);
                return d;
            },
        }
};
