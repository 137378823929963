








































































































































import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import {getNotifier} from '@/helpers';
import {useProfessionProvider} from '@/components/services/profession-provider.service';


import {required} from 'vuelidate/lib/validators';


declare const jQuery: any;

function initialState(ctx) {
    return {
        form1: {
            application: null,
            utilizationTimeShort: null,
            professionCategory: null,
            agentUser: null,
            customer: null,
            contactPerson: null,
        },
        form2: {
            tasksText: null,
            qualificationText: null,
            utilizationTimeLong: null,
            benefitsText: null,
            termsText: "",
            specificsText: ""
        },
        isLoading: false,
        modal: null,
        curPage: 1,
        lastPage: 2,
        offerTitle: '',
        professionCategories: ctx.professionCategories,
        agentUsers: ctx.agentUsers,
        selectCustomers: ctx.selectCustomers || [],
        contactPersons: ctx.contactPersons,
    }
}

export default {
    name: 'AddOffer',
    setup() {
        const http = ref();
        const professionsProvider = ref();
        http.value = useHttp();
        professionsProvider.value = useProfessionProvider();
        return {http, professionsProvider};
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
            Object.assign(this.$data, initialState(this));
            this.modal = jQuery('#' + this.modalId);
        });
        this.modal.on('shown.bs.modal', () => {
            this.getPlaceholders();
        });
        this.professionCategories = []
        this.agentUsers = [];
        this.contactPersons = [];
        this.getProfessionCategories();
        this.getAgentUsers();
        this.getCustomers();
        this.updateOfferTitle();
    },
    props: {
        modalId: {
            type: String,
            required: true
        }
    },
    data() {
        return initialState(this);
    },
    validations() {
        return {
            form1: {
                application: {required},
                utilizationTimeShort: {required},
                professionCategory: {required},
                agentUser: {required},
                customer: {required},
                contactPerson: {required},
            },
            form2: {
                tasksText: {required},
            }
        }
    },
    methods: {
        submit: function () {

            this.$v.$touch();
            if (this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;
            const forms = {
                form1: this.$data.form1,
                form2: this.$data.form2
            };
            this.http.post('/offers/add', forms).then(() => {
                notifier.displaySuccess('Hinzugefügt', 'Das Angebot wurde hinzugefügt.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('added');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        nextPage() {
            this.$v['form' + this.curPage].$touch();
            if (this.$v['form' + this.curPage].$error) return;
            this.curPage++;
        },
        previousPage() {
            this.curPage--;
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        requiredError(form, prop) {
            if (this.$v[form][prop].$dirty && !this.$v[form][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        async getProfessionCategories() {
            this.professionCategories = await this.professionsProvider.getProfessionCategoriesAsOptions();
        },
        async getAgentUsers() {
            this.agentUsers = await this.http.get('/agent-users').then((response: any) => {
                return response.data.agentUsers;
            });
        },
        async getCustomers() {
            this.customers = await this.http.post('/customers', {
                offset: 0,
                limit: 1000,
                sortBy: 'facility',
                sortDir: 'DESC',
                filterInput: [],
                searchQuery: ''
            }).then((response: any) => {
                const customers = {};
                response.data['data']['rows'].forEach(customer => {
                    customers[customer.id] = customer;
                    customers[customer.id]['name'] = customer.facility + (customer.addressAddition ? " " + customer.addressAddition : "");
                });
                return customers;
            });
            this.selectCustomers = Object.values(this.customers);
        },
        async getContactPersons() {
            if (this.form1.customer === null) {
                return;
            }
            this.form1.contactPerson = null;
            this.contactPersons = await this.http.get('/customer-contact-persons/' + this.form1.customer)
                .then((response: any) => {
                    response.data.customerContactPersons.forEach(cp => {
                        cp['name'] = cp.firstName + ' ' + cp.lastName;
                        cp['name_responsibility'] = cp.firstName + ' ' + cp.lastName + ' (' + cp.responsibility + ')';
                    });
                    return response.data.customerContactPersons;
                });
        },
        async getPlaceholders(){
            const placeholders = await this.http.get('/offers-placeholders').then((response: any) => {
                return response.data['data'];
            });
            Object.assign(this.form2, placeholders);
        },
        updateContactPersons(newCustomerId, oldCustomerId) {
            if (newCustomerId === oldCustomerId)
                return;
            this.getContactPersons();
        },
        updateOfferTitle() {
            const SEP = ', ';
            this.offerTitle =
                'Einsatz in ' +
                ((this.customers && this.form1.customer) ? this.customers[this.form1.customer]['regionShort'] : 'Region') + SEP +
                (this.form1.application || 'Einsatzgebiet') + SEP +
                (this.form1.utilizationTimeShort || 'Einsatzzeitraum') + SEP +
                '#ID';
        }
    },
    watch: {
        'form1.customer':  function(newCustomerId, oldCustomerId){
            this.updateContactPersons(newCustomerId, oldCustomerId);
            this.updateOfferTitle();
        },
        'form1.application': function(){this.updateOfferTitle()},
        'form1.utilizationTimeShort': function(){this.updateOfferTitle()},
    }
}

