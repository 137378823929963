import { provide, inject } from '@vue/composition-api';

import {interval} from 'rxjs';
import {filter, startWith, mergeMap} from 'rxjs/operators';

import {useAuth} from "@/components/services/auth.service";
import {useHttp} from "@/components/services/http.service";

import { Subject } from 'rxjs';

const createOpenTasksService = () => {

    const POLLING_FREQUENCY = 100000 ;

    const auth: any = useAuth();
    const http: any = useHttp();

    const openTasks$ = new Subject();

    interval(POLLING_FREQUENCY).pipe(
        startWith(0),
        filter(() => auth.getUser() !== null),
        mergeMap(() => {
            return http.get('/num-open-tasks');
        }))
        .subscribe((response: any) => {
            openTasks$.next(response.data);
        });

    return {
        getOpenTasks() {
            return openTasks$;
        },
        pollOpenTasks() {
            http.get('/num-open-tasks').then((response) => {
                openTasks$.next(response.data);
            });
        }
    }
};

const openTasksSymbol = Symbol();

export function provideOpenTasksService() {
    const openTasksService = createOpenTasksService();
    provide(openTasksSymbol, openTasksService);
}

export function useOpenTasksService() {
    const openTasksService = inject(openTasksSymbol);
    if (!openTasksService) throw new Error("No open tasks service provided!!!");

    return openTasksService;
}