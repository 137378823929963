



































































































    import { defineComponent } from '@vue/composition-api';

    import { useAuth } from '@/components/services/auth.service';
	
	declare const jQuery: any;

    export default defineComponent({
        name: 'AppNavigation',
        setup() {
            const auth = useAuth();
            return { 'auth': auth };
        },
        mounted: function () {
            if (jQuery('body').hasClass('fixed-sidebar')) {
                jQuery('.sidebar-collapse').slimscroll({
                    height: '100%'
                });
            }

            /**
             * Expand and collpase sub menus when clicking on a parent element
             *
             * jQuery is used here instead of a reactive component for no other reason than time pressure
             * in the initial development phase.
             *
             * The original inspinia implementation used jQuery plugin 'metismenu' for expanding and collapsing
             * the sidemenu which uses class name 'mm-active' to track open sub menus. The classname is repurposed
             * to distingish between a menu element being active because of belonging to the active route ('active')
             * and having an open sub menu because it has been clicked on ('mm-active')
             */
            jQuery('#side-menu > li > a').on('click', function () {
                jQuery('#side-menu > li > ul.show').removeClass('mm-active').collapse('hide');
                jQuery('#side-menu > li').removeClass('mm-active');
                jQuery(this).closest('li').addClass('mm-active');
            });
        },
        computed: {
            user() {
                return this.auth.getUser();
            }
        },
        methods: {
            logout() {
                this.auth.logout();
            },
            isActive(path: string) {
                return this.$route.path.startsWith(path);
            },
            hasRole(role: string) {
                return this.user && this.user.hasRole(role);
            },
        },
    });
