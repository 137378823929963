import { provide, inject } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';

const createInputSuggestions = () => {
    const httpClient: any = useHttp();

    let suggestions$;

    let honoraryTitles$ = Promise.resolve([]);
    let asvTasks$ = Promise.resolve([]);
    let aulTasks$ = Promise.resolve([]);
    let aulApplications$ = Promise.resolve([]);

    return {
        load: function () {
            suggestions$ = httpClient.get('/input-suggestions').then((response: any) => {
                return response.data;
            });

            honoraryTitles$ = suggestions$.then(({ honoraryTitles }) => {
                const list = [];
                for (const honoraryTitle of honoraryTitles) {
                    list.push(honoraryTitle);
                }
                return list;
            });

            asvTasks$ = suggestions$.then(({ asvTasks }) => {
                const list = [];
                for (const asvTask of asvTasks) {
                    list.push(asvTask);
                }
                return list;
            });

            aulTasks$ = suggestions$.then(({ aulTasks }) => {
                const list = [];
                for (const aulTask of aulTasks) {
                    list.push(aulTask);
                }
                return list;
            });

            aulApplications$ = suggestions$.then(({ aulApplications }) => {
                const list = [];
                for (const aulApplication of aulApplications) {
                    list.push(aulApplication);
                }
                return list;
            });
        },
        getHonoraryTitles: function () {
            return honoraryTitles$;
        },
        getAsvTasks: function () {
            return asvTasks$;
        },
        getAulTasks: function () {
            return aulTasks$;
        },
        getAulApplications: function () {
            return aulApplications$;
        },
    };

};

const inputSuggestionsSymbol = Symbol();

export function provideInputSuggestions() {
    const inputSuggestions = createInputSuggestions();
    provide(inputSuggestionsSymbol, inputSuggestions);
}

export function useInputSuggestions() {
    const inputSuggestions = inject(inputSuggestionsSymbol);
    if (!inputSuggestions) throw new Error('No input suggestions provided!!!');

    return inputSuggestions;
}
