













































































import {ref} from '@vue/composition-api';
import {useHttp} from '@/components/services/http.service';
import {getNotifier} from "@/helpers";

export default {
    name: 'CustomerInvoiceTimeline',
    setup() {
        const http = ref();
        http.value = useHttp();
        return {http};
    },
    props: {
        items: null,
        rates: null,
        scId: null,
        month: null,
        year: null
    },
    data: () => ({
        reverse: false,
    }),
    computed: {
        contractColumnMap() {
            return {
                'Regulärdienst': 'normalService',
                'Rufdienst': 'onCallService',
                'Bereitschaftsdienst': 'onDutyService',
                'Nachtdienst': 'nightBonus',
                'Samstag': 'saturdayBonus',
                'Sonntag': 'sundayBonus',
                'Feiertag': 'holidayBonus',
            }
        }
    },
    methods: {
        downloadTimesheetPdf(datetime) {
            this.isLoadingPdf = true;
            const notifier = getNotifier();
           // const dateTime = new Date(datetime);

            // chrome likes to cache blob results, so provide a timestamp to make it clear for chrome to not do this.
            this.http.get('/billing/customerInvoice/printTimesheetPdf/' + this.scId + '/' + this.month + '/' + this.year + '/' + datetime +'?version=' + new Date().getTime(),
                {responseType: 'blob'}).then((response) => {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                //window.open(fileURL);

                /*
                 * The filename is retrieved from the 'content-disposition' header.
                 *
                 * It is html encoded on the server-side as umlauts would over-complicate the format
                 * of the header field. Quotation marks are removed below as the filename may be returned
                 * between quotation marks or not.
                 */
                const headerLine = this.decodeHtml(response.headers['content-disposition']).replaceAll('"', '');
                const filename = headerLine.substring(headerLine.indexOf('=') + 1, headerLine.length);

                const link = document.createElement('a');
                link.href = fileURL;
                link.download = filename;
                link.click();
                URL.revokeObjectURL(fileURL);

                this.isLoadingPdf = false;
            }).catch((error: any) => {
                this.isLoadingPdf = false;
                notifier.displayServerError(error.message);
            });
        },
        decodeHtml(html) {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
    }
}
