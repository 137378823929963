














































import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { getNotifier } from '@/helpers';
import AddressForm from '@/views/users/AddressForm.vue';
import PersonalDataForm from '@/views/users/PersonalDataForm.vue';
import QualificationForm from '@/views/users/QualificationForm.vue';

declare const jQuery: any;

function initialState() {
    return {
        isLoading: false,
        modal: null,
        curPage: 1,
        lastPage: 3,
    }
}

export default {
    name: 'AddUser',
    components: {
        'address-form': AddressForm,
        'personal-data-form': PersonalDataForm,
        'qualification-form': QualificationForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        const inputSuggestions = useInputSuggestions();
        return { http, inputSuggestions };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('shown.bs.modal', () => {
            Object.assign(this.$data, initialState());
            this.modal = jQuery('#' + this.modalId);

            this.$refs.personalDataForm.clear();
            this.$refs.addressForm.clear();
            this.$refs.qualificationForm.clear();
            this.$refs.personalDataForm.setValues({countryOfBirth: 'DE', nationality: 'DE'});
        });
    },
    props: {
        modalId: {
            type: String,
        }
    },
    data() {
        return initialState();
    },
    methods: {
        submit: function() {
            if (!this.$refs.personalDataForm.validate()
                || !this.$refs.addressForm.validate()) {
                return;
            }

            const notifier = getNotifier();
            this.isLoading = true;
            const forms = {
                form1: this.$refs.personalDataForm.$data.form,
                form2: this.$refs.addressForm.$data.form,
                form3: this.$refs.qualificationForm.$data.form,
            };
            this.http.post('/user/add', forms).then(() => {
                notifier.displaySuccess('Hinzugefügt', 'Der Benutzer wurde hinzugefügt.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('added');
                this.inputSuggestions.load();
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        waitingForValidAddressForm() {
            return this.curPage === 2 && this.$refs.addressForm && !this.$refs.addressForm.validate();
        },
        nextPage() {
            if (this.curPage === 1) {
                if (!this.$refs.personalDataForm.validate()) return;
            } else if (this.curPage === 2) {
                if (!this.$refs.addressForm.validate()) return;
            }
            this.curPage++;
        },
        previousPage() {
            this.curPage--;
        },
    }
}
