
















































import {ref} from '@vue/composition-api';

import {required} from 'vuelidate/lib/validators';
import {useHttp} from '@/components/services/http.service';
import {getNotifier} from '@/helpers';

declare const jQuery: any;

    function initialState() {
        return {
            form: {
                id: null,
                note: null
            },
            users: [],
            isLoading: false,
        }
    }

    export default {
        name: 'AddBlacklistUser',
        setup() {
            const http = ref();
            http.value = useHttp();
            return {http};
        },
        mounted() {
            this.modal = jQuery('#' + this.modalId);
            this.modal.on('hidden.bs.modal', () => {
                this.$v.$reset();
            });
            this.modal.on('shown.bs.modal', () => {
                const data = initialState();
                this.$data.form = data.form;
            });
            this.getUsers();
        },
        props: {
            modalId: null,
            customerId: null,
            blacklistUsers : null
        },
        data() {
            return initialState();
        },
        validations() {
            return {
                form: {
                    id : {required}
                }
            }
        },
        computed: {
            blacklistUsersAsOptions: function () {
                return this.users.filter((elem) => !this.blacklistUsers.find(({userId}) => elem.value === userId));
            },
        },
        methods: {
            getUsers() {
                this.isLoading = true;
                const notifier = getNotifier();
                this.http.get('/users/blacklist-options').then((response) => {
                    this.users = response.data['users'];
                    this.isLoading = false;
                }).catch((error: any) => {
                    this.isLoading = false;
                    notifier.displayServerError(error.message);
                });
            },
            submit: function () {
                this.$v.$touch();
                if (this.$v.$error) return;

                const notifier = getNotifier();
                this.isLoading = true;

                this.http.post('/customer/blacklist-user/add/' + this.customerId, this.$data.form).then((response) => {
                    notifier.displaySuccess('Hinzugefügt', 'Gesperrte Fachkräften wurde hinzugefügt.');
                    this.modal.modal('hide');
                    this.isLoading = false;

                    const blacklistUser = {...this.$data.form, id: response.data['data']['id'],
                        userName: response.data['data']['userName'],
                        userId: response.data['data']['userId'],
                        note: response.data['data']['note']};
                    this.$emit('added', blacklistUser);
                }).catch((error: any) => {
                    notifier.displayServerError(error.message);
                    this.isLoading = false;
                });
            },
            requiredError(prop) {
                if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
                return [];
            }
        }
    }

