





























import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import AddressForm from '@/views/users/AddressForm.vue';

declare const jQuery: any;

export default {
    name: 'EditAddress',
    components: {
        'address-form': AddressForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$refs.addressForm.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            this.$refs.addressForm.$data.form = { ... this.address };
        });
    },
    props: {
        userId: null,
        modalId: null,
        address: null,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        submit: function() {
            if (!this.$refs.addressForm.validate()) {
                return;
            }

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/address/edit/' + this.address.id, this.$refs.addressForm.$data.form).then(() => {
                Object.assign(this.address, this.$refs.addressForm.$data.form);
                notifier.displaySuccess('Aktualisiert', 'Adresse wurde aktualisiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('edited');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}
