




















































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { genderTitlesAsOptions } from '@/components/enums/gender_titles';
import { useCountryProvider } from '@/components/services/country-provider.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';

function initialState() {
    return {
        form : {
            title: null,
            additionalTitle: null,
            firstName: null,
            lastName: null,
            birthName: null,
            birthday: null,
            birthplace: null,
            countryOfBirth: null,
            nationality: null,
        },
        genderTitles: genderTitlesAsOptions,
        honoraryTitles: [],
        countriesAsOptions: [],
        menuBirthday: false,
        formattedBirthday: '',
    }
}

export default {
    name: 'PersonalDataForm',
    setup() {
        const countryProvider = ref();
        countryProvider.value = useCountryProvider();
        const inputSuggestions = useInputSuggestions();
        return { countryProvider, inputSuggestions };
    },
    mounted() {
        this.getCountriesAsOptions();
        this.getHonoraryTitles();
    },
    watch: {
        menuBirthday(val) {
            val && !this.form.birthday && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'));
        },
        'form.birthday'() {
            this.formattedBirthday = this.formatDate(this.form.birthday);
        }
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                title: { required },
                firstName: { required },
                lastName: { required },
            }
        }
    },
    computed: {
        year() {
            return new Date().getFullYear();
        }
    },
    methods: {
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        setValues(values) {
            const form = Object.keys(this.form).reduce((a, key) => ({ ...a, [key]: values[key]}), {});
            Object.assign(this.form, form);
        },
        validate() {
            this.$v.$touch();
            return !this.$v.$error;
        },
        clear() {
            const data = initialState();
            Object.assign(this.form, data.form);
            this.$v.$reset();
        },
        async getCountriesAsOptions() {
            this.countriesAsOptions = await this.countryProvider.getCountriesAsOptions();
        },
        async getHonoraryTitles() {
            this.honoraryTitles = await this.inputSuggestions.getHonoraryTitles();
        },
    }
}
