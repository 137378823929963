










































import { ref } from '@vue/composition-api';

import Swal from 'sweetalert2';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import TimesheetEntryForm from '@/views/billing/TimesheetEntryForm.vue';

declare const jQuery: any;

function initialState() {
    return {
        isLoading: false,
    }
}

export default {
    name: 'EditTimesheetEntry',
    components: {
        'timesheet-entry-form': TimesheetEntryForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$refs.timesheetEntryForm.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            this.$refs.timesheetEntryForm.setValues(this.timesheetEntry);
        });
    },
    props: {
        modalId: null,
        timesheetEntry: null,
        professionCategoryId: null,
        holidays: null,
        contractBegin: null,
        contractEnd: null,
    },
    data() {
        return initialState();
    },
    methods: {
        submit: function() {
            if (!this.$refs.timesheetEntryForm.validate()) {
                return;
            }

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/billing/timesheet-entry/edit/' + this.timesheetEntry.id, this.$refs.timesheetEntryForm.$data.form).then(({ data }) => {

                if (data['OK'] === 1) {
                    Object.assign(this.timesheetEntry, this.$refs.timesheetEntryForm.$data.form);
                    notifier.displaySuccess('Aktualisiert', 'Leistungsbogen-Eintrag wurde editiert.');
                    this.modal.modal('hide');
                    this.$emit('edited');

                } else if (data['OK'] === 0) {
                    Swal.fire({
                        title: 'Fehler',
                        text: data['error'],
                        icon: 'warning',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'm-xs btn btn-md btn-default',
                        }
                    });
                }
                this.isLoading = false;
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}
