




























































import {ref} from '@vue/composition-api';

import {required} from 'vuelidate/lib/validators';

import {useHttp} from '@/components/services/http.service';
import {getNotifier} from '@/helpers';
import {useProfessionProvider} from '@/components/services/profession-provider.service';

declare const jQuery: any;

function initialState() {
    return {
        form: {
            tasksText: null,
            qualificationText: null,
            utilizationTimeLong: null,
            termsText: null,
            benefitsText: null,
            specificsText: null,
        },
        offerTitle: null,
        isLoading: false,
    }
}

export default {
    name: 'EditOfferContent',
    setup() {
        const http = ref();
        const professionsProvider = ref();
        http.value = useHttp();
        professionsProvider.value = useProfessionProvider();
        return {http, professionsProvider};
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            this.$data.form = {...this.offerContent}
        });
    },
    props: {
        modalId: null,
        offerContent: {},
        offerId: null
    },
    data() {
        return initialState();
    },
    validations() {
        return {
            form: {
                tasksText: {required},
            }
        }
    },
    methods: {
        submit: function () {
            this.$v.$touch();
            if (this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;
            this.http.post('/offers/'+ this.offerId +'/edit', this.$data.form).then(() => {
                Object.assign(this.offerContent, this.$data.form);

                notifier.displaySuccess('Aktualisiert', 'Angebotsinhalt wurde aktualisiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('edited');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
    },
}

