




















































































































import VueHtml2pdf from 'vue-html2pdf';

import { ref } from '@vue/composition-api';
import { useHttp } from '@/components/services/http.service';

import Paystub from "@/views/billing/Paystub.vue";
import TimesheetEntries from "@/views/billing/TimesheetEntries.vue";
import AdditionalAgreements from "@/views/billing/AdditionalAgreements.vue";
import Employee from "@/views/billing/Employee.vue";


function initialState() {
    return {
        isLoading: false,
        status: 200,
        employeeName: '',
    }
}

export default {
    name: 'paystubOverview',
    components: {
        'paystub': Paystub,
        'timesheet-entries': TimesheetEntries,
        'additional-agreements': AdditionalAgreements,
        'employee': Employee,
        VueHtml2pdf
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    data() {
        return initialState();
    },
    methods:{
        generateReport () {
            this.$refs.html2Pdf.generatePdf();
        }
    },
    computed:{
        curDate(){
          return {year: this.$route.params.year, month: String(this.$route.params.month).padStart(2, '0')}
        },
        prevDate(){
            const prev = new Date(this.$route.params.year, this.$route.params.month-1);
            prev.setMonth(prev.getMonth()-1);
            return {year: prev.getFullYear(), month: (prev.getMonth()+1).toString().padStart(2, '0')}
        },
        nextDate(){
            const prev = new Date(this.$route.params.year, this.$route.params.month-1);
            prev.setMonth(prev.getMonth()+1);
            return {year: prev.getFullYear(), month: (prev.getMonth()+1).toString().padStart(2, '0')}
        },
    }
};
