
































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import AdminPrivilegeNotification from '@/views/users/AdminPrivilegeNotification.vue';

function initialState() {
    return {
        modal: null,
        isLoading: false,
        isLoadingEmploymentEnd: false,
        menuContractExtensionDate: false,
        formattedContractExtensionDate: null,
        employmentEnd: '',
        employmentEnd$: null,
        form: {
            contractExtensionDate: null,
        }
    }
}

declare const jQuery: any;

export default {
    name: 'AddASVExtension',
    components: {
        'admin-privilege-notification': AdminPrivilegeNotification,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            const data = initialState();
            Object.assign(this.form, data.form);
            if (this.asvExtension) {
                this.form = { ... this.asvExtension };
            }
            this.formattedContractExtensionDate = this.formatDate(this.form.contractExtensionDate);

            this.employmentEnd$ = this.getEmploymentEnd();
            this.employmentEnd$.then((employmentEnd) => {
                this.employmentEnd = employmentEnd ? this.$moment(employmentEnd).format('DD.MM.YYYY') : '-';
            });
        });
    },
    watch: {
        menuContractExtensionDate(val) {
            val && setTimeout(() => (this.$refs.datePicker.activePicker = 'YEAR'));
        },
    },
    props: {
        modalId: null,
        asv: null,
        asvExtension: null,
        numExtensionsInTheLastTwoYears: null,
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                contractExtensionDate: {
                    required,
                    greaterThanEmploymentEnd(v) {
                        return this.employmentEnd$.then((employmentEnd) => {
                            return new Date(v) >= new Date(employmentEnd);
                        });
                    },
                    terminable() {
                        return !!this.asv.end;
                    }
                }
            },
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            setTimeout(() => { // wait for the anync validator execution to be completed
                if (this.$v.$pending || this.$v.$error) return;

                const notifier = getNotifier();
                this.isLoading = true;

                if (this.asvExtension) {
                    this.http.post('/user/contract/edit/asv-extension/' + this.asvExtension.id, this.$data.form).then(() => {
                        Object.assign(this.asvExtension, this.$data.form);
                        notifier.displaySuccess('Editiert', 'Vertragsverlängerung wurde editiert.');
                        this.modal.modal('hide');
                        this.isLoading = false;
                        this.$emit('edited');
                    }).catch((error: any) => {
                        notifier.displayServerError(error.message);
                        this.isLoading = false;
                    });
                } else {
                    this.http.post('/user/contract/add/asv-extension/' + this.asv.id, this.$data.form).then((response) => {
                        notifier.displaySuccess('Erstellt', 'Vertragsverlängerung wurde erstellt.');
                        this.modal.modal('hide');
                        this.isLoading = false;
                        this.$emit('added', response.data['asvExtension']);
                    }).catch((error: any) => {
                        notifier.displayServerError(error.message);
                        this.isLoading = false;
                    });
                }
            });
        },
        contractExtensionDateError(prop) {
            const errors = [];
            if (!this.$v['form'][prop].$dirty) return errors;
            !this.$v['form'][prop].required && errors.push('Bitte ausfüllen.');
            !this.$v['form'][prop].greaterThanEmploymentEnd && errors.push('Das Datum muss nach dem aktuellen Anstellungsende (' + this.employmentEnd + ') liegen.');
            !this.$v['form'][prop].terminable && errors.push('Der Anstellungsvertrag ist unbefristet. Eine Verlängerungsvertrag kann daher nicht erstellt oder editiert werden.');
            return errors;
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        getEmploymentEnd() {
            this.isLoadingEmploymentEnd = true;
            return this.http.get('/user/employment-end/' + this.asv.id).then((response) => {
                this.isLoadingEmploymentEnd = false;
                return response['data']['endDate'];
            }).catch((error: any) => {
                this.isLoadingEmploymentEnd = false;
            });
        }
    }
};
