












































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';

declare const jQuery: any;

function initialState() {
    return {
        form: {
            facility: null,
            regionShort: null,
            regionLong: null,
            note: null,
            termsNote: null,
            housingNote: null,
            address: {
                addressAddition: null,
                street: null,
                streetNumber: null,
                zip: null,
                city: null,
            }
        },
        isLoading: false,
    }
}

export default {
    name: 'EditClinicData',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            this.$data.form = { ... this.customer };
            this.$data.form.address = { ... this.customer.address };
        });
    },
    props: {
        modalId: null,
        customer: {},
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                facility: { required },
                regionShort: { required },
                regionLong: { required },
                address: {
                    street: { required },
                    streetNumber: { required },
                    zip: { required },
                }
            }
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/customer/edit/' + this.customer.id, this.$data.form).then(() => {
                Object.assign(this.customer, this.$data.form);
                notifier.displaySuccess('Aktualisiert', 'Klinikdaten wurden aktualisiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('edited');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        requiredError2(prop) {
            if (this.$v['form']['address'][prop].$dirty && !this.$v['form']['address'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
    }
}

