import { provide, inject } from '@vue/composition-api';
import router from '@/router';

import { useHttp } from '@/components/services/http.service';
import {User} from '@/components/services/user';

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

const parseJwt = function(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(b64DecodeUnicode(base64));
};

export function getUser() {
    const token = localStorage.getItem('lumis_session');
    return token ? new User(parseJwt(token)) : null;
}

export function logout(from?: string) {
    localStorage.removeItem('lumis_session');
    from = from ? from : router.currentRoute.path;
    if ('/login' !== from) {
        router.push({path: '/login', query: {returnUrl: from}});
    }
}

const createAuth = () => {

    const httpClient: any = useHttp();

    const MAX_REMAINING_SEC_BEFORE_REFRESH = 3600 * 24 * 3;
    let isRefreshingToken = false;

    const refreshToken = function() {
        isRefreshingToken = true;
        httpClient
            .post('refresh-token')
            .then((response: any) => {
                isRefreshingToken = false;
                if (response.data) {
                    localStorage.setItem('lumis_session', response.data);
                }
                return response.data;
            });
    };

    const refreshTokenIfNecessary = function() {
        if (!isRefreshingToken) {
            const user = getUser();
            const unixNow = Math.round(+new Date() / 1000);
            if (user !== null && user.exp - unixNow < MAX_REMAINING_SEC_BEFORE_REFRESH) {
                refreshToken();
            }
        }
    };

    httpClient.interceptors.request.use(
        config => {
            const token = localStorage.getItem('lumis_session');
            if (token) {
                refreshTokenIfNecessary();
                config.headers.common['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    return {
        login: function(username: string, password: string) {
            const bodyFormData = new FormData();
            bodyFormData.append('_username', username);
            bodyFormData.append('_password', password);
            return httpClient
                .post('login', bodyFormData)
                .then((response: any) => {
                    if (response.data) {
                        localStorage.setItem('lumis_session', response.data);
                    }
                    return response.data;
                });
        },
        logout: function() {
            return logout();
        },
        getUser() {
            return getUser();
        },
        hasAccessToPath(path: string) {
            const toRoute = router.resolve({
                path: path,
            });
            const expectedRole = toRoute.route.meta['expectedRole'];
            return !expectedRole || getUser().hasRole(expectedRole);
        },
    };

};

const authSymbol = Symbol();

export function provideAuth() {
    const auth = createAuth();
    provide(authSymbol, auth);
}

export function useAuth() {
    const auth = inject(authSymbol);
    if (!auth) throw new Error("No auth client provided!!!");

    return auth;
}
