





















import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';

export default {
    name: 'DeleteAvailability',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    props: {
        availabilityId: null,
    },
    data() {
        return {
            swalDelete: ['Löschen?', 'Das kann nicht rückgängig gemacht werden.'],
            isLoading: false,
        }
    },
    methods: {
        submit: function() {
            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/user/availability/delete/' + this.availabilityId).then(() => {
                notifier.displaySuccess('Entfernt', 'Die Verfügbarkeit wurde entfernt.');
                this.isLoading = false;
                this.$emit('deleted');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}

