















































































import { ref } from '@vue/composition-api';

import { required, minValue } from 'vuelidate/lib/validators';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import { useOutstandingPaystubsService } from '@/components/services/outstanding-paystubs.service';

declare const jQuery: any;

function initialState() {
    return {
        form: {
            transferredHours: null,
            other: null
        },
        previousPaystub: null,
        isLoading: false,
        modal: null,
    }
}

export default {
    name: 'ExecutePaystub',
    setup() {
        const http = ref();
        http.value = useHttp();
        const outstandingPaystubsService = useOutstandingPaystubsService();
        return { http, outstandingPaystubsService };
    },
    mounted() {
        this.notifier = getNotifier();
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
            Object.assign(this.$data, initialState());
            this.modal = jQuery('#' + this.modalId);
        });
        this.modal.on('shown.bs.modal', () => {
            this.getPreviousPaystub();
            if(this.savedPaystub){
                this.form.transferredHours = this.savedPaystub.minutesTransferred / 60;
                this.form.other = this.savedPaystub.other;
            }
        });
    },
    props: {
        modalId: {
            type: String,
        },
        date: {
            type: Object,
            required: true
        },
        employeeNumber: {
            required: true,
        },
        plannedMinutes: {
            type: Number
        },
        workedMinutes: {
            type: Number
        },
        savedPaystub: null,
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                transferredHours: {
                    required,
                    minValue: minValue((this.plannedMinutes/60).toFixed(2))
                },
                other: {}
            },
        }
    },
    methods: {
        getPreviousPaystub(){
            this.isLoading = true;
            const previousDate = this.prevDate;
            this.http.get('/billing/paystub/transfer/' + this.employeeNumber + '/' + previousDate.month + '-' + previousDate.year).then((response) => {
                this.previousPaystub = response.data['data'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        submit: function(event) {
            this.$v.$touch();
            if(this.$v.$error) return;

            const action = event.submitter.value;
            this.isLoading = true;
            const sendData = {
                minutesTransferred: Math.round(this.form.transferredHours*60),
                minutesWorked: this.workedMinutes,
                other: this.form.other
            };
            this.http.post('/billing/paystub/transfer/'+ action + '/' + this.employeeNumber + '/' + this.date.month + '-' + this.date.year, sendData).then(() => {
                if(action === 'execute'){
                    this.notifier.displaySuccess('Auszahlung durchgeführt', 'Die Auszahlung wurde durchgefürht.');
                }else{
                    this.notifier.displaySuccess('Änderungen gespeichert', 'Die Änderungen zur Auszahlung wurden gespeichert.');
                }
                this.modal.modal('hide');
                this.isLoading = false;
                this.outstandingPaystubsService.pollOutstandingPaystubs();
                this.$emit('executed');
            }).catch((error: any) => {
                this.notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].minValue) return ['Minimum Soll-Stunden.'];
            return [];
        },
    },
    computed: {
        prevDate(){
            const prev = new Date(this.date.year, this.date.month-1);
            prev.setMonth(prev.getMonth()-1);
            return {year: prev.getFullYear(), month: (prev.getMonth()+1).toString().padStart(2, '0')}
        },
        timeRemaining(){
            if(!this.form.transferredHours)
                return '';
            const remaining = this.previousPaystub ? this.previousPaystub.minutesRemaining/60 : 0;
            return (this.workedMinutes/60 - this.form.transferredHours + remaining).toFixed(2);
        }
    }
}

