






import * as Ladda from 'ladda';

export default {
  name: 'VueLadda',

  props: {
    // customizable button's class attribute - you can use your own CSS class
    'buttonClass': {
      type: String,
      default: 'ladda-button'
    },
    // use vue props validation to make sure "data-style" is given. (ladda need it)
    'dataStyle': {
      type: String,
      default: 'expand-left'
    },
    'dataSpinnerColor': {
        type: String,
        default: 'white',
    },
    'disabled': {
      type: Boolean,
      default: false,
    },
    // loading prop to change the status of this component.
    loading: {
      type: Boolean,
      required: true
    },
    progress: {
      validator: function(progress: number) {
        return progress >= 0 && progress <= 1;
      },
      default: 0
    }
  },

  watch: {
    loading: function(loading: boolean) {
      loading ? this.ladda.start() : this.ladda.stop();
    },

    progress: function(progress: number) {
      this.ladda.setProgress(progress);
    }
  },

  mounted: function() {
    this.ladda = Ladda.create(this.$refs.ladda);
    this.loading ? this.ladda.start() : this.ladda.stop();
  },

  beforeUnmount: function() {
    this.ladda.remove();
    delete this.ladda;
  }
};
