import { provide, inject } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';

const createCountryProvider = () => {
    const httpClient: any = useHttp();

    let countries$;

    let countriesAsOptions$ = Promise.resolve({});
    let countryMap$ = Promise.resolve({});

    return {
        load: function () {
            countries$ = httpClient.get('/countries').then((response: any) => {
                return response.data['data'];
            });

            countriesAsOptions$ = countries$.then((countries: any) => {
                const list = [];
                for (const country of countries) {
                    list.push({name: country['name'], value: country['iso']});
                }
                return list;
            });

            countryMap$ = countries$.then((countries: any) => {
                const map = {};
                for (const country of countries) {
                    map[country['iso']] = country;
                }
                return map;
            });
        },
        getCountriesAsOptions: function () {
            return countriesAsOptions$;
        },
        getCountryMap: function () {
            return countryMap$;
        },
    };

};

const countryProviderSymbol = Symbol();

export function provideCountryProvider() {
    const countryProvider = createCountryProvider();
    provide(countryProviderSymbol, countryProvider);
}

export function useCountryProvider() {
    const countryProvider = inject(countryProviderSymbol);
    if (!countryProvider) throw new Error('No country provider provided!!!');

    return countryProvider;
}
