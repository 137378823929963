import { provide, inject } from '@vue/composition-api';

const createContractRequirementsCheck = () => {

    let user = null;
    const unsatisfiedRequirements = [];

    return {
        setUser(_user) {
            user = _user;
        },
        requirementsSatisfied() {
            if (!user || !user.firstName) {
                return null;
            }
            this.calculateUnsatisfiedRequirements();
            return unsatisfiedRequirements.length === 0;
        },
        calculateUnsatisfiedRequirements() {
            unsatisfiedRequirements.splice(0, unsatisfiedRequirements.length); // Don't destroy the object by doing so: unsatisfiedRequirements = []. It would render array change detection void.
            if (!user.birthday) {
                unsatisfiedRequirements.push('Persönliche Daten - Geburtstag')
            }
            if (!user.nationality) {
                unsatisfiedRequirements.push('Persönliche Daten - Staatsangehörigkeit')
            }
            if (!user.address.street) {
                unsatisfiedRequirements.push('Adresse - Straße')
            }
            if (!user.address.streetNumber) {
                unsatisfiedRequirements.push('Adresse - Hausnummer')
            }
            if (!user.address.zip) {
                unsatisfiedRequirements.push('Adresse - PLZ')
            }
            if (!user.address.city) {
                unsatisfiedRequirements.push('Adresse - Stadt')
            }
            if (!user.address.email) {
                unsatisfiedRequirements.push('Adresse - E-Mail')
            }
            if (!user.professionCategoryId) {
                unsatisfiedRequirements.push('Qualifikation - Berufsgruppe')
            }
            if (user.professionCategoryId === 1 && !user.professionLevel) {
                unsatisfiedRequirements.push('Qualifikation - Berufslevel')
            }
            return unsatisfiedRequirements;
        },
        getUnsatisfiedRequirements() {
            return unsatisfiedRequirements;
        },
    };
};

const contractRequirementsCheckSymbol = Symbol();

export function provideContractRequirementsCheck() {
    const contractRequirementsCheck = createContractRequirementsCheck();
    provide(contractRequirementsCheckSymbol, contractRequirementsCheck);
}

export function useContractRequirementsCheck() {
    const contractRequirementsCheck = inject(contractRequirementsCheckSymbol);
    if (!contractRequirementsCheck) throw new Error("No contract requirements check provided!!!");

    return contractRequirementsCheck;
}
