



















































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';

function initialState() {
    return {
        modal: null,
        isLoading: false,
        menuStart: false,
        menuEnd: false,
        formattedStartDate: null,
        formattedEndDate: null,
        form: {
            start: null,
            end: null,
        }
    }
}

declare const jQuery: any;

export default {
    name: 'AddAvailability',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            const data = initialState();
            Object.assign(this.$data.form, data.form);
            this.formattedStartDate = null;
            this.formattedEndDate = null;
        });
    },
  /*
    watch: {
        menuStart(val) {
            val && setTimeout(() => (this.$refs.datePickerStart.activePicker = 'YEAR'));
        },
        menuEnd(val) {
            val && setTimeout(() => (this.$refs.datePickerEnd.activePicker = 'YEAR'));
        },
    },
   */
    props: {
        modalId: null,
        userId: null,
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                start: { required },
                end: {
                    required,
                    greaterThanStart() {
                        return new Date(this.form.end) > new Date(this.form.start);
                    }
                }
            },
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/user/availability/add/' + this.userId, this.$data.form).then((response) => {
                notifier.displaySuccess('Hinzugefügt', 'Verfügbarkeit wurde hinzugefügt.');
                this.modal.modal('hide');
                this.isLoading = false;

                this.$emit('added', { id: response.data['id'], ... this.$data.form });
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        requiredAndGreaterThanStartError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].greaterThanStart) return ['Enddatum muss nach dem Anfangsdatum liegen.'];
            return [];
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
    }
};
