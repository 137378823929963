













































































































































































































































































import {defineComponent, ref} from '@vue/composition-api';

import {Subject} from 'rxjs';
import {switchMap, debounceTime} from 'rxjs/operators';

import {useHttp} from '@/components/services/http.service';
import {useProfessionProvider} from '@/components/services/profession-provider.service';
import {useExpirationCalculator} from '@/components/services/expiration-calculator.service';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import TableFilter from '@/components/common/forms/TableFilter.vue';
import DatatableCheckbox from '@/components/common/forms/DatatableCheckbox.vue';
import AddUser from '@/views/users/AddUser.vue';

export default defineComponent({
    name: 'UsersOverview',
    components: {
        'add-user': AddUser,
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
        'datatable-checkbox': DatatableCheckbox,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        const professionProvider = useProfessionProvider();
        const expirationCalculator = useExpirationCalculator();
        return { professionProvider, http, expirationCalculator };
    },
    mounted() {
        this.getProfessionCategoryMap();
        this.getProfessionsMap();
        this.setTableProperties();
        
        this.filters = this.filterDefs;


        setTimeout(() => {
          this.$refs.tableFilter.setValues({'registrationStatus': 1});
        });
        this.$set(this.filterInput, 'registrationStatus', 1);

        this.searchQuery$ = new Subject().pipe(
            debounceTime(400),
        );
        this.searchQuerySubscription = this.searchQuery$.subscribe((query: string) => {
            this.saveTableProperty('searchQuery', query);
            this.searchQuery = query;
            this.items$.next(1);
        });

        this.items$ = new Subject().pipe(
            switchMap((page) => {
                this.isLoading = true;
                this.allSelected = false;
                this.currentPage = page;
                this.saveTableProperty('currentPage', page);
                return this.http.post('/users', {
                    'offset': this.currentPage * this.rowsPerPage - this.rowsPerPage,
                    'limit': this.rowsPerPage,
                    'sortBy': this.sortBy,
                    'sortDir': this.sortDesc ? 'desc' : 'asc',
                    'filterInput': this.filterInput,
                    'searchQuery': this.searchQuery,
                });
            }));
        this.itemsSubscription = this.items$.subscribe({
            next: (response: any) => {
                this.numTotalRows = response.data['data']['num_total_rows'];
                this.items = response.data['data']['rows'];
                this.$refs.userTable.clearSelected();
                this.isLoading = false;
            }, error: (err) => {
                console.log(err);
                this.isLoading = false;
            }
        });

        this.items$.next(this.currentPage);
    },
    destroyed() {
        this.itemsSubscription.unsubscribe();
        this.searchQuerySubscription.unsubscribe();
    },
    watch: {
        '$route'() {
            this.setTableProperties();
            this.items$.next(1);
        }
    },
    data() {
        return {
            todo: null,
            savedTableState: {},
            items$: null,
            itemsSubscription: null,
            searchQuery$: null,
            searchQuerySubscription: null,
            searchQuery: '',
            isLoading: false,
            professionCategoryMap: {},
            professionsMap: {},
            currentPage: 1,
            sortBy: 'created',
            sortDesc: true,
            rowsPerPage: 10,
            numTotalRows: null,
            items: [],
            filterInput: {},
            filters: [],
            filterListExpanded: false,
            allSelected: false,
            actions: [
                { title: 'Deaktivieren', icon: 'clear', function: (this as any).deactivateSelectedItems },
                { title: 'Aktivieren', icon: 'done', function: (this as any).activateSelectedItems },
            ],
            fields: [
                {
                    key: 'selected',
                },
                {
                  key: 'id',
                  label: 'ID',
                  sortable: true,
                },
                {
                  key: 'employeeNumber',
                  label: 'MA-Nr.',
                  sortable: true,
                },
                {
                  key: 'firstName',
                  label: 'Vorname',
                  sortable: true,
                },
                {
                    key: 'lastName',
                    label: 'Nachname',
                    sortable: true,
                },
                {
                    key: 'registrationStatus',
                    label: 'Status',
                    sortable: true,
                },
                {
                    key: 'created',
                    label: 'registriert seit',
                    sortable: true,
                },
                {
                    key: 'professionCategory',
                    label: 'Berufsgruppe',
                    sortable: true,
                },
                {
                    key: 'professions',
                    label: 'Fachbereiche',
                    sortable: false,
                    class: 'td-professions',
                },
                {
                    key: 'availabilities',
                    label: 'Verfügbarkeit',
                    sortable: false,
                },
                {
                  key: 'expirationEmployment',
                  label: 'Anstellungsende',
                  sortable: true,
                },
                {
                  key: 'contactData',
                  label: 'Kontaktdaten',
                  sortable: false,
                },
                {
                    key: 'todo',
                    label: 'TODOs',
                    sortable: false,
                },
                {
                    key: 'action',
                    label: 'Aktion',
                    sortable: false,
                },
            ],
            filterDefs: [
                {
                    title: 'TODOs',
                    type: 'select',
                    prop: 'todo',
                    placeholder: 'Bitte auswählen',
                    options: [
                        { name: 'kürzlich ausgelaufene Anstellungsverhältnisse prüfen', value: 'review_expired_employments' },
                        { name: 'Verträge als unterschrieben markieren', value: 'sign' },
                        { name: 'Verträge als Rückläufer markieren', value: 'mark_as_returned' },
                    ],
                },
                {
                    title: 'Vorname',
                    type: 'text',
                    prop: 'firstName',
                },
                {
                    title: 'Nachname',
                    type: 'text',
                    prop: 'lastName',
                },
                {
                    title: 'registriert vor',
                    type: 'date',
                    prop: 'createdBefore',
                },
                {
                    title: 'registriert nach',
                    type: 'date',
                    prop: 'createdAfter',
                },
                {
                    title: 'Mitarbeiter',
                    type: 'select',
                    prop: 'registrationStatus',
                    placeholder: 'Bitte auswählen',
                    options: [
                        { name: 'Mitarbeiter', value: 1 },
                        { name: 'Interessent', value: 0 },
                        { name: 'Unbestätigt', value: -1 },
                    ],
                },
                {
                    title: 'Aktiv',
                    type: 'select',
                    prop: 'active',
                    placeholder: 'Bitte auswählen',
                    options: [
                        { name: 'Inaktiv', value: 0 },
                        { name: 'Aktiv', value: 1 },
                    ],
                },
                {
                    title: 'Berufsgruppe',
                    type: 'select',
                    prop: 'professionCategory',
                    placeholder: 'Bitte auswählen',
                    options: (this as any).professionProvider.getProfessionCategoriesAsOptions(),
                },
                {
                    title: 'Fachbereich',
                    type: 'select',
                    prop: 'profession',
                    placeholder: 'Bitte auswählen',
                    parent: 'professionCategory',
                    options: (this as any).getProfessionCategoryFilterOptions(),
                },
                {
                    title: 'verfügbar von / bis',
                    type: 'date-range',
                    prop: 'availability',
                },
            ],
        }
    },
    computed: {
        filterInputEmpty() {
            for (const prop in this.filterInput) {
                if (Object.prototype.hasOwnProperty.call(this.filterInput, prop)) {
                    if (this.filterInput[prop] !== null && this.filterInput[prop] !== '' && this.filterInput[prop] !== undefined) {
                        return false;
                    }
                }
            }
            return true;
        },
        today() {
            return new Date().setHours(0, 0, 0, 0);
        },
    },
    methods: {
        sortingChanged(ctx) {
            this.saveTableProperty('sortBy', ctx.sortBy);
            this.saveTableProperty('sortDesc', ctx.sortDesc);
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.items$.next(1);
        },
        rowsPerPageChanged() {
            this.saveTableProperty('rowsPerPage', this.rowsPerPage);
            this.items$.next(1);
        },
        onPageChange(page) {
            this.items$.next(page);
        },
        filter(values) {
            this.todo = values['todo'] ? values['todo'] : null;
            for (const prop in values) {
                if (Object.prototype.hasOwnProperty.call(values, prop)) {
                    // Properties of 'filterInput' need to be reactive as computed property 'filterInputEmpty' depends on them.
                    this.$set(this.filterInput, prop, values[prop]);
                }
            }
            this.saveTableProperty('filterInput', this.filterInput);
            this.items$.next(1);
        },
        selectOrClearAll() {
            if (this.allSelected) {
                this.$refs.userTable.clearSelected();
            } else {
                this.$refs.userTable.selectAllRows();
            }
        },
        selectOrUnselect(row) {
            if (!row.rowSelected) {
                row.selectRow();
            } else {
                row.unselectRow();
                this.allSelected = false;
            }
        },
        onUserAdded() {
            this.sortBy = 'created';
            this.sortDesc = true;
            this.items$.next(1);
        },
        activateSelectedItems() {
            this.isLoading = true;
            return this.http.post('/users/activate', this.getSelectedIds()).then(() => {
                this.isLoading = false;
                this.items$.next(this.currentPage);
            });
        },
        deactivateSelectedItems() {
            this.isLoading = true;
            return this.http.post('/users/deactivate', this.getSelectedIds()).then(() => {
                this.isLoading = false;
                this.items$.next(this.currentPage);
            });
        },
        getSelectedRows() {
            const selectedRows = [];
            this.items.forEach((item, index) => {
                if (this.$refs.userTable.isRowSelected(index)) {
                    selectedRows.push(index);
                }
            });
            return selectedRows;
        },
        getSelectedIds() {
            const selectedRows = this.getSelectedRows();
            const ids = [];
            for (const selectedRow of selectedRows) {
                ids.push(this.items[selectedRow]['id']);
            }
            return ids;
        },
        getProfessionsList(list) {
            const arr = [];
            for (const key of list) {
                this.professionsMap[key] ? arr.push(this.professionsMap[key]['name']) : arr.push(key);
            }
            return arr.join(', ');
        },
        toggleFilterList() {
            this.filterListExpanded = !this.filterListExpanded;
        },
        clearFilters() {
            this.$refs.tableFilter.clearValues();
            this.filterInput = {};
            this.clearTableProperties();
            this.items$.next(1);
        },
        setTableProperties() {
            const todo = this.$route.query.todoFilter;
            if (todo) {
                this.clearTableProperties();
                this.saveTableProperty('filterInput', {todo: todo});
            }

            this.loadTableProperties();

            if (this.savedTableState.filterInput) {
                setTimeout(() => {
                    this.todo = this.savedTableState.filterInput['todo'] ? this.savedTableState.filterInput['todo'] : null;
                    this.$refs.tableFilter.setValues(this.savedTableState.filterInput);
                    // expand the filters if at least one filter value is pre-set
                    for (const prop in this.savedTableState.filterInput) {
                        if (this.savedTableState.filterInput[prop] !== null && this.savedTableState.filterInput[prop] !== '') {
                            this.filterListExpanded = true;
                            break;
                        }
                    }
                });
            }
        },
        saveTableProperty(prop, value) {
            this.savedTableState[prop] = value;
            window.sessionStorage.setItem('user-table', JSON.stringify(this.savedTableState));
        },
        loadTableProperties() {
            this.savedTableState = JSON.parse(window.sessionStorage.getItem('user-table'));
            if (this.savedTableState === null) {
                this.savedTableState = {};
                window.sessionStorage.setItem('user-table', JSON.stringify(this.savedTableState));
            }
            Object.assign(this.$data, this.savedTableState);
        },
        clearTableProperties() {
            this.savedTableState = {};
            window.sessionStorage.removeItem('user-table');
        },
        async getProfessionCategoryMap() {
            this.professionCategoryMap = await this.professionProvider.getProfessionCategoryMap();
        },
        async getProfessionsMap() {
            this.professionsMap = await this.professionProvider.getProfessionsMap();
        },
        async getProfessionCategoryFilterOptions() {
            const options = {};
            const categories = await this.professionProvider.getProfessionCategoryMap();
            for (const categoryId in categories) {
                options[categoryId] = await this.professionProvider.getProfessionsByCategoryAsOptions(categoryId);
            }
            return options;
        },
    }
});
