





















































































































import { ref } from '@vue/composition-api';

import { required, integer } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import { useProfessionProvider } from '@/components/services/profession-provider.service';
import { useContractRequirementsCheck } from '@/components/services/contract-requirements-check.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { useExpirationCalculator } from '@/components/services/expiration-calculator.service';
import AdminPrivilegeNotification from '@/views/users/AdminPrivilegeNotification.vue';

function initialState() {
    return {
        modal: null,
        isLoading: false,
        menuBegin: false,
        menuEnd: false,
        formattedBegin: null,
        formattedEnd: null,
        professionCategoryName: null,
        professionLevelName: null,
        asvTasks: [],
        show523Warning: false,
        form: {
            weeklyWorkingHours: null,
            monthlyWorkingHours: null,
            begin: null,
            end: null,
            task: null,
            hurdleRate: null,
            professionCategory: null,
            professionLevel: null,
        }
    }
}

declare const jQuery: any;

export default {
    name: 'AddASV',
    components: {
        'admin-privilege-notification': AdminPrivilegeNotification,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        const professionProvider = useProfessionProvider();
        const contractRequirementsCheck = useContractRequirementsCheck();
        const inputSuggestions = useInputSuggestions();
        const expirationCalculator = useExpirationCalculator();
        return { http, professionProvider, contractRequirementsCheck, inputSuggestions, expirationCalculator };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            this.getAsvTasks();

            const data = initialState();
            if (this.asv) {
                this.form = Object.keys(data.form).reduce((a, key) => ({ ...a, [key]: this.asv[key]}), {});
                this.professionProvider.getProfessionCategoryMap().then((map) => {
                    this.professionCategoryName = map[this.asv['professionCategory']].name;
                });
                this.professionLevelName = this.asv['professionLevelName'];
            } else {
                Object.assign(this.form, data.form);
                this.form.professionCategory = this.professionCategory;
                this.form.professionLevel = this.professionLevel;
            }
            this.formattedBegin = this.formatDate(this.form.begin);
            this.formattedEnd = this.formatDate(this.form.end);
        });
    },
    props: {
        modalId: null,
        userId: null,
        professionCategory: null,
        professionLevel: null,
        asv: null,
        employmentEnd: null,
        isEmployed: null,
    },
    data() {
        return initialState();
    },
    watch: {
        professionCategory(val) {
            this.professionProvider.getProfessionCategoryMap().then((map) => {
                this.professionCategoryName = val ? map[val].name : '';
            });
        },
        professionLevel(val) {
            this.professionProvider.getProfessionLevelById(val).then((level) => {
                this.professionLevelName = val ? level.name : '';
            });
        },
        employmentEnd(val) {
            this.show523Warning = !this.isEmployed && !this.expirationCalculator.isNewEmploymentAllowed(val);
        },
        isEmployed(val) {
            this.show523Warning = !val && !this.expirationCalculator.isNewEmploymentAllowed(this.employmentEnd);
        }
    },
    validations () {
        return {
            form: {
                weeklyWorkingHours: { required, integer },
                begin: { required },
                task: { required },
                professionCategory: { required },
                hurdleRate: { required },
            },
        }
    },
    computed: {
        professionFullname() {
            let name = this.professionCategoryName;
            if (this.professionLevelName) {
                name+= ' (' + this.professionLevelName + ')';
            }
            return name;
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            this.$data.form.monthlyWorkingHours = Math.round(this.$data.form.weeklyWorkingHours * 4.3333);

            if (this.asv) {
                this.http.post('/user/contract/edit/asv/' + this.asv.id, this.$data.form).then(() => {
                    Object.assign(this.asv, this.$data.form);
                    notifier.displaySuccess('Editiert', 'Anstellungsvertrag wurde editiert.');
                    this.modal.modal('hide');
                    this.isLoading = false;
                    this.inputSuggestions.load();
                    this.$emit('edited');
                }).catch((error: any) => {
                    notifier.displayServerError(error.message);
                    this.isLoading = false;
                });
            } else {
                this.http.post('/user/contract/add/asv/' + this.userId, this.$data.form).then((response) => {
                    notifier.displaySuccess('Erstellt', 'Anstellungsvertrag wurde erstellt.');
                    this.modal.modal('hide');
                    this.isLoading = false;
                    this.inputSuggestions.load();
                    this.$emit('added', { asv: response.data['asv'], employeeNumber: response.data['employeeNumber'] });
                }).catch((error: any) => {
                    notifier.displayServerError(error.message);
                    this.isLoading = false;
                });
            }
        },
        getMinDate() {
            return process.env.NODE_ENV === 'development' ? null : new Date().toISOString().substr(0, 10);
        },
        requirementsSatisfied() {
            return this.contractRequirementsCheck.requirementsSatisfied();
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        integerAndRequiredError(prop) {
            const errors = [];
            if (!this.$v['form'][prop].$dirty) return errors;
            !this.$v['form'][prop].required && errors.push('Bitte ausfüllen.');
            !this.$v['form'][prop].integer && errors.push('Bitte nur eine ganze Zahl eingeben.');
            return errors;
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        async getAsvTasks() {
            this.asvTasks = await this.inputSuggestions.getAsvTasks();
        },
    }
};
