






































































import { ref } from '@vue/composition-api';

import { email, integer } from 'vuelidate/lib/validators';

import { useHttp } from '@/components/services/http.service';

function initialState() {
    return {
        form : {
            addressAddition: null,
            street: null,
            streetNumber: null,
            zip: null,
            city: null,
            email: null,
            telephone: null,
            mobile: null,
        },
    }
}

export default {
    name: 'AddressForm',
    data() {
        return initialState();
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    props: {
        userId: null,
    },
    validations () {
        return {
            form: {
                email: {
                    email,
                    uniqueEmail(value) {
                        if (!this.userId) {
                            return true;
                        }
                        if (!this.$v['form']['email'].email) {
                            return true;
                        }
                        if (!value) {
                            return true;
                        }

                        return new Promise((resolve) => {
                            return this.http.get('/user/find-by-mail?mail=' + value).then(response => {
                                resolve(response.data['OK'] === 0 || response.data['userId'] === this.userId);
                            });
                        });
                    }
                },
                telephone: { integer },
                mobile: { integer },
            }
        }
    },
    methods: {
        validate() {
            this.$v.$touch();
            return !this.$v.$pending && !this.$v.$anyError;
        },
        clear() {
            const data = initialState();
            Object.assign(this.form, data.form);
            this.$v.$reset();
        },
        integerError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].integer) return ['Nur Zahlen erlaubt.'];
            return [];
        },
        emailError(prop) {
            const errors = [];
            if (!this.$v['form'][prop].$dirty) return errors;
            !this.$v['form'][prop].email && errors.push('Keine E-Mail');
            !this.$v['form'][prop].uniqueEmail && !this.$v['form'][prop].$pending && errors.push('E-Mail schon vergeben.');
            return errors;
        },
    }
}

