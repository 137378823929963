var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row border-bottom"},[_c('nav',{staticClass:"navbar navbar-static-top",staticStyle:{"margin-bottom":"0"},attrs:{"role":"navigation"}},[_c('div',{staticClass:"navbar-header"},[_c('a',{staticClass:"navbar-minimalize minimalize-styl-2 btn btn-primary",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleNavigation()}}},[_c('i',{staticClass:"fa fa-bars"})])]),_c('ul',{staticClass:"nav navbar-top-links navbar-right"},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.numOpenTasks),expression:"numOpenTasks"}],staticClass:"dropdown"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"count-info",attrs:{"data-toggle":"dropdown","href":"#","aria-expanded":"false"}},'a',attrs,false),on),[_c('i',{staticClass:"fa fa-user-md"}),_c('span',{staticClass:"label label-danger"},[_vm._v(" "+_vm._s(_vm.numOpenTasks)+" ")])])]}}])},[_c('span',[_vm._v("Fachkräfteaufgaben")])]),_c('ul',{staticClass:"dropdown-menu dropdown-alerts"},[(_vm.numEmploymentEndTasks > 0)?_c('li',[_c('router-link',{attrs:{"to":{path:'/users', query: {todoFilter: 'review_expired_employments'}}}},[_c('span',{staticClass:"task"},[_c('i',{staticClass:"text-danger fa fa-hourglass-end fa-lg fa-fw"}),_c('span',{staticClass:"task-description"},[_vm._v(" "+_vm._s(_vm.numEmploymentEndTasks)+" "),(_vm.numEmploymentEndTasks > 1)?_c('span',[_vm._v(" ausgelaufene Anstellungsverhältnisse")]):_c('span',[_vm._v(" ausgelaufenes Anstellungsverhältnis")]),_vm._v(" prüfen ")])])])],1):_vm._e(),(_vm.numEmploymentEndTasks > 0)?_c('li',{staticClass:"dropdown-divider"}):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{path:'/users', query: {todoFilter: 'sign'}}}},[_c('span',{staticClass:"task"},[_c('i',{staticClass:"text-danger fa fa-pencil-square-o fa-lg fa-fw"}),_c('span',{staticClass:"task-description"},[_vm._v(_vm._s(_vm.numSignTasks)+" Verträge zu unterschreiben")])])])],1),_c('li',{staticClass:"dropdown-divider"}),_c('li',[_c('router-link',{attrs:{"to":{path:'/users', query: {todoFilter: 'mark_as_returned'}}}},[_c('span',{staticClass:"task"},[_c('i',{staticClass:"text-danger fa fa-envelope-o fa-lg fa-fw"}),_c('span',{staticClass:"task-description"},[_vm._v(_vm._s(_vm.numReturnTasks)+" Verträge als Rückläufer zu markieren")])])])],1)])],1),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.outstandingCustomerInvoicesCount),expression:"outstandingCustomerInvoicesCount"}],staticClass:"dropdown"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"count-info",attrs:{"data-toggle":"dropdown","href":"#","aria-expanded":"false"}},'a',attrs,false),on),[_c('span',{staticClass:"material-icons"},[_vm._v("announcement")]),_c('span',{staticClass:"label label-danger"},[_vm._v(_vm._s(_vm.outstandingCustomerInvoicesCount))])])]}}])},[_c('span',[_vm._v("Ausstehende Rechnung an Kunden")])]),_c('ul',{staticClass:"dropdown-menu dropdown-alerts"},_vm._l((_vm.outstandingCustomerInvoices),function(count,date){return _c('li',{key:date,staticClass:"pl-3"},[_c('router-link',{staticClass:"month-navi-link",attrs:{"to":{name: 'customerInvoices', query: {date: _vm.$moment(date, 'YYYY-MM').format('MM-YYYY'), notProcessed: true}},"custom":""},nativeOn:{"click":function($event){return _vm.$router.go(0)}}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(date,'MM.YYYY'))+" "),_c('span',{staticClass:"label label-danger ml-3"},[_vm._v(_vm._s(count))])])])],1)}),0)],1),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.outstandingPaystubsCount),expression:"outstandingPaystubsCount"}],staticClass:"dropdown"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"count-info",attrs:{"data-toggle":"dropdown","href":"#","aria-expanded":"false"}},'a',attrs,false),on),[_c('span',{staticClass:"material-icons"},[_vm._v("euro")]),_c('span',{staticClass:"label label-danger"},[_vm._v(_vm._s(_vm.outstandingPaystubsCount))])])]}}])},[_c('span',[_vm._v("Ausstehende Lohnabrechnungen")])]),_c('ul',{staticClass:"dropdown-menu dropdown-alerts"},_vm._l((_vm.outstandingPaystubs),function(count,date){return _c('li',{key:date,staticClass:"pl-3"},[_c('router-link',{attrs:{"to":{path:'/billing/paystubs', query: {date: _vm.$moment(date, 'YYYY-MM').format('MM-YYYY')}},"custom":""},nativeOn:{"click":function($event){return _vm.$router.go(0)}}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(date,'MM.YYYY'))+" "),_c('span',{staticClass:"label label-danger ml-3"},[_vm._v(_vm._s(count))])])])],1)}),0)],1),_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_c('i',{staticClass:"fa fa-sign-out"}),_vm._v(" Ausloggen ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }