




























import { defineComponent } from '@vue/composition-api';

import { useAuth } from '@/components/services/auth.service';
import { useProfessionProvider } from '@/components/services/profession-provider.service';
import { useCountryProvider } from '@/components/services/country-provider.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { useOpenTasksService } from '@/components/services/open-tasks.service';

declare const jQuery: any;

export default defineComponent({
    name: 'Login',
    setup() {
        const professionProvider = useProfessionProvider();
        const countryProvider = useCountryProvider();
        const auth = useAuth();
        const inputSuggestions = useInputSuggestions();
        const openTasksService = useOpenTasksService();
        return { auth, professionProvider, countryProvider, inputSuggestions, openTasksService };
    },
    mounted() {
        jQuery('.modal-backdrop').remove();
    },
    data() {
        return {
            username: '',
            password: '',
            error: '',
            loggedIn: false,
        }
    },
    methods: {
        processForm: function () {
            this.auth.login(this.username, this.password).then(() => {
                this.loggedIn = true;

                this.professionProvider.load();
                this.countryProvider.load();
                this.inputSuggestions.load();
                this.openTasksService.pollOpenTasks();

                setTimeout(() => {
                    const returnUrl = this.$route.query['returnUrl'];
                    if (returnUrl && this.auth.hasAccessToPath(returnUrl)) {
                        this.$router.push(returnUrl);
                    } else {
                        this.$router.push('/');
                    }
                }, 400);
            }).catch((error: any) => {
                console.log(error.response);
                this.error = 'Ungültige Zugangsdaten';
            });
        },
    },
    computed: {
        year() {
            return new Date().getFullYear();
        }
    }
});
