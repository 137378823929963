





























import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import DeleteBlacklistUser from '@/views/customers/DeleteBlacklistUser.vue';

export default {
    name: 'BlacklistUsers',
    components: {
      'delete-blacklist-user': DeleteBlacklistUser,
    },
    setup() {
      const http = ref();
      http.value = useHttp();
      return { http };
    },
    props: {
      blacklistUsers: null,
    },
    methods: {
      onDeleted(index) {
        this.blacklistUsers.splice(index, 1);
      }
    }
}

