









































































































































import { ref } from '@vue/composition-api';
import { useHttp } from '@/components/services/http.service';
import {Subject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import TransitionExpand from "@/components/common/animations/TransitionExpand.vue";
import TableFilter from "@/components/common/forms/TableFilter.vue";

function initialState() {
    return {
        isLoading: false,
        items$: null,
        paystubTransferred: null,
        itemsSubscription: null,
        searchQuery$: null,
        searchQuerySubscription: null,
        searchQuery: '',
        currentPage: 1,
        sortBy: 'date',
        sortDesc: false,
        rowsPerPage: 50,
        numTotalRows: null,
        items: [],
        filterInput: {},
        filters: [],
        filterListExpanded: false,
        fields: [
            {
                key: 'date',
                label: 'Datum',
                sortable: true,
            },
            {
                key: 'day',
                label: 'Tag',
                sortable: true,
            },
            {
                key: 'start',
                label: 'von',
                sortable: false,
            },
            {
                key: 'end',
                label: 'bis',
                sortable: false,
            },
            {
                key: 'break',
                label: 'Pause',
                sortable: false,
            },
            {
                key: 'hours',
                label: 'Stunden',
                sortable: false,
            },
            {
                key: 'typeOfDay',
                label: 'Status',
                sortable: true,
            },
            {
                key: 'typeOfWork',
                label: 'Dienst',
                sortable: true,
            },
            {
                key: 'facility',
                label: 'Einrichtung',
                sortable: true,
            },
        ],
        filterDefs: [
            {
                title: 'Einrichtung',
                type: 'text',
                prop: 'facility',
            },
        ],
    }
}

export default {
    name: 'paystubTimesheetEntries',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    components:{
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
    },
    data() {
        return initialState();
    },
    props:{
        date: {
            type: Object,
            required: true
        },
        employeeNumber: {
            required: true,
        }
    },
    mounted() {
        this.filters = this.filterDefs;

        this.searchQuery$ = new Subject().pipe(
            debounceTime(400),
        );
        this.searchQuerySubscription = this.searchQuery$.subscribe((query: string) => {
            this.searchQuery = query;
            this.items$.next(1);
        });

        this.items$ = new Subject().pipe(
            switchMap((page) => {
                this.isLoading = true;
                this.currentPage = page;
                return this.http.post('/billing/paystub/timesheet-entries/filters/' +
                 this.employeeNumber + '/' + this.date.month + '-' + this.date.year, {
                    'offset': this.currentPage * this.rowsPerPage - this.rowsPerPage,
                    'limit': this.rowsPerPage,
                    'sortBy': this.sortBy,
                    'sortDir': this.sortDesc ? 'desc' : 'asc',
                    'filterInput': this.filterInput,
                    'searchQuery': this.searchQuery,
                });
            }));
        this.itemsSubscription = this.items$.subscribe({
            next: (response: any) => {
                this.numTotalRows = response.data['data']['num_total_rows'];
                this.items = response.data['data']['rows'];
                this.paystubTransferred = response.data['data']['paystubTransferred'];
                this.isLoading = false;
            }, error: (err) => {
                console.log(err);
                this.isLoading = false;
            }
        });

        this.items$.next(this.currentPage);
    },
    methods: {
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.items$.next(1);
        },
        rowsPerPageChanged() {
            this.items$.next(1);
        },
        onPageChange(page) {
            this.items$.next(page);
        },
        toggleFilterList() {
            this.filterListExpanded = !this.filterListExpanded;
        },
        clearFilters() {
            this.$refs.tableFilter.clearValues();
            this.filterInput = {};
            this.clearTableProperties();
            this.items$.next(1);
        },
        filter(values) {
            for (const prop in values) {
                if (Object.prototype.hasOwnProperty.call(values, prop)) {
                    // Properties of 'filterInput' need to be reactive as computed property 'filterInputEmpty' depends on them.
                    this.$set(this.filterInput, prop, values[prop]);
                }
            }
            this.items$.next(1);
        },
        applyBtableRowAttr(item){
            if(item && this.paystubTransferred && !item.isEmployeePaystubProcessed) {
                return {title: "Leistungsbogen wurde nach vollendeter Lohnabrechnung hinzugefügt!"};
            }
        },
        applyBtableRowClass(item){
            if(item && this.paystubTransferred && !item.isEmployeePaystubProcessed) {
                return "table-danger";
            }
        }
    },
    computed:{
        dayString(){
            return [
                '',
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
                'So',
            ];
        }
    }
};
