


























import { ref } from '@vue/composition-api';

import { useContractRequirementsCheck } from '@/components/services/contract-requirements-check.service';

export default {
    name: 'ContractRequirementsFailed',
    setup() {
        const contractRequirementsCheck = ref();
        contractRequirementsCheck.value = useContractRequirementsCheck();
        return { contractRequirementsCheck };
    },
    mounted() {
        this.requirementsList = this.contractRequirementsCheck.getUnsatisfiedRequirements();
    },
    data() {
        return {
            requirementsList: [],
        }
    },
    props: {
        modalId: null,
    },
}

