














































































































    import {defineComponent} from '@vue/composition-api';

    import {Subject} from 'rxjs';
    import {debounceTime} from 'rxjs/operators';

    export default defineComponent({

        name: 'TableFilters',
        subscriptions () {
            return {
                debounce$: new Subject().pipe(
                    debounceTime(400),
                )};
        },
        mounted() {
            this.$observables.debounce$.subscribe((key) => {
                this.emit(key);
            });
        },
        props: {
            filters: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                options: {},
                values: {},
				formattedValues: {},
                datePickerMenus: {},
                subFilters: {},
            }
        },
        watch: {
            filters: function(filters) {
                let rangePickerIndex = 0;
                for (const key in filters) {
                    const filter = filters[key];

                    // open date-range filters with the YEAR-view if no value is selected
                    if (filter['type'] === 'date-range') {
                        const index = rangePickerIndex++;
                        const cb = (menuOpen) => {
                            const val = this.values[filter['prop']];
                            const datePickerType = val ? 'DATE' : 'YEAR';
                            menuOpen && setTimeout(() => this.$refs.rangePickers[index].activePicker = datePickerType);
                        };
                        this.$watch(() => this.datePickerMenus[filter['prop']], cb);
                    }

                    // allow async options for a select box
                    const options = filter['options'];
                    if (options !== undefined) {
                        if (options instanceof Promise) {
                            options.then((options) => this.options[filter['prop']] = options);
                        } else {
                            this.options[filter['prop']] = options;
                        }
                    }

                    if (filter['parent']) {
                        if (!this.subFilters[filter['parent']]) {
                            this.subFilters[filter['parent']] = [];
                        }
                        this.subFilters[filter['parent']].push(filter['prop']);
                    }
                }
            },
        },
        methods: {
            clearFocus() {
                this.$nextTick(() => {
					this.$nextTick(() => {
						for (const filter of this.$refs.filter) {
							filter.blur();
						}
					})
                  })
            },
            selectOptionsByParentValue(filter) {
                let options = [];
                const optionSets = this.options[filter['prop']];
                if (!optionSets) return options;

                if (optionSets[this.values[filter['parent']]]) {
                    options = optionSets[this.values[filter['parent']]];
                    if (!options.some((option) => option.value === this.values[filter['prop']])) {
                        this.values[filter['prop']] = null;
                    }
                    return options;
                }
                for (const key in optionSets) {
                    if (optionSets[key].length > 0) {
                        options = options.concat(optionSets[key]);
                        options.push({divider: true});
                    }
                }
                options.pop();
                return options;

            },
			formatDate(value: any) {
                if (value) {
                    value = value.split('-');
                    return value[2] + '.' + value[1] + '.' + value[0].slice(-2);
                }
                return null;
			},
            formatDateRange(value: any) {
                if (value) {
                    const date1 = this.formatDate(value[0]);
                    const date2 = this.formatDate(value[1]);
                    return new Date(value[1]) > new Date(value[0]) ? date1 + ' - ' + date2 :  date2 + ' - ' + date1;
                }
                return null;
            },
            dateEmit(key: string) {
                const data = {};
                data[key] = this.values[key];
                this.$emit('input', data);
            },
            dateRangeEmit(key: string) {
                const data = {};
                const value = this.values[key];
                data[key] = new Date(value[1]) > new Date(value[0]) ? [value[0], value[1]] : [value[1], value[0]];
                this.$emit('input', data);
            },
            debounceEmit(key: string) {
                this.$observables.debounce$.next(key);
            },
            emit(key) {
                this.$nextTick(() => { // wait until sub filters have set their values
                    const data = {};
                    data[key] = typeof this.values[key] === 'string' ? this.values[key] : this.values[key];
                    if (this.subFilters[key]) {
                        for (const _key of this.subFilters[key]) {
                            data[_key] = this.values[_key];
                        }
                    }
                    this.$emit('input', data);
                });
            },
            clearValues() {
                for (const key in this.filters) {
                    const filter = this.filters[key];
                    const prop = filter['prop'];
                    this.$set(this.values, prop, null);
                    if (filter['type'] === 'date' || filter['type'] === 'date-range') {
                        this.$set(this.formattedValues, prop, null);
                    }
                }
            },
            setValues(values) {
                for (const key in this.filters) {
                    const filter = this.filters[key];
                    const prop = filter['prop'];
                    if (typeof values[prop] !== 'undefined') {
                        if (filter['type'] === 'date') {
                            this.$set(this.formattedValues, prop, this.formatDate(values[prop]));
                        } else if (filter['type'] === 'date-range') {
                            this.$set(this.formattedValues, prop, this.formatDateRange(values[prop]));
                        }
                        this.$set(this.values, prop, values[prop]);
                    }
                }
            }
        }
    });
