






























import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';

function initialState() {
    return {
        modal: null,
        isLoading: false,
        form: {
            file: null,
        }
    }
}

declare const jQuery: any;

export default {
    name: 'UploadJobApplication',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            const data = initialState();
            Object.assign(this.$data.form, data.form);
        });
    },
    props: {
        modalId: null,
        userId: null,
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                file: {
                    required,
                    hasPdfExtension(value) {
                        return required(value) && value['type'] === 'application/pdf';
                    }
                }
            },
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            const formData = new FormData();
            formData.append('job-application', this.$data.form.file);

            this.http.post('/user/job-application/upload/' + this.userId, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((response) => {
                notifier.displaySuccess('Hochgeladen', 'Die Profildatei wurde auf dem Server hinterlegt.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('uploaded', response.data['profilePdfDate']);
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        fileError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].hasPdfExtension) return ['Nur PDF-Dateien erlaubt.'];
            return [];
        },
    }
};
