import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCompositionAPI from '@vue/composition-api';

// import own plugins and directives
import VueLadda from '@/components/plugins/vue-ladda';
import VueSwal from '@/components/plugins/vue-swal-delete';
import BarChartjs from '@/components/plugins/vue-bar-chartjs';

// add styles and scripts to the application
import jQuery from 'jquery';
import 'pace-js';
import 'toastr/build/toastr.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import {Decimal} from 'decimal.js';
import Vue2Filters from 'vue2-filters';
import Vuelidate from 'vuelidate';
import VueMoment from 'vue-moment';
import VueRx from 'vue-rx';
import vuetify from './components/plugins/vuetify/vuetify';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';
import {IconsPlugin, PaginationPlugin, SpinnerPlugin, TablePlugin, BadgePlugin, CollapsePlugin } from 'bootstrap-vue';
import './assets/font-awesome/scss/font-awesome.scss';
import './assets/style.scss';

// expose jQuery globally
declare const window: any;
window.$ = window.jQuery = jQuery;
// Prevent jQuery modal closing on clicking outside globally
window.$.fn.modal.prototype.constructor.Constructor.Default.backdrop = 'static';

// expose Decimal globally
window.Decimal = Decimal;

// start pace js
declare const Pace: any;
Pace.start();

// add jQuery plugins (use require instead of import to ensure that the library is fetched synchronously)
require('jquery-slimscroll');

const toastr = require('toastr/build/toastr.min.js');
window.toastr = toastr;

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(VueLadda);
Vue.use(VueSwal);
Vue.use(BarChartjs);
Vue.use(Vuelidate);
Vue.use(VueMoment);
Vue.use(VueRx);
Vue.use(Vue2Filters);
Vue.use(TablePlugin);
Vue.use(IconsPlugin);
Vue.use(SpinnerPlugin);
Vue.use(PaginationPlugin);
Vue.use(BadgePlugin);
Vue.use(CollapsePlugin);
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');