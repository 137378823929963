


































































import EditContactPersons from '@/views/customers/EditContactPerson.vue';
import ReplaceContactPerson from '@/views/customers/ReplaceContactPerson.vue';
import DeleteContactPerson from '@/views/customers/DeleteContactPerson.vue';

export default {
    name: 'ContactPersons',
    components: {
        'edit-contact-person': EditContactPersons,
        'delete-contact-person': DeleteContactPerson,
        'replace-contact-person': ReplaceContactPerson,
    },
    props: {
        contactPersons: {
            default: [],
        },
    },
    methods: {
        onDeleted(index) {
            this.contactPersons.splice(index, 1);
        }
    }
};
