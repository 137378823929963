
















































































































import { ref } from '@vue/composition-api';
import { useHttp } from '@/components/services/http.service';

function initialState() {
    return {
        isLoading: true,
        contracts: [],
        hurdleRate: null,
    }
}

export default {
    name: 'paystubAdditionalAgreements',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    data() {
        return initialState();
    },
    props:{
        employeeNumber: {
            required: true,
        },
        date: {
            type: Object,
            required: true
        },
        secondmentContractId: {
            type: Number,
            required: false
        }
    },
    mounted() {
        this.getContracts();
    },
    methods:{
        async getContracts(){
            this.isLoading = true;
            const uri = this.secondmentContractId
                ? '/billing/customer-invoices/customer-invoice/additional-agreements/' + this.secondmentContractId
                : '/billing/paystub/additional-agreements/' + this.employeeNumber + '/' + this.date.month + '-' + this.date.year;
            return this.http.get(uri).then((response) => {
                this.status = 200;
                this.contracts = response.data['contracts'];
                this.hurdleRate = response.data['hurdleRate'] || null;
                this.isLoading = false;
            }).catch((error: any) => {
                console.log(error);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },

        hourlyRateNormal(contract){
            return this.showSecondmentContract ? contract.secondmentContract.hourlyRateNormal : (this.hurdleRate + contract.additionalAgreement.bonusSalary);
        },
        hourlyRateOnDuty(contract){
            return this.hourlyRateNormal(contract) * contract.secondmentContract.hourlyRateOnDuty / 100;
        },
        hourlyRateOnCall(contract){
            return this.hourlyRateNormal(contract) * contract.secondmentContract.hourlyRateOnCall / 100;
        },
        hourlyRateNightService(contract){
            return this.hourlyRateNormal(contract) * contract.secondmentContract.nightService / 100;
        },
        hourlyRateHolidayService(contract){
            return this.hourlyRateNormal(contract) * contract.secondmentContract.holidayService / 100;
        },
        contractFieldOther(contract){
            return this.showSecondmentContract ? contract.secondmentContract.other : contract.additionalAgreement.other;
        }
    },
    computed: {
        showSecondmentContract(){
            return Number.isInteger(this.secondmentContractId);
        },
        showAdditionalAgreement(){
            return !this.showSecondmentContract;
        }
    }
};
