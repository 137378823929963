








































































import {required} from 'vuelidate/lib/validators';

declare const jQuery: any;

function initialState() {
    return {
        form: {
            message: "asdsadfasdf",
            note: null,
        },
        isLoading: false,
        modal: null,
    }
}

export default {
    name: 'CreateApplication',
    mounted() {
        this.PROFILE_PDF_DATE_THRESHOLD = 1000*60*60*24*365; // One Year
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
            Object.assign(this.$data, initialState());
            this.modal = jQuery('#' + this.modalId);
        });
        this.modal.on('shown.bs.modal', () => {
            this.$data.form.message = this.userData.applicationEmailMessage;
            this.$data.form.note = this.userData.applicationEmailNote;
        });
    },
    props: {
        modalId: {
            type: String,
            required: true
        },
        offerTitle: {
            type: String,
            required: true,
        },
        userData: {
            type: Object,
            required: true
        },
        contactPerson: {
            type: Object,
            required: true
        }
    },
    data() {
        return initialState();
    },
    validations() {
        return {
            form: {
                message: {required},
            },
        }
    },
    methods: {
      submit: function () {
        this.$v.$touch();
        if (this.$v.$error) return;

        this.$emit('created', this.form);
        this.modal.modal('hide');
      },
      save: function () {
        this.$v.$touch();
        if (this.$v.$error) return;
        this.$emit('saved', this.form);
        this.modal.modal('hide');
      },
        requiredError(form, prop) {
            if (this.$v[form][prop].$dirty && !this.$v[form][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        show(){
            this.modal.modal('show');
        },

    },
    computed: {
        profileTimestampWithinRange(){
            return ( (((new Date) as any) - (new Date(this.userData.profilePdfDate) as any)) > this.PROFILE_PDF_DATE_THRESHOLD )
        }
    }
}

