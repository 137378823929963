












import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'AppFooter',
    computed: {
        year() {
            return new Date().getFullYear();
        }
    }
});
