








































































































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';

declare const jQuery: any;

function initialState() {
    return {
        form1: {
            facility: null,
            regionShort: null,
            regionLong: null,
            addressAddition: null,
            street: null,
            streetNumber: null,
            zip: null,
            city: null,
            note: null,
            termsNote: null,
            housingNote: null,
        },
        form2: {
            addressAddition: null,
            street: null,
            streetNumber: null,
            zip: null,
            city: null,
        },
        form3: {
            addressAddition: null,
            street: null,
            streetNumber: null,
            zip: null,
            city: null,
        },
        isLoading: false,
        modal: null,
        curPage: 1,
        lastPage: 3,
    }
}

export default {
    name: 'AddCustomer',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
            Object.assign(this.$data, initialState());
            this.modal = jQuery('#' + this.modalId);
        });
    },
    props: {
        modalId: {
            type: String,
        }
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form1: {
                facility: { required },
                regionShort: { required },
                regionLong: { required },
                street: { required },
                streetNumber: { required },
                zip: { required },
            },
            form2: {
                street: { required },
                streetNumber: { required },
                zip: { required },
            },
            form3: {
                street: { required },
                streetNumber: { required },
                zip: { required },
            },
        }
    },
    methods: {
        submit: function() {

            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;
            const forms = {form1: this.$data.form1, form2: this.$data.form2, form3: this.$data.form3};
            this.http.post('/customer/add', forms).then(() => {
                notifier.displaySuccess('Hinzugefügt', 'Der Kunde wurde hinzugefügt.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('added');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        nextPage() {
            this.$v['form' + this.curPage].$touch();
            if(this.$v['form' + this.curPage].$error) return;
            this.curPage++;

            const TRANSFER_FIELDS = ['street', 'streetNumber', 'zip', 'city'];
            if(this.curPage === 2){
                TRANSFER_FIELDS.forEach(field => {
                    this.form2[field] = this.form2[field] || this.form1[field];
                });
            } else if(this.curPage === 3){
                TRANSFER_FIELDS.forEach(field => {
                    this.form3[field] = this.form3[field] || this.form2[field];
                });
            }
        },
        previousPage() {
            this.curPage--;
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        requiredError(form, prop) {
            if (this.$v[form][prop].$dirty && !this.$v[form][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
    }
}

