







































































































import { required } from 'vuelidate/lib/validators';

function initialState() {
    return {
        form : {
            date: null,
            startHours: null,
            startMinutes: '00',
            endHours: null,
            endMinutes: '00',
            typeOfDay: 1,
            typeOfWork: 1,
            typeOfDayAndWork: 1,
            note: null,
            break: null,
            timesheetNo: null,
        },
        holidayActive: false,
        datePickerMenu: null,
        formattedDate: null,
    }
}

export default {
    name: 'TimesheetEntryForm',
    data() {
        return initialState();
    },
    validations() {
        return {
            form: {
                date: {
                    required,
                    datePattern() {
                        const re = /^\d\d?\.\d\d?\.(\d\d\d\d|\d\d)$/;
                        return re.test(this.formattedDate);
                    },
                    isValidDate(v) {
                        return this.$moment(v, 'YYYY-MM-DD').isValid();
                    },
                    isInContractPeriod(v) {
                        const selectedDate = new Date(v);
                        const begin = new Date(this.contractBegin);
                        if (null === this.contractEnd) {
                            return selectedDate >= begin;
                        }
                        const end = new Date(this.contractEnd);
                        return selectedDate >= begin && selectedDate <= end;
                    }
                },
                startHours: {
                    required(v) {
                        return this.form.typeOfDayAndWork >= 4 || required(v);
                    },
                    isHour(v) {
                        return v >= 0 && v <= 23;
                    }
                },
                startMinutes: {
                    required(v) {
                        return this.form.typeOfDayAndWork >= 4 || required(v);
                    },
                    isMinute(v) {
                        return v >= 0 && v <= 59;
                    }
                },
                endHours: {
                    required(v) {
                        return this.form.typeOfDayAndWork >= 4 || required(v);
                    },
                    isHour(v) {
                        return v >= 0 && v <= 23;
                    },
                },
                endMinutes: {
                    required(v) {
                        return this.form.typeOfDayAndWork >= 4 || required(v);
                    },
                    isMinute(v) {
                        return v >= 0 && v <= 59;
                    }
                },
                typeOfDay: {required},
                typeOfWork: {required},
            },
        }
    },
    props: {
        professionCategoryId: null,
        contractBegin: null,
        contractEnd: null,
        holidays: {
            type: Array,
            default: () => [],
        }
    },
    watch: {
        'form.date'(v) {
            this.formattedDate = this.formatDate(v);
            this.holidayActive = this.holidays.includes(v);
        },
        'form.typeOfDayAndWork'(v) {
            switch (v) {
                case 1:
                    this.form.typeOfDay = 1;
                    this.form.typeOfWork = 1;
                    break;
                case 2:
                    this.form.typeOfDay = 1;
                    this.form.typeOfWork = 2;
                    break;
                case 3:
                    this.form.typeOfDay = 1;
                    this.form.typeOfWork = 3;
                    break;
                case 4:
                    this.form.typeOfDay = 2;
                    this.form.typeOfWork = 1;
                    break;
                case 5:
                    this.form.typeOfDay = 3;
                    this.form.typeOfWork = 1;
                    break;
            }

            if (v === 4 || v === 5) {
                const data = initialState();
                this.form.startHours = data.form.startHours;
                this.form.startMinutes = data.form.startMinutes;
                this.form.endHours = data.form.endHours;
                this.form.endMinutes = data.form.endMinutes;
            }
            if (v !== 1) {
                this.form.break = null;
            }
        },
    },
    computed: {
        typeOfDayAndWorkOptions() {
            const timesheetNo = this.form.timesheetNo ? this.form.timesheetNo.split('-') : [];

            if (timesheetNo.length === 1) {
                return [
                    {name: 'Urlaubstag', value: 4},
                    {name: 'Krankheit', value: 5},
                ];
            } else if (this.professionCategoryId === 1) {
                return [
                    {name: 'Ja', value: 1},
                    {name: 'Ja (Bereitschaft)', value: 2},
                    {name: 'Ja (Rufdienst)', value: 3},
                    {name: 'Urlaubstag', value: 4},
                    {name: 'Krankheit', value: 5},
                ];
            } else {
                return [
                    {name: 'Ja', value: 1},
                    {name: 'Urlaubstag', value: 4},
                    {name: 'Krankheit', value: 5},
                ];
            }
        }
    },
    methods: {
        setValues(values) {
            const form = Object.keys(this.form).reduce((a, key) => ({ ...a, [key]: values[key]}), {});
            Object.assign(this.form, form);

            if (form['typeOfDay'] === 1) {
                this.form.typeOfDayAndWork = 1;
                if (form['typeOfWork'] === 2) {
                    this.form.typeOfDayAndWork = 2;
                } else if (form['typeOfWork'] === 3) {
                    this.form.typeOfDayAndWork = 3;
                }
                this.focusDateControl();
            } else if (form['typeOfDay'] === 2) {
                this.form.typeOfDayAndWork = 4;
                this.focusTypeOfDayAndWorkControl();
            } else if (form['typeOfDay'] === 3) {
                this.form.typeOfDayAndWork = 5;
                this.focusTypeOfDayAndWorkControl();
            }
        },
        validate() {
            this.$v.$touch();
            return !this.$v.$error;
        },
        clear() {
            const data = initialState();
            Object.assign(this.form, data.form);
        },
        requiredError(form, prop) {
            if (this.$v[form][prop].$dirty && !this.$v[form][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        focusDateControl() {
            this.$refs.startHoursControl.$el.querySelector('input').focus();
        },
        focusTypeOfDayAndWorkControl() {
            this.$refs.typeOfDayAndWorkControl.$el.querySelector('input').focus();
        },
        onDateControlKeypress(event) {
            if (event.key === 'ArrowUp') {
                this.incrementDate();
            } else if (event.key === 'ArrowDown') {
                this.decrementDate();
            }
        },
        onFormattedDateChanged(v) {
            const parsedDate = this.$moment(v, 'DD.MM.YYYY');
            this.form.date = parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : '2020-01-01';
        },
        incrementDate() {
            const tomorrow = new Date(this.form.date);
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.form.date = this.$moment(tomorrow).format('YYYY-MM-DD');
            this.formattedDate = this.formatDate(this.form.date);
        },
        decrementDate() {
            const yesterday = new Date(this.form.date);
            yesterday.setDate(yesterday.getDate() - 1);
            this.form.date = this.$moment(yesterday).format('YYYY-MM-DD');
            this.formattedDate = this.formatDate(this.form.date);
        },
        hoursError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].isHour && errors.push('Bitte eine Zahl zwischen 0 und 23 eingeben.');
            return errors;
        },
        minutesError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].isMinute && errors.push('Bitte eine Zahl zwischen 0 und 59 eingeben.');
            return errors;
        },
        dateError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].datePattern && errors.push('Bitte ein Datum eingeben.');
            !this.$v[form][prop].isValidDate && errors.push('Bitte ein gültiges Datum eingeben.');
            !this.$v[form][prop].isInContractPeriod && errors.push('Datum muss innerhalb der Vertragszeit liegen.');
            return errors;
        },
    }
}
