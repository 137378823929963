























import { defineComponent } from '@vue/composition-api';

import AppFooter from '../footer/Footer.vue';
import AppNavigation from '../navigation/Navigation.vue';
import AppTopnavbar from '../topnavbar/Topnavbar.vue';
import { detectBody } from '@/helpers';

export default defineComponent({
    name: 'BasicLayout',
    components: {
        AppFooter,
        AppNavigation,
        AppTopnavbar,
    },
    mounted: function() {
        detectBody();
    },
    created() {
        window.addEventListener('resize', this.onResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            detectBody();
        }
    }
});
