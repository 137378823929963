

















































declare const jQuery: any;

function initialState() {
    return {
        form: {
            message: null,
        },
        isLoading: false,
        modal: null,
    }
}

export default {
    name: 'CancelApplication',
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            Object.assign(this.$data, initialState());
            this.modal = jQuery('#' + this.modalId);
        });
    },
    props: {
        modalId: {
            type: String,
            required: true
        },
        offerTitle: {
            type: String,
            required: true,
        },
        contactPerson: {
            type: Object,
            required: true
        }
    },
    data() {
        return initialState();
    },
    methods: {
        submit: function () {
            this.$emit('submit', this.form);
            this.modal.modal('hide');
        },
        show(){
            this.modal.modal('show');
        },

    },
}

