




















































import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import {required} from "vuelidate/lib/validators";

declare const jQuery: any;

function initialState() {
    return {
        form: {
          id: null,
        },
        isLoading: false,
    }
}

export default {
    name: 'ReplaceContactPerson',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
        this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
          const data = initialState();
          this.$data.form = data.form;
        });
    },
    props: {
        contactPersonId: null,
        contactPersons: null,
        modalId: null,
    },
    data() {
        return initialState();
    },
    validations() {
        return {
            form: {
                id: {required}
            }
        }
    },
    computed: {
        isDisabled: function(){
            return this.contactPersonsAsOptions.length === 0;
        },
        contactPersonsAsOptions: function () {
            return this.contactPersons.filter(function (obj) {
                return obj.id !== this;
            }, this.contactPersonId);
        },
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if (this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;
            this.http.post('/customer/contact-person/replace/' + this.contactPersonId, this.$data.form).then(() => {
                notifier.displaySuccess('Entfernt', 'Die Kontaktperson wurde entfernt.');
                this.isLoading = false;
                this.modal.modal('hide');
                this.$emit('deleted');
                this.$emit('updateContactPersons');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        }
    }
}
