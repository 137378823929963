






import { defineComponent } from '@vue/composition-api';

declare const jQuery: any;

export default defineComponent({
    name: 'BlankLayout',
    mounted: function() {
        jQuery('body').addClass('gray-bg');
    },
    destroyed() {
        jQuery('body').removeClass('gray-bg');
    },
});
