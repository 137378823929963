























































import { useProfessionProvider } from '@/components/services/profession-provider.service';

function initialState() {
    return {
        form : {
            professionCategoryId: null,
            professionLevel: null,
            specializationIds: [],
            professionIds: [],
            auxiliaryDesignation: null,
            note: null,
        },
        professionsAsOptions: [],
        professionCategoriesAsOptions: [],
        specializationsAsOptions: [],
        professionLevelsAsOptions: [],
    }
}

export default {
    name: 'QualificationForm',
    setup() {
        const professionProvider = useProfessionProvider();
        return { professionProvider };
    },
    mounted() {
        this.getProfessionCategoriesAsOptions();
        this.getProfessionsByCategoryAsOptions();
        this.getSpecializationsByCategoryAsOptions();
        this.getProfessionLevelsAsOptions();
    },
    data() {
        return initialState();
    },
    methods: {
        clear() {
            const data = initialState();
            Object.assign(this.form, data.form);
        },
        async getProfessionCategoriesAsOptions() {
            this.professionCategoriesAsOptions = await this.professionProvider.getProfessionCategoriesAsOptions();
        },
        async getProfessionsByCategoryAsOptions() {
            this.professionsAsOptions = {};
            const professionCategories = await this.professionProvider.getProfessionCategoryMap();
            for (const cat in professionCategories) {
                this.professionsAsOptions[cat] = await this.professionProvider.getProfessionsByCategoryAsOptions(cat);
            }
        },
        async getSpecializationsByCategoryAsOptions() {
            this.specializationsAsOptions = {};
            const professionCategories = await this.professionProvider.getProfessionCategoryMap();
            for (const cat in professionCategories) {
                this.specializationsAsOptions[cat] = await this.professionProvider.getSpecializationsByCategoryAsOptions(cat);
            }
        },
        async getProfessionLevelsAsOptions() {
            this.professionLevelsAsOptions = await this.professionProvider.getProfessionLevelsAsOptions();
        },
    }
}
