import {provide, inject} from '@vue/composition-api';

import {interval} from 'rxjs';
import {filter, startWith, mergeMap} from 'rxjs/operators';

import {useAuth} from "@/components/services/auth.service";
import {useHttp} from "@/components/services/http.service";

import {Subject} from 'rxjs';

const createOutstandingCustomerInvoicesService = () => {

    const POLLING_FREQUENCY = 100000;

    const auth: any = useAuth();
    const http: any = useHttp();

    const outstandingCustomerInvoices$ = new Subject();

    interval(POLLING_FREQUENCY).pipe(
        startWith(0),
        filter(() => auth.getUser() !== null),
        mergeMap(() => {
            return http.get('/outstanding-customer-invoices');
        }))
        .subscribe((response: any) => {
            outstandingCustomerInvoices$.next(response.data);
        });

    return {
        getOutstandingCustomerInvoices() {
            return outstandingCustomerInvoices$;
        },
        pollOutstandingCustomerInvoices() {
            http.get('/outstanding-customer-invoices').then((response) => {
                outstandingCustomerInvoices$.next(response.data);
            });
        }
    }
};

const outstandingCustomerInvoicesSymbol = Symbol();

export function provideOutstandingCustomerInvoicesService() {
    const openOutstandingCustomerInvoices = createOutstandingCustomerInvoicesService();
    provide(outstandingCustomerInvoicesSymbol, openOutstandingCustomerInvoices);
}

export function useOutstandingCustomerInvoicesService() {
    const openOutstandingCustomerInvoices = inject(outstandingCustomerInvoicesSymbol);
    if (!openOutstandingCustomerInvoices) throw new Error("No outstanding Customer Invoices service provided!!!");

    return openOutstandingCustomerInvoices;
}