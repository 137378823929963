





























































































































































































































































































































































import { ref } from '@vue/composition-api';

import Vue2Filters from 'vue2-filters';
import { required, integer, minValue } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import { useProfessionProvider } from '@/components/services/profession-provider.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';

declare const Decimal: any;

function initialState() {
    return {
        isLoadingCustomersAsOptions: false,
        isLoadingContactPersonsAsOptions: false,
        isLoadingCustomerLocation: false,
        menuBegin: false,
        menuEnd: false,
        formattedBegin: null,
        formattedEnd: null,
        professionCategoryMap: [],
        customersAsOptions: [],
        contactPersonsAsOptions: [],
        professionsAsOptions: {},
        professionCategoryName: null,
        professionLevelName: null,
        aulTasks: [],
        aulApplications: [],
        form1: {
            facility: null,
            contactPersonId: null,
        },
        form2: {
            weeklyWorkingHours: null,
            begin: null,
            end: null,
            task: null, // Auszuübende Tätigkeit
            profession: null, // Erforderliche Qualifikation Fachrichtung
            professionCategory: null,
            professionLevel: null, // Fachrichtung Level (nur für Ärzte: Arzt | Facharzt)
            location: '', // Einsatzort
            application: null, // Einsatzbereich
            hourlyRateNormal: null, // Stundenverrechnungssatz Regeldienst (in Cent)
            hourlyRateOnDuty: null, // Stundenverrechnungssatz Bereitschaftsdienst in Prozent
            hourlyRateOnCall: null, // Stundenverrechnungssatz Rufdienst in Prozent
            nightService: 125, // Zugschlagsatz 1 für Nacht- und Rufdienst in Prozent
            holidayService: 150, // Zuschlagsatz 2 für Feiertagsdienst in Prozent
            housing: null, // Punkt "Unterkunft" anzeigen
            expensesCost: null, // Spesenvergütung in Cent
            other: null, // Sonstiges
        },
        form3: {
            hurdleRate: null,
            hourlyRateNormal: null,
            bonusSalary: null, // Zulage Aktivstunde in Cent pro Stunde
            reimbursementCost: null, // Fahrtkostenerstattung Heimfahrten je Einsatzwoche in Cent pro km
            additionalMealAllowanceCost: null, // Verpflegungsmehraufwand Erstattung in Cent je Einsatztag
            drivingDistance: null, // Fahrstrecke in km
            other: null, // optionaler Punkt "Sonstiges"
        },
    }
}

export default {
    name: 'AulForm',
    setup() {
        const http = ref();
        http.value = useHttp();
        const professionProvider = useProfessionProvider();
        const inputSuggestions = useInputSuggestions();
        return { http, professionProvider, inputSuggestions };
    },
    mixins: [Vue2Filters.mixin],
    mounted() {
        this.getAulTasks();
        this.getAulApplications();
        this.getProfessionsByCategoryAsOptions();
    },
    data() {
        return initialState();
    },
    props: {
        asv: null,
        professionCategory: null,
        professionLevel: null,
        employmentEnd: null,
    },
    watch: {
        professionCategory: {
            immediate: true,
            handler(v) {
                this.form2.professionCategory = v;
                this.professionProvider.getProfessionCategoryMap().then((map) => {
                    this.professionCategoryName = v ? map[v].name : '';
                });
            }
        },
        professionLevel: {
            immediate: true,
            handler(v) {
                this.form2.professionLevel = v;
                this.professionProvider.getProfessionLevelById(v).then((level) => {
                    this.professionLevelName = v ? level.name : '';
                });
            }
        },
        'form1.contactPersonId'(v) {
            this.$emit('contactPersonChanged', v);
        },
        'asv.hurdleRate': {
            immediate: true,
            handler(v) {
                setTimeout(() => this.form3.hurdleRate = v);
            }
        },
    },
    computed: {
        professionFullname() {
            let name = this.professionCategoryName;
            if (this.professionLevelName) {
                name+= ' (' + this.professionLevelName + ')';
            }
            return name;
        }
    },
    validations() {
        return {
            form2: {
                weeklyWorkingHours: { required, integer },
                begin: {
                    required,
                    notSmallerThanAsvBegin(v) {
                        return new Date(v) >= new Date(this.asv.begin);
                    }
                },
                end: {
                    required,
                    greaterThanBegin(v) {
                        return new Date(v) > new Date(this.form2.begin);
                    },
                    notGreaterThanAsvEnd(v) {
                        return this.employmentEnd === null || new Date(v) <= this.employmentEnd;
                    }
                },
                task: { required },
                professionCategory: { required },
                application: { required },
                hourlyRateNormal: { required },
                profession: { required },
                hourlyRateOnDuty: {
                    required(v) {
                        return this.form2.professionCategory !== 1 || required(v);
                    },
                    integer,
                },
                hourlyRateOnCall: {
                    required(v) {
                        return this.form2.professionCategory !== 1 || required(v);
                    },
                    integer,
                },
                nightService: {
                    required(v) {
                        return this.form2.professionCategory === 1 || required(v);
                    },
                    integer,
                    minValue: minValue(100),
                },
                holidayService: {
                    required(v) {
                        return this.form2.professionCategory === 1 || required(v);
                    },
                    integer,
                    minValue: minValue(100),
                },
            },
            form3: {
                hourlyRateNormal: {
                    required,
                    lowerThanClinicRate(v) {
                        return !this.form2.hourlyRateNormal || this.form2.hourlyRateNormal > v;
                    },
                    higherThanHurdlerRate(v) {
                        return v > this.form3.hurdleRate;
                    },
                },
                bonusSalary: { required },
                reimbursementCost: {
                    required(v) {
                        return !this.form3.drivingDistance || required(v);
                    },
                    integer,
                },
                drivingDistance: {
                    required(v) {
                        return !this.form3.reimbursementCost || required(v);
                    },
                    integer,
                },
            }
        }
    },
    methods: {
        validate() {
            this.$v.$touch();
            return !this.$v.$error;
        },
        clear() {
            const professionCategory = this.form2.professionCategory;
            const professionLevel = this.form2.professionLevel;
            const hurdleRate = this.form3.hurdleRate;
            const data = initialState();
            Object.assign(this.form1, data.form1);
            Object.assign(this.form2, data.form2);
            Object.assign(this.form3, data.form3);
            this.form2.professionCategory = professionCategory;
            this.form2.professionLevel = professionLevel;
            this.form3.hurdleRate = hurdleRate;
            this.formattedBegin = null;
            this.formattedEnd = null;
            this.$v.$reset();
        },
        setCustomer(name, id, contactPersonName, contactPersonId) {
            this.customersAsOptions = [{
                name: name,
                value: id,
            }];
            this.contactPersonsAsOptions = [{
                name: contactPersonName,
                value: contactPersonId,
            }];
            this.form1.facility = id;
            this.form1.contactPersonId = contactPersonId;
            this.getCustomersAsOptions();
            this.getContactPersonsAsOptions();
        },
        setPreset(preset){
            console.log("PRESET", preset)
            this.getCustomersAsOptions().then(()=> {
                    this.form1.facility = preset.customerId;
                    return this.getContactPersonsAsOptions()
            }).then(()=>{
                this.form1.contactPersonId = preset.contactPersonId;
            });
        },
        setValues(zv, aul) {
            this.setCustomer(aul.contactPerson.customer.facility, aul.contactPerson.customer.id, aul.contactPerson.fullName, aul.contactPerson.id);

            const data = initialState();
            this.form2 = Object.keys(data.form2).reduce((a, key) => ({ ...a, [key]: aul[key]}), {});
            this.form3 = Object.keys(data.form3).reduce((a, key) => ({ ...a, [key]: zv[key]}), {});

            this.professionProvider.getProfessionCategoryMap().then((map) => {
                this.professionCategoryName = map[this.form2.professionCategory].name;
            });
            this.professionLevelName = aul.professionLevelName;
            this.form3.hurdleRate = this.asv.hurdleRate;
            this.form3.hourlyRateNormal = new Decimal(this.form3.bonusSalary).plus(this.asv.hurdleRate).toFixed(2);

            this.formattedBegin = this.formatDate(this.form2.begin);
            this.formattedEnd = this.formatDate(this.form2.end);
        },
        onFacilityChanged() {
            this.form1.contactPersonId = null;
            this.getContactPersonsAsOptions();
            this.getCustomerLocation();
        },
        getCustomerLocation() {
            /*
            this.isLoadingCustomerLocation = true;
            const notifier = getNotifier();
            this.http.get('/customer/location/' + this.form1.facility).then((response) => {
                this.form2.location = response.data['data'];
                this.isLoadingCustomerLocation = false;
            }).catch((error: any) => {
                this.isLoadingCustomerLocation = false;
                notifier.displayServerError(error.message);
            });
             */
        },
        async getContactPersonsAsOptions() {
            this.isLoadingContactPersonsAsOptions = true;
            const notifier = getNotifier();
            return this.http.get('/customer/contact-persons-as-options/' + this.form1.facility).then((response) => {
                this.contactPersonsAsOptions = response.data['data'];
                this.isLoadingContactPersonsAsOptions = false;
            }).catch((error: any) => {
                this.isLoadingContactPersonsAsOptions = false;
                notifier.displayServerError(error.message);
            });
        },
        async getCustomersAsOptions() {
            this.isLoadingCustomersAsOptions = true;
            const notifier = getNotifier();
            return this.http.get('/user/customers-as-options/' + this.$route.params.userId).then((response) => {
                this.customersAsOptions = response.data['data'];
                this.isLoadingCustomersAsOptions = false;
            }).catch((error: any) => {
                this.isLoadingCustomersAsOptions = false;
                notifier.displayServerError(error.message);
            });
        },
        ratio(total, percentage) {
            if (!total || !percentage || isNaN(total) || isNaN(percentage)) {
                return;
            }
            return new Decimal(total).mul(percentage).dividedBy(100).toFixed(2);
        },
        onHourlyRateChanged(hourlyRate) {
            if (hourlyRate) {
                const bonusSalary = new Decimal(hourlyRate).minus(this.asv.hurdleRate).toFixed(2);
                this.$data.form3.bonusSalary = bonusSalary > 0 ? bonusSalary : 0;
            }
        },
        drivingDistanceError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte Fahrstrecke und Fahrtenanzahl ausfüllen oder beide leer lassen.');
            !this.$v[form][prop].integer && errors.push('Bitte eine ganze Zahl eingeben.');
            return errors;
        },/*
        reimbursementError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte Fahrstrecke und Fahrtenanzahl ausfüllen oder beide leer lassen.');
            return errors;
        },*/
        hourlyRateZvError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].lowerThanClinicRate && errors.push('Der Stundensatz in der Zusatzvereinbarung muss niedriger als in der Arbeitnehmerüberlassung sein.');
            !this.$v[form][prop].higherThanHurdlerRate && errors.push('Der Stundensatz muss über der Grundvergütung pro Stunde liegen.');
            return errors;
        },
        beginError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].notSmallerThanAsvBegin && errors.push('Datum darf nicht vor dem Beginn des Anstellungs- verhältnisses liegen.');
            return errors;
        },
        endError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].greaterThanBegin && errors.push('Enddatum muss nach dem Anfangsdatum liegen.');
            !this.$v[form][prop].notGreaterThanAsvEnd && errors.push('Enddatum muss innerhalb des befristeten Anstellungs- verhältnisses liegen.');
            return errors;
        },
        integerAndRequiredError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen.');
            !this.$v[form][prop].integer && errors.push('Bitte eine ganze Zahl eingeben.');
            return errors;
        },
        wageSurchargeError(form, prop) {
            const errors = this.integerAndRequiredError(form, prop);
            !this.$v[form][prop].minValue && errors.push('Mindestens 100%');
            return errors;
        },
        requiredError(form, prop) {
            if (this.$v[form][prop].$dirty && !this.$v[form][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        async getAulTasks() {
            this.aulTasks = await this.inputSuggestions.getAulTasks();
        },
        async getAulApplications() {
            this.aulApplications = await this.inputSuggestions.getAulApplications();
        },
        async getProfessionsByCategoryAsOptions() {
            this.professionsAsOptions = {};
            const professionCategories = await this.professionProvider.getProfessionCategoryMap();
            for (const cat in professionCategories) {
                this.professionsAsOptions[cat] = await this.professionProvider.getProfessionsByCategoryAsOptions(cat);
            }
        },
    }
}

