




































import { ref } from '@vue/composition-api';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { useExpirationCalculator } from '@/components/services/expiration-calculator.service';
import AulForm from '@/views/users/AulForm.vue';

declare const jQuery: any;

export default {
    name: 'AddAUL',
    components: {
        'aul-form': AulForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        const inputSuggestions = useInputSuggestions();
        const expirationCalculator = useExpirationCalculator();
        return { http, inputSuggestions, expirationCalculator };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$refs.aulForm.clear();
        });
        this.modal.on('shown.bs.modal', () => {
            this.employmentEnd = this.expirationCalculator.getAsvEnd(this.asv);
            this.$refs.aulForm.getCustomersAsOptions();
        });

        if(this.preset){
            this.modal.modal("show");
            this.$refs['aulForm'].setPreset(this.preset)
        }
    },
    props: {
        modalId: null,
        asv: null,
        professionCategory: null,
        professionLevel: null,
        preset: null,
    },
    data() {
        return {
            submitDisabled: true,
            isLoading: false,
            employmentEnd: null,
        }
    },

    methods: {
        onContactPersonChanged(contactPerson) {
            this.submitDisabled = null === contactPerson;
        },
        submit: function() {
            if (!this.$refs.aulForm.validate()) {
                return;
            }

            const notifier = getNotifier();
            this.isLoading = true;

            const forms = {
                form1: this.$refs.aulForm.form1,
                form2: this.$refs.aulForm.form2,
                form3: this.$refs.aulForm.form3,
            };
            this.http.post('/user/contract/add/aul/' + this.asv.id, forms).then((response) => {
                notifier.displaySuccess('Erstellt', 'AÜL und ZV wurden erstellt.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.inputSuggestions.load();
                this.$emit('added', response.data['zv']);
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
};
