import { provide, inject } from '@vue/composition-api';
import router from '@/router';

import axios from 'axios';

import {logout} from '@/components/services/auth.service';
import { getNotifier } from '@/helpers';

const createHttp = () => {
    const http = axios.create({
        baseURL: process.env.VUE_APP_SERVER_HOST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    });

    http.defaults.withCredentials = true;
    http.defaults.timeout = 100000;

    http.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },
        error => {
            if (error.response && error.response.status) {
                const curRoute =  router.currentRoute.path;
                switch (error.response.status) {
                    case 401:
                        if ('/login' !== curRoute) {
                            getNotifier().displayServerError('Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich neu ein.');
                            logout();
                        }
                        break;
                    case 400:
                    case 403:
                        logout();
                        break;
                    case 404:
                        break;
                    case 502:
                        setTimeout(() => {
                            logout();
                        }, 1000);
                }
            }
            return Promise.reject(error);
        }
    );

    return http;
};

const httpSymbol = Symbol();

export function provideHttp() {
    const http = createHttp();
    provide(httpSymbol, http);
}

export function useHttp() {
    const http = inject(httpSymbol);
    if (!http) throw new Error('No http client provided!!!');

    return http;
}