



















































































































































































import {ref, defineComponent} from '@vue/composition-api';


import {useHttp} from '@/components/services/http.service';
import {useProfessionProvider} from '@/components/services/profession-provider.service';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import DatatableCheckbox from '@/components/common/forms/DatatableCheckbox.vue';
import {VExpansionPanel, VExpansionPanelContent, VExpansionPanelHeader, VExpansionPanels} from "vuetify/lib";


export default defineComponent({
    name: 'offerUserTable',
    setup() {
        const professionProvider = ref();
        const http = ref();
        professionProvider.value = useProfessionProvider();
        http.value = useHttp();
        return {professionProvider, http};
    },
    components: {
        'transition-expand': TransitionExpand,
        'datatable-checkbox': DatatableCheckbox,
        'v-expansion-panels': VExpansionPanels,
        'v-expansion-panel': VExpansionPanel,
        'v-expansion-panel-header': VExpansionPanelHeader,
        'v-expansion-panel-content': VExpansionPanelContent
    },
    props: {
        title: {
            type: String,
        },
        offer: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        rowsPerPage: {
            type: Number,
            required: true
        },
        numTotalRows: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
        sortBy: {
            type: String,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        fields: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            allSelected: false,
            tooltipNote: "",
            tooltipTarget: null,
            professionCategoryMap: {},
            professionLevelsMap: {},
            professionsMap: {},
            specializationsMap: {},
            swalSendOfferData: [
                "Angebot erneut senden?",
                "Das Angebot wurde diesem Benutzer bereits versendet. Wollen Sie es erneut versenden?",
                "Versenden"
            ],
            swalSendApplicationData: [
                "Profil veraltet",
                "Das Profil dieses Benutzers ist bereits über ein Jahr alt. Wollen Sie die Bewerbung trotzdem Versenden?",
                "Versenden"
            ],
        }
    },
    mounted(){
        this.getProfessionCategoryMap();
        this.getProfessionLevelsMap();
        this.getProfessionsMap();
        this.getSpecializationsMap();
    },
    methods: {
        sendOfferClicked(userId){
            this.$emit('sendOfferClicked', userId);
        },
        createApplicationClicked(userData){
            this.$emit('createApplicationClicked', userData);
        },
        cancelApplicationClicked(userData){
            this.$emit('cancelApplicationClicked', userData);
        },
        sortingChanged(ctx) {
            this.$emit('sortingChanged', ctx);
            this.$emit('pageChanged', this.currentPage);
            this.allSelected = false;
        },
        selectOrClearAll() {
            if (this.allSelected) {
                this.$refs.userTable.clearSelected();
            } else {
                this.$refs.userTable.selectAllRows();
            }
        },
        selectOrUnselect(row) {
            if (!row.rowSelected) {
                row.selectRow();
            } else {
                row.unselectRow();
                this.allSelected = false;
            }
        },

        getSelectedRows() {
            const selectedRows = [];
            this.items.forEach((item, index) => {
                if (this.$refs.userTable.isRowSelected(index)) {
                    selectedRows.push(index);
                }
            });
            return selectedRows;
        },
        getSelectedIds() {
            const selectedRows = this.getSelectedRows();
            const ids = [];
            for (const selectedRow of selectedRows) {
                ids.push(this.items[selectedRow]['id']);
            }
            return ids;
        },

        rowHovered(item, index, event){
            this.tooltipTarget = event.originalTarget;
            this.tooltipNote = (item.blacklisted ? 'Blacklisted: ' + item.blacklisted : false)
                || (item.sent ? 'Angebot wurde bereits versendet' : false) || '';
        },
        rowUnhovered(){
            this.tooltipNote = '';
        },

        getProfessionsList(list) {
            const arr = [];
            for (const key of list) {
                this.professionsMap[key] ? arr.push(this.professionsMap[key]['name']) : arr.push(key);
            }
            return arr.join(', ');
        },
        getSpecializationsList(list) {
            const arr = [];
            for (const key of list) {
                this.specializationsMap[key] ? arr.push(this.specializationsMap[key]['name']) : arr.push(key);
            }
            return arr.join(', ');
        },
        async getProfessionCategoryMap() {
            this.professionCategoryMap = await this.professionProvider.getProfessionCategoryMap();
        },
        async getProfessionLevelsMap() {
            this.professionLevelsMap = await this.professionProvider.getProfessionLevelsMap();
        },
        async getProfessionsMap() {
            this.professionsMap = await this.professionProvider.getProfessionsMap();
        },
        async getSpecializationsMap() {
            this.specializationsMap = await this.professionProvider.getSpecializationsMap();
        },
    },
    computed: {
        parentSortBy: {
            get: function () {
                return this.sortBy;
            },
            set: function () {
                0;
            }
        },
        parentSortDesc: {
            get: function () {
                return this.sortDesc
            },
            set: function () {
                0;
            }
        },
        parentCurrentPage: {
            get: function () {
                return this.currentPage
            },
            set: function (page) {
                this.allSelected = false;
                this.$emit('pageChanged', page);
            }
        },
        today() {
            return new Date().setHours(0, 0, 0, 0);
        },
        inApplicantSection(){
            return this.title.toLowerCase() === 'bewerber';
        }
    },
});
