
























import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { useHttp } from '@/components/services/http.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { getNotifier } from '@/helpers';
import PersonalDataForm from '@/views/users/PersonalDataForm.vue';

declare const jQuery: any;

export default {
    name: 'EditPersonalData',
    components: {
        'personal-data-form': PersonalDataForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        const inputSuggestions = useInputSuggestions();
        return { http, inputSuggestions };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$refs.personalDataForm.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            this.$refs.personalDataForm.setValues(this.user);
        });
    },
    props: {
        modalId: null,
        user: null,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    validations () {
        return {
            form: {
                title: { required },
                firstName: { required },
                lastName: { required },
            }
        }
    },
    methods: {
        submit: function() {
            if (!this.$refs.personalDataForm.validate()) {
                return;
            }

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/user/edit/' + this.user.id, this.$refs.personalDataForm.$data.form).then(() => {
                Object.assign(this.user, this.$refs.personalDataForm.$data.form);
                notifier.displaySuccess('Aktualisiert', 'Persönliche Daten wurde aktualisiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('edited');
                this.inputSuggestions.load();
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}
