





































import { ref } from '@vue/composition-api';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import { useProfessionProvider } from '@/components/services/profession-provider.service';
import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { useExpirationCalculator } from '@/components/services/expiration-calculator.service';
import AdminPrivilegeNotification from '@/views/users/AdminPrivilegeNotification.vue';
import AulForm from '@/views/users/AulForm.vue';

declare const jQuery: any;

export default {
    name: 'EditAUL',
    components: {
        'admin-privilege-notification': AdminPrivilegeNotification,
        'aul-form': AulForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        const professionProvider = useProfessionProvider();
        const inputSuggestions = useInputSuggestions();
        const expirationCalculator = useExpirationCalculator();
        return { http, professionProvider, inputSuggestions, expirationCalculator };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$refs.aulForm.clear();
        });
        this.modal.on('shown.bs.modal', () => {
            this.employmentEnd = this.expirationCalculator.getAsvEnd(this.asv);
            this.$refs.aulForm.setValues(this.zv, this.zv.aul);
        });
    },
    props: {
        modalId: null,
        asv: null,
        zv: null,
    },
    data() {
        return {
            isLoading: false,
            submitDisabled: true,
            employmentEnd: null,
        }
    },
    methods: {
        onContactPersonChanged(contactPerson) {
            this.submitDisabled = null === contactPerson;
        },
        submit: function() {
            if (!this.$refs.aulForm.validate()) {
                return;
            }

            const notifier = getNotifier();
            this.isLoading = true;

            const forms = {
                form1: this.$refs.aulForm.form1,
                form2: this.$refs.aulForm.form2,
                form3: this.$refs.aulForm.form3,
            };
            this.http.post('/user/contract/edit/zv-and-aul/' + this.zv.id, forms).then(() => {
                Object.assign(this.zv['aul'], forms.form2);
                Object.assign(this.zv, forms.form3);
                this.zv['begin'] = this.zv['aul']['begin'];
                this.zv['end'] = this.zv['aul']['end'];
                this.professionProvider.getProfessionById(forms.form2.profession).then((profession) => {
                    this.zv['aul']['professionName'] = profession ? profession['name'] : null;
                });

                notifier.displaySuccess('Editiert', 'AÜL und ZV wurden editiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.inputSuggestions.load();
                this.$emit('edited');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
};
