
























import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import QualificationForm from '@/views/users/QualificationForm.vue';

declare const jQuery: any;

export default {
    name: 'EditQualification',
    components: {
        'qualification-form': QualificationForm,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('shown.bs.modal', () => {
            const form = Object.keys(this.$refs.qualificationForm.$data.form).reduce((a, key) => ({ ...a, [key]: this.user[key]}), {});
            Object.assign(this.$refs.qualificationForm.$data.form, form);
        });
    },
    props: {
        modalId: null,
        user: null,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        submit: function() {
            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/user/edit/' + this.user.id, this.$refs.qualificationForm.$data.form).then(() => {
                Object.assign(this.user, this.$refs.qualificationForm.$data.form);
                notifier.displaySuccess('Aktualisiert', 'Qualifikation wurde aktualisiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('edited');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}
