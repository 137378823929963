import { provide, inject } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';

const createProfessionProvider = () => {
    const httpClient: any = useHttp();

    let data$;
    let professions$;
    let professionLevels$;

    let professionCategoriesMap$ = Promise.resolve({});
    let professionLevelsMap$ = Promise.resolve({});
    let professionsMap$ = Promise.resolve({});
    let specializationsMap$ = Promise.resolve({});

    let professionsByCategory$ = Promise.resolve({});
    let specializationsByCategory$ = Promise.resolve({});

    let professionCategoriesAsOptions$ = Promise.resolve({});
    let professionLevelsAsOptions$ = Promise.resolve([]);
    let professionsAsOptions$ = Promise.resolve({});
    let specializationsAsOptions$ = Promise.resolve({});

    let professionsByCategoryAsOptions$ = Promise.resolve({});
    let specializationsByCategoryAsOptions$ = Promise.resolve({});

    return {
        load: function() {
            data$ = httpClient.get('/professions').then((response: any) => {
                return response.data['data'];
            });

            professions$ = data$.then((data: any) => {
                return data['professions'];
            });

            professionLevels$ = data$.then((data: any) => {
                return data['profession_levels'];
            });

            professionsMap$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    for (const profession of professionCategory['professions']) {
                        map[profession['id']] = profession;
                    }
                }
                return map;
            });

            specializationsMap$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    for (const specialization of professionCategory['specializations']) {
                        map[specialization['id']] = specialization;
                    }
                }
                return map;
            });

            professionsByCategory$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    map[professionCategory['id']] = professionCategory['professions'];
                }
                return map;
            });

            specializationsByCategory$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    map[professionCategory['id']] = professionCategory['specializations'];
                }
                return map;
            });

            professionCategoriesMap$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    map[professionCategory['id']] = professionCategory;
                }
                return map;
            });

            professionCategoriesAsOptions$ = professions$.then((professionCategories: any) => {
                const list = [];
                for (const professionCategory of professionCategories) {
                    list.push({name: professionCategory['name'], value: professionCategory['id']});
                }
                return list;
            });

            professionsAsOptions$ = professions$.then((professionCategories: any) => {
                const list = [];
                for (const professionCategory of professionCategories) {
                    for (const profession of professionCategory['professions']) {
                        list.push({name: profession['name'], value: profession['id']});
                    }
                }
                return list;
            });

            professionsByCategoryAsOptions$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    const list = [];
                    for (const profession of professionCategory['professions']) {
                        list.push({name: profession['name'], value: profession['id']});
                    }
                    map[professionCategory['id']] = list;
                }
                return map;
            });


            specializationsAsOptions$ = professions$.then((professionCategories: any) => {
                const list = [];
                for (const professionCategory of professionCategories) {
                    for (const specialization of professionCategory['specializations']) {
                        list.push({name: specialization['name'], value: specialization['id']});
                    }
                }
                return list;
            });

            specializationsByCategoryAsOptions$ = professions$.then((professionCategories: any) => {
                const map = {};
                for (const professionCategory of professionCategories) {
                    const list = [];
                    for (const specialization of professionCategory['specializations']) {
                        list.push({name: specialization['name'], value: specialization['id']});
                    }
                    map[professionCategory['id']] = list;
                }
                return map;
            });

            professionLevelsAsOptions$ = professionLevels$.then((levels: any) => {
                const list = [];
                for (const level of levels) {
                    list.push({name: level['name'], value: level['id']});
                }
                return list;
            });

            professionLevelsMap$ = professionLevels$.then((levels: any) => {
                const map = {};
                for (const level of levels) {
                    map[level['id']] = level;
                }
                return map;
            });

        },
        getProfessionCategoryMap: function () {
            return professionCategoriesMap$;
        },
        getProfessionCategoriesAsOptions: function () {
            return professionCategoriesAsOptions$;
        },
        getProfessionsAsOptions: function () {
            return professionsAsOptions$;
        },
        getProfessionsMap: function () {
            return professionsMap$;
        },
        getSpecializationsMap: function() {
            return specializationsMap$;
        },
        getProfessionCategoryById: function (id: number) {
            return professionCategoriesMap$.then((map: any) => map[id]);
        },
        getProfessionById: function (id: number) {
            return professionsMap$.then((map: any) => map[id]);
        },
        getSpecializationById: function (id: number) {
            return specializationsMap$.then((map: any) => map[id]);
        },
        getProfessionsByCategory: function (category: number) {
            return professionsByCategory$.then((map: any) => map[category]);
        },
        getSpecializationsAsOptions: function () {
            return specializationsAsOptions$;
        },
        getSpecializationsByCategory: function (category: number) {
            return specializationsByCategory$.then((map: any) => map[category]);
        },
        getProfessionsByCategoryAsOptions: function (category: number) {
            return professionsByCategoryAsOptions$.then((map: any) => map[category]);
        },
        getSpecializationsByCategoryAsOptions: function (category: number) {
            return specializationsByCategoryAsOptions$.then((map: any) => map[category]);
        },
        getProfessionLevelsMap: function () {
            return professionLevelsMap$;
        },
        getProfessionLevelsAsOptions: function () {
            return professionLevelsAsOptions$;
        },
        getProfessionLevelById: function (id: number) {
            return professionLevelsMap$.then((map: any) => map[id]);
        },
    };

};

const professionProviderSymbol = Symbol();

export function provideProfessionProvider() {
    const professionProvider = createProfessionProvider();
    provide(professionProviderSymbol, professionProvider);
}

export function useProfessionProvider() {
    const professionProvider = inject(professionProviderSymbol);
    if (!professionProvider) throw new Error('No profession provider provided!!!');

    return professionProvider;
}
