










































































































import {ref, defineComponent} from '@vue/composition-api';

import {Subject} from 'rxjs';
import {switchMap, debounceTime} from 'rxjs/operators';

import {useHttp} from '@/components/services/http.service';
import {useProfessionProvider} from '@/components/services/profession-provider.service';
import { getNotifier } from '@/helpers';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import TableFilter from '@/components/common/forms/TableFilter.vue';

import UserTable from '@/views/offers//UserTable.vue';
import CreateApplication from "@/views/offers/CreateApplication.vue";
import CancelApplication from "@/views/offers/CancelApplication.vue";

export default defineComponent({
    name: 'offerGroupedUsers',
    setup() {
        const professionProvider = ref();
        const http = ref();
        professionProvider.value = useProfessionProvider();
        http.value = useHttp();
        return {professionProvider, http};
    },
    components: {
        'cancel-application': CancelApplication,
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
        'user-table': UserTable,
        'create-application': CreateApplication,
    },
    mounted() {
        this.notifier = getNotifier();
        this.filters = this.filterDefs;

        setTimeout(() => {
            this.$refs.tableFilter.setValues({'professionCategory': this.offer.cover.professionCategory.id});
        });
        this.filterInput['professionCategory'] = this.offer.cover.professionCategory.id;

        this.searchQuery$ = new Subject().pipe(
            debounceTime(400),
        );
        this.searchQuerySubscription = this.searchQuery$.subscribe((query: string) => {
            this.searchQuery = query;
            this.items$.next(1);
        });

        this.items$ = new Subject().pipe(
            switchMap((page=null) => {
                const offsets = {};

                Object.keys(this.tables).forEach(table => {
                    const obj = this.tables[table];
                    obj.currentPage = page || obj.currentPage;
                    obj.allSelected = false;
                    offsets[table] = obj.currentPage * this.rowsPerPage - this.rowsPerPage;
                });

                this.isLoading = true;
                return this.http.post('/users/grouped', {
                    'offerId': this.$route.params.offerId,
                    'offsets': offsets,
                    'limit': this.rowsPerPage,
                    'sortBy': this.sortBy,
                    'sortDir': this.sortDesc ? 'desc' : 'asc',
                    'filterInput': this.filterInput,
                    'searchQuery': this.searchQuery,
                });
            })
        );
        this.itemsSubscription = this.items$.subscribe({
            next: (response: any) => {
                Object.keys(this.tables).forEach(table => {
                    const obj = this.tables[table];
                    this.$refs[obj.refName].$refs.userTable.clearSelected();
                    obj.numTotalRows = response.data['data'][table]['num_total_rows'];
                    obj.items = response.data['data'][table]['rows'];
                    obj.items.forEach(item => {
                        if(item.blacklisted){
                            item['_rowVariant'] = 'danger'
                        }
                    });
                });

                this.$emit("itemsUpdated", Object.assign({},
                    ...Object.keys(this.tables).map(k => ({[k] : this.tables[k].numTotalRows}))
                ));
                this.isLoading = false;
            }, error: () => {
                this.isLoading = false;
            }
        });
        this.items$.next(1);
    },
    props: {
        contactPerson: {
            type: Object,
            required: true
        },
        offer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            swalSendOfferData: [
                "Angebot versenden?",
                "Wollen Sie das Angebot an alle markierte Benutzer versenden?",
                "Versenden"
            ],
            applicationUserData: {},
            items$: null,
            itemsSubscription: null,
            searchQuery$: null,
            searchQuerySubscription: null,
            searchQuery: '',
            isLoading: false,
            sortBy: 'firstName',
            sortDesc: true,
            rowsPerPage: 10,

            filterInput: {},
            filters: [],
            filterListExpanded: false,
            actions: [
                { id: 'send', title: 'Angebot versenden', icon: 'send', function: (this as any).sendOfferToSelectedItems },
            ],
            tables: {
                'applicants': {
                    refName: 'tableApplicants',
                    title: 'Bewerber',
                    items: [],
                    allSelected: false,
                    currentPage: 1,
                    numTotalRows: 0
                },
                'employees_expiring': {
                    refName: 'tableEmplExp',
                    title: 'Mitarbeiter mit auslaufendem Vertrag',
                    items: [],
                    allSelected: false,
                    currentPage: 1,
                    numTotalRows: 0
                },
                'employees_rest': {
                    refName: 'tableEmplRest',
                    title: 'Restliche Mitarbeiter',
                    items: [],
                    allSelected: false,
                    currentPage: 1,
                    numTotalRows: 0
                },
                'interested': {
                    refName: 'tableInterested',
                    title: 'Interessenten',
                    items: [],
                    allSelected: false,
                    currentPage: 1,
                    numTotalRows: 0
                },
            },
            fields: [
                {
                    key: 'selected',
                },
                {
                    key: 'firstName',
                    label: 'Vorname',
                    sortable: true,
                },
                {
                    key: 'lastName',
                    label: 'Nachname',
                    sortable: true,
                },
                {
                    key: 'professionCategory',
                    label: 'Berufsgruppe',
                    sortable: true,
                },
                {
                    key: 'professionLevel',
                    label: 'Berufslevel',
                    sortable: true,
                },
                {
                    key: 'professions',
                    label: 'Fachbereiche',
                    sortable: false,
                },
                {
                    key: 'specializations',
                    label: 'Vertiefungen',
                    sortable: false,
                },
                {
                    key: 'availabilities',
                    label: 'Verfügbarkeit',
                    sortable: false,
                },
                {
                    key: 'lastSecondmentEnd',
                    label: 'Auslaufender AÜL',
                    sortable: true,
                },
                {
                    key: 'action',
                    label: 'Aktion',
                    sortable: false,
                },
            ],
            filterDefs: [
                {
                    title: 'Vorname',
                    type: 'text',
                    prop: 'firstName',
                },
                {
                    title: 'Nachname',
                    type: 'text',
                    prop: 'lastName',
                },
                {
                    title: 'Berufsgruppe',
                    type: 'select',
                    prop: 'professionCategory',
                    placeholder: 'Bitte auswählen',
                    options: (this as any).professionProvider.getProfessionCategoriesAsOptions(),
                },
                {
                    title: 'Berufslevel',
                    type: 'select',
                    prop: 'professionLevel',
                    placeholder: 'Bitte auswählen',
                    options: (this as any).professionProvider.getProfessionLevelsAsOptions(),
                },
                {
                    title: 'Fachbereich',
                    type: 'multiselect',
                    prop: 'profession',
                    placeholder: 'Bitte auswählen',
                    options: (this as any).professionProvider.getProfessionsAsOptions(),
                },
                {
                    title: 'Vertiefung',
                    type: 'multiselect',
                    prop: 'specialization',
                    placeholder: 'Bitte auswählen',
                    options: (this as any).professionProvider.getSpecializationsAsOptions(),
                },
                {
                    title: 'verfügbar von / bis',
                    type: 'date-range',
                    prop: 'availability',
                },
                {
                    title: 'Auslaufender AÜL bis',
                    type: 'date',
                    prop: 'lastSecondmentEnd',
                },
                {
                    title: 'Versendet',
                    type: 'select',
                    placeholder: 'Bitte auswählen',
                    prop: 'sent',
                    options: [
                        {name: 'Versendet', value: true},
                        {name: 'Nicht versendet', value: false},
                    ]
                },
            ],
        }
    },
    methods: {
        rowsPerPageChanged() {
            this.items$.next(1);
        },
        filter(value) {
            Object.assign(this.filterInput, value);
            this.items$.next(1);
        },
        toggleFilterList() {
            this.filterListExpanded = !this.filterListExpanded;
        },
        onSortingChanged(ctx){
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
        },
        loadPage(page, obj){
            obj.currentPage = page;
            this.items$.next();
        },
        getSelectedIds() {
            let ids = [];
            for (const key in this.tables) {
                ids = [...ids, ...this.$refs[this.tables[key].refName].getSelectedIds()];
            }
            return ids;
        },
        sendOfferToSelectedItems() {
            this.sendOfferToIds(this.getSelectedIds())
        },
        sendOfferToIds(ids){
            this.isLoading = true;

            return this.http.post('/offers/'+ this.$route.params.offerId +'/send', {
                'ids': ids
            }).then((resp) => {
                Object.values(resp.data.users_missing_email).forEach(userName => {
                    this.notifier.displayWarning(userName, "hat keine E-Mail angegeben")
                });
                Object.values(resp.data.failures).forEach((obj: any) => {
                    this.notifier.displayServerError("Fehler beim Versenden der E-Mail an " + obj.user_name + ": " + obj.message);
                });
                Object.values(resp.data.successes).forEach(userName => {
                    this.notifier.displaySuccess(userName, "Angebot erfolgreich versendet")
                });
                this.isLoading = false;
                this.items$.next(this.currentPage);
            });
        },
        onCreateApplicationClicked(userData){
            Object.assign(this.applicationUserData, userData);
            this.$refs.createApplicationModal.show();
        },
        onCancelApplicationClicked(userData){
            Object.assign(this.applicationUserData, userData);
            this.$refs.cancelApplicationModal.show();
        },
        cancelApplication(formData){
            this.isLoading = true;
            this.http.post('/offers/'+ this.$route.params.offerId +'/applications/cancel', {
                'userId': this.applicationUserData.id,
                'message': formData.message,
            }).then((resp) => {
                const failure = resp.data.failure;
                if(failure){
                    if(failure.type == 'user_missing_email'){
                        this.notifier.displayError('Kein E-Mail-Adresse', 'Für den Benutzer ' + failure.data + ' wurde keine E-Mail-Adresse hinterlegt');
                    }else if(failure.type == 'transport_exception'){
                        this.notifier.displayServerError("Fehler beim Versenden der E-Mail: " + failure.data);
                    }
                }else{
                    this.notifier.displaySuccess(this.applicationUserData.firstName + ' ' + this.applicationUserData.lastName, "Bewerbung erfolgreich abgesagt");
                    this.items$.next(this.currentPage);
                }
                this.isLoading = false;
            });
        },
      sendApplication(formData){
        this.isLoading = true;
        this.http.post('/offers/'+ this.$route.params.offerId +'/applications/send', {
          'userId': this.applicationUserData.id,
          'message': formData.message,
          'note': formData.note
        }).then((resp) => {
          const failure = resp.data.failure;
          if(failure){
            if(failure.type == 'contact_person_missing_email'){
              this.notifier.displayError('Kein E-Mail-Adresse', 'Für den Ansprechpartner ' + failure.data + ' wurde keine E-Mail-Adresse hinterlegt');
            }else if(failure.type == 'transport_exception'){
              this.notifier.displayServerError("Fehler beim Versenden der E-Mail: " + failure.data);
            }
          }else{
            this.notifier.displaySuccess(this.applicationUserData.firstName + ' ' + this.applicationUserData.lastName, "Bewerbung erfolgreich versendet");
            this.items$.next(this.currentPage);
          }
          this.isLoading = false;
        });
      },
      saveApplication(formData){
        this.isLoading = true;
        this.http.post('/offers/'+ this.$route.params.offerId +'/applications/save', {
          'userId': this.applicationUserData.id,
          'message': formData.message,
          'note': formData.note
        }).then((resp) => {
          this.notifier.displaySuccess("Bewerber zugeordnet", "Bewerber wurde erfolgreich zugeordnet");
          this.items$.next(this.currentPage);
          this.isLoading = false;
        });
      },
    },
});
