/*
 * Inspinia js helpers:
 *
 * correctHeight() - fix the height of main wrapper
 * detectBody() - detect windows size
 * smoothlyMenu() - add smooth fade in/out on navigation show/ide
 *
 */

declare const jQuery: any;

declare const toastr: any;

export function correctHeight() {

  const pageWrapper = jQuery('#page-wrapper');
  const navbarHeight = jQuery('nav.navbar-default').height();
  const wrapperHeight = pageWrapper.height();

  if (navbarHeight > wrapperHeight) {
    pageWrapper.css('min-height', navbarHeight + 'px');
  }

  if (navbarHeight <= wrapperHeight) {
    if (navbarHeight < jQuery(window).height()) {
      pageWrapper.css('min-height', jQuery(window).height() + 'px');
    } else {
      pageWrapper.css('min-height', navbarHeight + 'px');
    }
  }

  if (jQuery('body').hasClass('fixed-nav')) {
    if (navbarHeight > wrapperHeight) {
      pageWrapper.css('min-height', navbarHeight + 'px');
    } else {
      pageWrapper.css('min-height', jQuery(window).height() - 60 + 'px');
    }
  }
}

export function detectBody() {
  if (jQuery(document).width() < 769) {
    jQuery('body').addClass('body-small');
  } else {
    jQuery('body').removeClass('body-small');
  }
}

export function smoothlyMenu() {
  const body = jQuery('body');
  if (!body.hasClass('mini-navbar') || body.hasClass('body-small')) {
    // Hide menu in order to smoothly turn on when maximize menu
    jQuery('#side-menu').hide();
    // For smoothly turn on menu
    setTimeout(
      function () {
        jQuery('#side-menu').fadeIn(400);
      }, 200);
  } else if (body.hasClass('fixed-sidebar')) {
    jQuery('#side-menu').hide();
    setTimeout(
      function () {
        jQuery('#side-menu').fadeIn(400);
      }, 100);
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    jQuery('#side-menu').removeAttr('style');
  }
}

export function getNotifier() {
 return {
    displayError: (headline: string, message: string) => {
      toastr.options.progressBar = true;
      toastr.options.timeOut = 5500;
      toastr.error(message, headline);
    },
    displayServerError: (error: string) => {
      const errorMsg = error ? error : 'Benachrichtigen Sie den Support';
      toastr.options.progressBar = true;
      toastr.options.timeOut = 4500;
      toastr.error(errorMsg, 'Serverfehler');
    },
    displaySuccess: (headline: string, message: string) => {
      toastr.options.progressBar = true;
      toastr.options.timeOut = 2500;
      toastr.success(message, headline);
    },
    displayWarning: (headline: string, message: string) => {
      toastr.options.progressBar = true;
      toastr.options.timeOut = 4500;
      toastr.warning(message, headline);
    },
  };
}
