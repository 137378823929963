








import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'TransitionExpand',
    methods: {
        enter: function (element) {

            const height = getComputedStyle(element).height;
            element.style.height = '0px';

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height;
                element.style.marginBottom = '20px';
            });
        },
        leave(element) {

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = '0px';
                element.style.marginBottom = '0px';
            });
        },
        afterLeave(element) {
            element.style.height = '';
            element.style.marginBottom = '';
        },
    }
});
