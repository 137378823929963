































declare const jQuery: any;

export default {
    name: 'RejectTermination',
    data() {
        return {
            unterminatedContracts: [],
        }
    },
    methods: {
        vote(contract, contractSet) {
            this.unterminatedContracts = [];

            if (contract['contractType'] === 'asv') {
                for (const contract of contractSet) {
                    if (contract['contractType'] === 'aul') {
                        const contractEnd = new Date(contract['end']).setHours(0, 0, 0, 0);
                        const today = new Date().setHours(0, 0, 0, 0);
                        if (contract['contractEnded'] === null && contractEnd >= today) {
                            this.unterminatedContracts.push(contract);
                        }
                    }
                }
            }
            return this.unterminatedContracts.length > 0;
        },
        openModal() {
            jQuery(this.$refs.modal).modal('show');
        }
    }
}

