






import Swal from 'sweetalert2';

export default {
    name: 'VueSwal',
    props: {
        'data': null,
        'isActive': {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        swal() {
            if (this.isActive) {
                Swal.fire({
                    title: this.data[0],
                    text: this.data[1],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.data[2] ? this.data[2] : 'Löschen',
                    cancelButtonText: 'Abbrechen',
                    reverseButtons: true,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'm-xs btn btn-md btn-primary',
                        cancelButton: 'm-xs btn btn-md btn-default',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('confirmed');
                    }
                });
            }
        }
    }
};
