













import { ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import DeleteAvailability from '@/views/users/DeleteAvailability.vue';

export default {
    name: 'Availabilities',
    components: {
        'delete-availability': DeleteAvailability,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    props: {
        availabilities: null,
    },
    methods: {
        onDeleted(index) {
            this.availabilities.splice(index, 1);
        }
    }
}

