



















































































































































































































































import {ref} from '@vue/composition-api';
import {useHttp} from '@/components/services/http.service';
import {getNotifier} from "@/helpers";
import AdditionalAgreements from "@/views/billing/AdditionalAgreements.vue";
//import {useOutstandingCustomerInvoicesService} from '@/components/services/outstanding-customer-invoices.service';
import ProcessCustomerInvoice from "@/views/billing/ProcessCustomerInvoice.vue";
import CustomerInvoiceTimeline from "@/views/billing/CustomerInvoiceTimeline.vue";

function initialState() {
    return {
        isLoading: false,
        invoice: null,
        status: 200,
    }
}

export default {
    name: 'CustomerInvoiceOverview',
    components: {
        'process-customer-invoice': ProcessCustomerInvoice,
        'additional-agreements': AdditionalAgreements,
        'customer-invoice-timeline': CustomerInvoiceTimeline
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        //const outstandingCustomerInvoicesService = useOutstandingCustomerInvoicesService();
        //return {http, outstandingCustomerInvoicesService};
        return {http};
    },
    data() {
        return initialState();
    },
    props: {
        scId: {
            required: true,
        },
        month: {
            required: true
        },
        year: {
            required: true
        }
    },
    mounted() {
        this.getCustomerInvoice();
    },
    methods: {
        getCustomerInvoice() {
            this.http.get('/billing/customerInvoice/' + this.scId + '/' + this.month + '/' + this.year).then((response) => {
                this.status = 200;
                this.invoice = response.data.data['invoice'];
                this.isLoading = false;
            }).catch((error: any) => {
                console.log(error);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
        downloadTimesheetPdf() {
            this.isLoadingPdf = true;
            const notifier = getNotifier();
            // chrome likes to cache blob results, so provide a timestamp to make it clear for chrome to not do this.
            this.http.get('/billing/customerInvoice/printTimesheetPdf/' + this.scId + '/' + this.month + '/' + this.year + '?timestamp=' + new Date().getTime(),
                { responseType: 'blob' }).then((response) => {
              const file = new Blob([response.data], {type: 'application/pdf'});
              const fileURL = URL.createObjectURL(file);
              //window.open(fileURL);

              /*
               * The filename is retrieved from the 'content-disposition' header.
               *
               * It is html encoded on the server-side as umlauts would over-complicate the format
               * of the header field. Quotation marks are removed below as the filename may be returned
               * between quotation marks or not.
               */
              const headerLine = this.decodeHtml(response.headers['content-disposition']).replaceAll('"', '');
              const filename = headerLine.substring(headerLine.indexOf('=') + 1, headerLine.length);

              const link = document.createElement('a');
              link.href = fileURL;
              link.download = filename;
              link.click();
              URL.revokeObjectURL(fileURL);

              this.isLoadingPdf = false;
        }).catch((error: any) => {
              this.isLoadingPdf = false;
              notifier.displayServerError(error.message);
            });
        },
        decodeHtml(html) {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        onProcessed() {
            this.getCustomerInvoice();
        },

        },
    computed: {
        contractColumnMap() {
            return {
              'Regulärdienst': 'normalService',
              'Rufdienst': 'onCallService',
              'Bereitschaftsdienst': 'onDutyService',
              'Nachtdienst': 'nightBonus',
              'Samstag': 'saturdayBonus',
              'Sonntag': 'sundayBonus',
              'Feiertag': 'holidayBonus'
            }
        },
        curDate(){
          return {year: this.$route.params.year, month: String(this.$route.params.month).padStart(2, '0')}
        },
    }
};
