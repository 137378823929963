




















































































import {ref} from '@vue/composition-api';

import {required} from 'vuelidate/lib/validators';

import {useHttp} from '@/components/services/http.service';
import {getNotifier} from '@/helpers';
import {useProfessionProvider} from '@/components/services/profession-provider.service';

declare const jQuery: any;

function initialState() {
    return {
        form: {
            application: null,
            utilizationTimeShort: null,
            professionCategory: null,
            agentUser: null,
            customer: null,
            contactPerson: null
        },
        contactPersons: [],
        selectCustomers: [],
        agentUsers: [],
        professionCategories: [],
        offerTitle: null,
        isLoading: false,
    }
}

export default {
    name: 'EditOfferCover',
    setup() {
        const http = ref();
        const professionsProvider = ref();
        http.value = useHttp();
        professionsProvider.value = useProfessionProvider();
        return {http, professionsProvider};
    },

    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            for(const key of Object.keys(this.offerCover)){
                this.$data.form[key] = this.offerCover[key].id || this.offerCover[key];
            }
        });


        this.getProfessionCategories();
        this.getAgentUsers();
        this.getCustomers();
        this.updateOfferTitle();
    },

    props: {
        modalId: null,
        offerCover: {},
        offerId: null
    },
    data() {
        return initialState();
    },

    validations() {
        return {
            form: {
                application: {required},
                utilizationTimeShort: {required},
                professionCategory: {required},
                agentUser: {required},
                customer: {required},
                contactPerson: {required},
            }
        }
    },

    methods: {
        submit: function () {
            this.$v.$touch();
            if (this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;
            this.http.post('/offers/'+ this.offerId +'/edit', this.$data.form).then(() => {
                const d = this.$data;
                const f = d.form;
                this.offerCover.application = f.application;
                this.offerCover.utilizationTimeShort = f.utilizationTimeShort;
                this.offerCover.professionCategory = {id: f.professionCategory, name: d.professionCategories.filter(obj => obj.value === f.professionCategory)[0].name};
                this.offerCover.customer = {id: f.customer, name: d.selectCustomers.filter(obj => obj.id === f.customer)[0].name};
                this.offerCover.agentUser = {id: f.agentUser, name: d.agentUsers.filter(obj => obj.id === f.agentUser)[0].name};
                this.offerCover.contactPerson =  d.contactPersons.filter(obj => obj.id === f.contactPerson)[0];//{id: f.contactPerson, name: d.contactPersons.filter(obj => obj.id === f.contactPerson)[0].name};

                notifier.displaySuccess('Aktualisiert', 'Angebotsdeckblatt wurde aktualisiert.');
                this.modal.modal('hide');
                this.isLoading = false;
                this.$emit('edited');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        async getProfessionCategories() {
            this.professionCategories = await this.professionsProvider.getProfessionCategoriesAsOptions();
        },
        async getAgentUsers() {
            this.agentUsers = await this.http.get('/agent-users').then((response: any) => {
                return response.data.agentUsers;
            });
        },
        async getCustomers() {
            this.customers = await this.http.post('/customers', {
                offset: 0,
                limit: 1000,
                sortBy: 'facility',
                sortDir: 'DESC',
                filterInput: [],
                searchQuery: ''
            }).then((response: any) => {
                const customers = {};
                response.data['data']['rows'].forEach(customer => {
                    customers[customer.id] = customer;
                    customers[customer.id]['name'] = customer.facility + (customer.addressAddition ? " " + customer.addressAddition : "");
                });
                return customers;
            });
            this.selectCustomers = Object.values(this.customers);
        },
        async getContactPersons() {
            this.contactPersons = await this.http.get('/customer-contact-persons/' + this.form.customer)
                .then((response: any) => {
                    response.data.customerContactPersons.forEach(cp => {
                        cp['name'] = cp.firstName + ' ' + cp.lastName;
                        cp['name_responsibility'] = cp.firstName + ' ' + cp.lastName + ' (' + cp.responsibility + ')';
                    });
                    return response.data.customerContactPersons;
                });
        },
        updateContactPersons(newCustomerId, oldCustomerId) {
            if (newCustomerId === oldCustomerId)
                return;
            this.getContactPersons();
        },
        updateOfferTitle() {
            const SEP = ', ';
            this.offerTitle =
                ((this.customers && this.form.customer) ? this.customers[this.form.customer]['regionShort'] : 'Region') + SEP +
                (this.form.application || 'Einsatzgebiet') + SEP +
                (this.form.utilizationTimeShort || 'Einsatzzeitraum') + SEP +
                '#ID';
        }
    },
    watch: {
        'form.customer':  function(newCustomerId, oldCustomerId){
            this.updateContactPersons(newCustomerId, oldCustomerId);
            this.updateOfferTitle();
        },
        'form.application': function(){this.updateOfferTitle()},
        'form.utilizationTimeShort': function(){this.updateOfferTitle()},
    }
}

