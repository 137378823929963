











































































































































































































































































































































































































































import {ref} from '@vue/composition-api';

import Swal from 'sweetalert2';

import {useHttp} from '@/components/services/http.service';
import {useAuth} from '@/components/services/auth.service';
import {useOpenTasksService} from '@/components/services/open-tasks.service';
import {useContractRequirementsCheck} from '@/components/services/contract-requirements-check.service';
import {useExpirationCalculator} from '@/components/services/expiration-calculator.service';
import {getNotifier} from '@/helpers';
import AddASVAmendment from '@/views/users/AddASVAmendment.vue';
import AddASVExtension from '@/views/users/AddASVExtension.vue';
import AddAUL from '@/views/users/AddAUL.vue';
import AddAsv from '@/views/users/AddASV.vue';
import EditAUL from '@/views/users/EditAUL.vue';
import TerminateContract from '@/views/users/TerminateContract.vue';
import ContractDetails from '@/views/users/ContractDetails.vue';
import RejectTermination from '@/views/users/RejectTermination.vue';

declare const jQuery: any;

export default {
    name: 'Contracts',
    components: {
        'add-asv-extension': AddASVExtension,
        'add-asv-amendment': AddASVAmendment,
        'add-asv': AddAsv,
        'add-aul': AddAUL,
        'edit-aul': EditAUL,
        'terminate-contract': TerminateContract,
        'contract-details': ContractDetails,
        'reject-termination': RejectTermination,
    },
    setup() {
        const http = ref();
        const auth = ref();
        const contractRequirementsCheck = ref();
        http.value = useHttp();
        auth.value = useAuth();
        contractRequirementsCheck.value = useContractRequirementsCheck();
        const expirationCalculator = useExpirationCalculator();
        const openTasksService = useOpenTasksService();
        return {http, auth, contractRequirementsCheck, expirationCalculator, openTasksService};
    },
    props: {
        asvs: {
            type: Array,
            default: () => [],
        },
        professionCategory: null,
        professionLevel: null,
        queryParams: null,
    },
    mounted() {
        if (this.queryParams)
            this.addPresetAUL(this.queryParams.customerId, this.queryParams.contactPersonId);
    },
    watch: {
        asvs: {
            immediate: true,
            handler(asvs) {
                if (asvs) {
                    /**
                     * Building the entire contract view is computationally costly (takes multiple seconds for 20+
                     * contracts). It's therefore done only once at creation. As a consequence the synchronization
                     * of the associative map ('asvs') and the lists ('contractSets') which underlie the view has
                     * to be performed inside multiple functions reacting to the deletion and insertion of contracts.
                     * This of course makes the code more complex and prone to errors but the win in performance
                     * is worth the trade off.
                     */
                    if (!this.createForms) {
                        this.buildContractLists();
                        /**
                         * By using setTimeout we wait until the call stack has been executed before creating the forms.
                         * If we don't, the contract list would be shown to the user only after all forms are created which
                         * may take over a second depending on the number for contracts.
                         */
                        setTimeout(() => {
                            this.createForms = true;
                        });
                    } else {
                        const newAsvs = asvs.filter((asv1) => !this.asvsInContractSets.some((asv2) => asv1 === asv2));
                        for (const newAsv of newAsvs) {
                            this.onAsvAdded(newAsv);
                        }
                    }
                }
            }
        },
    },
    data() {
        return {
            isLoadingPdf: false,
            isLoadingEdit: {},
            isLoadingTimesheet: {},
            contractSets: [],
            curIndexSet: 1000,
            asvsInContractSets: [],
            contractsToBeTerminatedFirst: [],
            createForms: false,
            asvActions: [
                {
                    title: 'Mitarbeiter verleihen',
                    icon: 'fa-hospital-o',
                    iconType: 'fa',
                    modal: 'modalAddAUL'
                },
                {
                    title: 'Verlängerungsvertrag erstellen',
                    icon: 'date_range',
                    iconType: 'mdi',
                    modal: 'modalAddASVExtension'
                },
                {
                    title: 'Änderungsverstrag erstellen',
                    icon: 'mdi-tooltip-plus-outline',
                    iconType: 'mdi',
                    modal: 'modalAddASVAmendment'
                },
            ],
            editModals: {
                'asv': 'modalEditASV',
                'asv-extension': 'modalEditASVExtension',
                'asv-amendment': 'modalEditASVAmendment',
                'zv': 'modalEditAul',
                'aul': 'modalEditAul',
            },
            swalSign: [
                'Beachten Sie',
                'Danach ist der Vertragsinhalt nur noch durch einen Admin änderbar.',
                'Unterzeichnen',
            ],
            swalReactivate: [
                'Beachten Sie',
                'Das Reaktivieren des Vertrages ist Ihnen nur aufgrund Ihrer Admin-Rechte möglich.',
                'Reaktivieren',
            ],
            swalAdminOnly: [
                'Beachten Sie',
                'Diese Aktion ist Ihnen nur aufgrund Ihrer Admin-Rechte möglich.',
                'Bestätigen',
            ],
            swalDelete: [
                'Vertrag löschen?',
                'Dies kann nicht rückgängig gemacht werden.',
                'Löschen',
            ],
            swalDeleteZV: [
                'Vertrag löschen?',
                'Dies kann nicht rückgängig gemacht werden. Beachten Sie, dass auch die zugehörige Arbeiternehmerüberlassung gelöscht wird.',
                'Löschen',
            ],
            swalDeleteAUL: [
                'Vertrag löschen?',
                'Dies kann nicht rückgängig gemacht werden. Beachten Sie, dass auch die zugehörige Zusatzvereinbarung gelöscht wird.',
                'Löschen',
            ],
            aulPreset: {
                indexSet: null,
                customerId: null,
                contactPersonId: null
            },
        }
    },
    computed: {
        numAsvExtensionsInTheLastTwoYears() {
            const twoYearsAgo = new Date();
            twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
            twoYearsAgo.setHours(0, 0, 0, 0);

            const numAsvExtensionsInTheLastTwoYears = [];
            for (const asv of this.asvs) {
                let num = 0;
                for (const asvExtension of asv.asvExtensions) {
                    const created = new Date(asvExtension['created'] * 1000);
                    created.setHours(0, 0, 0, 0);
                    if (created >= twoYearsAgo) {
                        num++;
                    }
                }
                numAsvExtensionsInTheLastTwoYears.push(num);
            }
            return numAsvExtensionsInTheLastTwoYears;
        },
    },
    methods: {
        addPresetAUL(customerId, contactPersonId) {
            const activeContracts = this.asvsInContractSets.filter((c) => (c.contractSigned && !c.contractEnded));
            if (activeContracts.length !== 1)
                return;

            const asvNum = activeContracts[0].contractNumber;
            let cIndex = null;
            for (const [index, contracts] of Object.entries(this.contractSets)) {
                if ((contracts as Array<any>).some(contract => contract.contractNumber === asvNum)) {
                    cIndex = index;
                    break;
                }
            }

            if (!cIndex)
                return;

            this.aulPreset['indexSet'] = cIndex;
            this.aulPreset['customerId'] = customerId;
            this.aulPreset['contactPersonId'] = contactPersonId;
        },
        getContractPdf(type, id) {
            this.isLoadingPdf = true;
            const notifier = getNotifier();
            // chrome likes to cache blob results, so provide a timestamp to make it clear for chrome to not do this.
            this.http.get('/user/contract/pdf/' + type + '/' + id + '?timestamp=' + new Date().getTime(),
                {responseType: 'blob'}).then((response) => {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                //window.open(fileURL);

                /*
                 * The filename is retrieved from the 'content-disposition' header.
                 *
                 * It is html encoded on the server-side as umlauts would over-complicate the format
                 * of the header field. Quotation marks are removed below as the filename may be returned
                 * between quotation marks or not.
                 */
                const headerLine = this.decodeHtml(response.headers['content-disposition']).replaceAll('"', '');
                const filename = headerLine.substring(headerLine.indexOf('=') + 1, headerLine.length);

                const link = document.createElement('a');
                link.href = fileURL;
                link.download = filename;
                link.click();
                URL.revokeObjectURL(fileURL);

                this.isLoadingPdf = false;
            }).catch((error: any) => {
                this.isLoadingPdf = false;
                notifier.displayServerError(error.message);
            });
        },
        setReturned(indexSet, indexContract, isReturned) {
            const notifier = getNotifier();
            const contract = this.contractSets[indexSet][indexContract];
            const action = isReturned ? 'mark-as-returned' : 'mark-as-unreturned';
            this.isLoadingEdit[indexSet + '-' + indexContract] = true;

            this.http.post('/user/contract/' + action + '/' + contract['contractType'] + '/' + contract['id']).then(() => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                contract['contractReturned'] = isReturned;
                this.openTasksService.pollOpenTasks();
            }).catch((error: any) => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                notifier.displayServerError(error.message);
            });
        },
        setSigned(indexSet, indexContract, isSigned) {
            const notifier = getNotifier();
            const contract = this.contractSets[indexSet][indexContract];
            const action = isSigned ? 'sign' : 'unsign';
            this.isLoadingEdit[indexSet + '-' + indexContract] = true;

            this.http.post('/user/contract/' + action + '/' + contract['contractType'] + '/' + contract['id']).then((response) => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                contract['contractSigned'] = isSigned;
                this.$emit(action + 'ed', {contract: contract, isEmployed: response.data['isEmployed']});
                this.openTasksService.pollOpenTasks();
            }).catch((error: any) => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                notifier.displayServerError(error.message);
            });
        },
        reactivate(indexSet, indexContract) {
            const notifier = getNotifier();
            const contract = this.contractSets[indexSet][indexContract];
            this.isLoadingEdit[indexSet + '-' + indexContract] = true;

            this.http.post('/user/contract/reactivate/' + contract['contractType'] + '/' + contract['id']).then((response) => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                contract['contractEnded'] = null;
                if (contract['contractType'] === 'aul') {
                    contract.zv['contractEnded'] = null;
                    contract.zv['contractEndedReason'] = null;
                }
                this.$emit('reactivated', {contract: contract, isEmployed: response.data['isEmployed']});
                this.openTasksService.pollOpenTasks();
            }).catch((error: any) => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                notifier.displayServerError(error.message);
            });
        },
        deleteContract(indexSet, indexContract) {
            const notifier = getNotifier();
            const contract = this.contractSets[indexSet][indexContract];
            this.isLoadingEdit[indexSet + '-' + indexContract] = true;

            this.http.post('/user/contract/delete/' + contract['contractType'] + '/' + contract['id']).then(() => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                this.onContractDeleted(indexSet, indexContract);

                this.openTasksService.pollOpenTasks();
            }).catch((error: any) => {
                this.isLoadingEdit[indexSet + '-' + indexContract] = false;
                notifier.displayServerError(error.message);
            });
        },
        sendTimesheet(indexSet, indexContract) {
            const notifier = getNotifier();

            const contract = this.contractSets[indexSet][indexContract];
            this.isLoadingTimesheet[indexSet + '-' + indexContract] = true;

            this.http.post('/user/contract/send-timesheet/' + contract['id']).then((response) => {
                this.isLoadingTimesheet[indexSet + '-' + indexContract] = false;
                if (response.data['OK'] === 1) {
                    notifier.displaySuccess('Versendet', 'Das Stundenzettel-PDF wurde an den Mitarbeiter verschickt.');
                } else {
                    notifier.displayServerError(response.data['failure']);
                }
            }).catch((error: any) => {
                this.isLoadingTimesheet[indexSet + '-' + indexContract] = false;
                notifier.displayServerError(error.message);
            });
        },
        isContractDeleteable(indexSet, contract) {
            return !contract.contractSigned
                && (contract.contractType !== 'asv' || this.contractSets[indexSet].length === 1)
                && (contract.contractType !== 'zv' || !contract.aul.contractSigned)
                && (contract.contractType !== 'aul' || !contract.zv.contractSigned);
        },
        getSwalDelete(contractType) {
            switch (contractType) {
                case 'zv':
                    return this.swalDeleteZV;
                case 'aul':
                    return this.swalDeleteAUL;
                default:
                    return this.swalDelete;
            }
        },
        openModal(id) {
            jQuery('#' + id).modal('show');
        },
        rejectTermination(indexSet, indexContract) {
            const contract = this.contractSets[indexSet][indexContract];
            if (this.$refs.rejectTermination.vote(contract, this.contractSets[indexSet])) {
                this.$refs.rejectTermination.openModal();
                return true;
            }
            return false;
        },
        openAddContractsModal(modalId, indexSet) {
            if (this.contractSets[indexSet][0]['contractSigned'] === false) {
                Swal.fire({
                    title: 'Noch nicht möglich',
                    text: 'Sie können Verträge zu diesem Anstellungsverhältnis erst hinzufügen, wenn der Anstellungsvertrag unterschrieben ist.',
                    icon: 'warning',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'm-xs btn btn-md btn-default',
                    }
                });
                return;
            }

            if (!this.contractRequirementsCheck.requirementsSatisfied()) {
                modalId = 'modalFailedRequirements';
            }
            jQuery('#' + modalId + indexSet).modal('show');
        },
        onContractTerminated(contract, isEmployed) {
            this.$emit('terminated', {contract: contract, isEmployed: isEmployed});
        },
        onAsvExtensionAdded(asvExtension, indexSet, asv) {
            let insertAtIndex = 0;
            for (const contract of this.contractSets[indexSet]) {
                if (contract['contractType'] === 'asv-amendment' || contract['contractType'] === 'zv') break;
                insertAtIndex++;
            }
            this.contractSets[indexSet].splice(insertAtIndex, 0, asvExtension);
            this.awakeLoadingSpinners();

            this.$emit('added', {asv: asv, list: 'asvExtensions', contract: asvExtension});
        },
        onAsvAmendmentAdded(asvAmendment, indexSet, asv) {
            let insertAtIndex = 0;
            for (const contract of this.contractSets[indexSet]) {
                if (contract['contractType'] === 'zv') break;
                insertAtIndex++;
            }
            this.contractSets[indexSet].splice(insertAtIndex, 0, asvAmendment);
            this.awakeLoadingSpinners();

            this.$emit('added', {asv: asv, list: 'asvAmendments', contract: asvAmendment});
        },
        onAulAdded(zv, indexSet, asv) {
            zv['aul']['zv'] = zv;

            this.contractSets[indexSet].push(zv);
            this.contractSets[indexSet].push(zv.aul);
            this.awakeLoadingSpinners();

            this.$emit('added', {asv: asv, list: 'zvs', contract: zv});
        },
        onAsvAdded(asv) {
            const contracts = [];
            contracts.push(asv);
            this.$set(this.contractSets, this.curIndexSet--, contracts);
            this.asvsInContractSets.push(asv);
            this.awakeLoadingSpinners();
        },
        onContractEdited(contract) {
            contract.lastModified = new Date();
            if (contract.contractType === 'zv') {
                contract.aul['lastModified'] = new Date();
            }
        },
        onContractDeleted(indexSet, indexContract) {
            const contract = this.contractSets[indexSet][indexContract];
            const asv = this.contractSets[indexSet][0];

            if (contract['contractType'] === 'asv') {
                this.$emit('asvDeleted', contract);
                this.$delete(this.contractSets, indexSet);

            } else if (contract['contractType'] === 'zv') {
                this.$emit('deleted', {asv: asv, list: 'zvs', contract: contract});
                this.contractSets[indexSet].splice(indexContract, 2);

            } else if (contract['contractType'] === 'aul') {
                this.$emit('deleted', {asv: asv, list: 'zvs', contract: contract.zv});
                this.contractSets[indexSet].splice(indexContract - 1, 2);

            } else if (contract['contractType'] === 'asv-extension') {
                this.$emit('deleted', {asv: asv, list: 'asvExtensions', contract: contract});
                this.contractSets[indexSet].splice(indexContract, 1);

            } else if (contract['contractType'] === 'asv-amendment') {
                this.$emit('deleted', {asv: asv, list: 'asvAmendments', contract: contract});
                this.contractSets[indexSet].splice(indexContract, 1);
            }
        },
        buildContractLists() {
            this.contractSets = {};
            for (const asv of this.asvs) {
                this.asvsInContractSets.push(asv);

                const contracts = [];
                contracts.push(asv);
                for (const asvExtension of asv.asvExtensions) {
                    contracts.push(asvExtension);
                }
                for (const asvAmendment of asv.asvAmendments) {
                    contracts.push(asvAmendment);
                }
                for (const zv of asv.zvs) {
                    contracts.push(zv);
                    contracts.push(zv.aul);
                    zv.aul['zv'] = zv;
                }
                this.$set(this.contractSets, this.curIndexSet--, contracts);
            }
            this.awakeLoadingSpinners();
        },
        awakeLoadingSpinners() {
            for (const indexSet in this.contractSets) {
                for (let indexContract = 0; indexContract < this.contractSets[indexSet].length; indexContract++) {
                    // this.$set makes a property reactive, https://vuejs.org/v2/guide/reactivity.html
                    this.$set(this.isLoadingEdit, indexSet + '-' + indexContract, false);
                    this.$set(this.isLoadingTimesheet, indexSet + '-' + indexContract, false);
                }
            }
        },
        decodeHtml(html) {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        size(obj) {
            return Object.keys(obj).length;
        }
    }
};
