














import {ref} from '@vue/composition-api';

import {useHttp} from '@/components/services/http.service';
import {getNotifier} from '@/helpers';
import {useOutstandingCustomerInvoicesService} from '@/components/services/outstanding-customer-invoices.service';

export default {
    name: 'ProcessCustomerInvoice',
    setup() {
        const http = ref();
        http.value = useHttp();
        const outstandingCustomerInvoicesService = useOutstandingCustomerInvoicesService();
        return {http, outstandingCustomerInvoicesService};
    },
    props: {
        scId: null,
        month: null,
        year: null,
        processed: null,
    },
    data() {
        return {
            swalDelete: ['Endgültig abschließen?', 'Dieser Vorgang kann nicht mehr zuruckgenommen werden!', 'Als abgerechnet markieren'],
            isLoading: false,
        }
    },
    methods: {
        submit: function () {
            const notifier = getNotifier();
            this.isLoading = true;

            this.http.get('/billing/customerInvoice/markProcessed/' + this.scId + '/' + this.month + '/' + this.year).then(() => {
                this.outstandingCustomerInvoicesService.pollOutstandingCustomerInvoices();
                notifier.displaySuccess('Kundenrechnung berechnet', 'Rechnung als abgerechnet markiert');
                this.isLoading = false;
                this.$emit('processed');
            }).catch((error: any) => {
                console.log(error);
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}

