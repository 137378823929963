





































import { ref } from '@vue/composition-api';
import { useHttp } from '@/components/services/http.service';

function initialState() {
    return {
        isLoading: true,
        employee: null,
    }
}

export default {
    name: 'paystubEmployee',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    data() {
        return initialState();
    },
    props:{
        employeeNumber: {
            required: true,
        },
        date: {
            type: Object,
            required: true
        },
    },
    mounted() {
        this.getEmployee();
    },
    methods:{
        getEmployee(){
            this.isLoading = true;
            this.http.get('/billing/paystub/employee/' + this.employeeNumber + '/' + this.date.month + '-' + this.date.year).then((response) => {
                this.status = 200;
                this.employee = response.data['data'];
                this.$emit('onEmployeeLoaded', this.employee);
                this.isLoading = false;
            }).catch((error: any) => {
                console.log(error);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
    }
};
