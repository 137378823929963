















































































































































































import {defineComponent, ref} from '@vue/composition-api';

import {Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators'; //debouce

import {useHttp} from '@/components/services/http.service';
import TransitionExpand from '@/components/common/animations/TransitionExpand.vue';
import TableFilter from '@/components/common/forms/TableFilter.vue';
import DatatableCheckbox from '@/components/common/forms/DatatableCheckbox.vue';

export default defineComponent({
    name: 'FollowUpContracts',
    components: {
        'transition-expand': TransitionExpand,
        'table-filter': TableFilter,
        'datatable-checkbox': DatatableCheckbox,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return {http};
    },
    destroyed() {
        this.itemsSubscription.unsubscribe();
    },
    watch: {
        '$route'() {
            this.setTableProperties();
            this.items$.next(1);
        }
    },
    data() {
        return {
            savedTableState: {},
            items$: null,
            itemsSubscription: null,
            isLoading: false,
            currentPage: 1,
            sortBy: 'firstName',
            sortDesc: false,
            rowsPerPage: 10,
            numTotalRows: null,
            items: [],
            filterInput: {},
            filters: [],
            filterListExpanded: false,
            duration: 2,
            fields: [
                {
                    key: 'firstName',
                    label: 'Vorname',
                    sortable: true,
                },
                {
                    key: 'lastName',
                    label: 'Nachname',
                    sortable: true,
                },
                {
                    key: 'employed',
                    label: 'Status',
                    sortable: true,
                },
                {
                    key: 'expiringContracts',
                    label: 'Endende Verleihung',
                    sortable: true,
                },
                {
                    key: 'upcomingContracts',
                    label: 'Ausstehende Verleihung',
                    sortable: true,
                },
                {
                    key: 'employmentContracts',
                    label: 'Anstellungsverhälnisse',
                    sortable: true,
                },
                {
                    key: 'action',
                    label: 'Aktion',
                    sortable: false,
                },
            ],
            filterDefs: [
                {
                    title: 'Vorname',
                    type: 'text',
                    prop: 'firstName',
                },
                {
                    title: 'Nachname',
                    type: 'text',
                    prop: 'lastName',
                },
            ],
        }
    },
    mounted() {
        this.filters = this.filterDefs;

        this.items$ = new Subject().pipe(
            switchMap((page) => {
                this.isLoading = true;
                this.currentPage = page;
                this.saveTableProperty('currentPage', page);
                return this.http.post('/controlling/follow-up-contracts', {
                    'offset': this.currentPage * this.rowsPerPage - this.rowsPerPage,
                    'limit': this.rowsPerPage,
                    'sortBy': this.sortBy,
                    'sortDir': this.sortDesc ? 'desc' : 'asc',
                    'filterInput': this.filterInput,
                    'duration': this.duration,
                });
            }));
        this.itemsSubscription = this.items$.subscribe({
            next: (response: any) => {
                this.numTotalRows = response.data['data']['num_total_rows'];
                this.items = response.data['data']['rows'];
                this.$refs.followUpContractsTable.clearSelected();
                this.isLoading = false;
            }, error: (err) => {
                console.log(err);
                this.isLoading = false;
            }
        });

        this.items$.next(this.currentPage);
    },
    computed: {
        filterInputEmpty() {
            for (const prop in this.filterInput) {
                if (this.filterInput[prop] !== null && this.filterInput[prop] !== '' && this.filterInput[prop] !== undefined) {
                    return false;
                }
            }
            return true;
        },
        today() {
            return new Date().setHours(0, 0, 0, 0);
        },
    },
    methods: {
        durationChanged() {
            this.saveTableProperty('rowsPerPage', this.rowsPerPage);
            this.items$.next(1);
        },
        sortingChanged(ctx) {
            this.saveTableProperty('sortBy', ctx.sortBy);
            this.saveTableProperty('sortDesc', ctx.sortDesc);
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.items$.next(1);
        },
        rowsPerPageChanged() {
            this.saveTableProperty('rowsPerPage', this.rowsPerPage);
            this.items$.next(1);
        },
        toggleFilterList() {
            this.filterListExpanded = !this.filterListExpanded;
        },
        clearFilters() {
            this.$refs.tableFilter.clearValues();
            this.filterInput = {};
            this.clearTableProperties();
            this.items$.next(1);
        },
        onPageChange(page) {
            this.items$.next(page);
        },
        filter(values) {
            for (const prop in values) {
                // Properties of 'filterInput' need to be reactive as computed property 'filterInputEmpty' depends on them.
                this.$set(this.filterInput, prop, values[prop]);
            }
            this.saveTableProperty('filterInput', this.filterInput);
            this.items$.next(1);
        },
        saveTableProperty(prop, value) {
            this.savedTableState[prop] = value;
            window.sessionStorage.setItem('follow-up-contracts-table', JSON.stringify(this.savedTableState));
        },
        loadTableProperties() {
            this.savedTableState = JSON.parse(window.sessionStorage.getItem('follow-up-contracts-table'));
            if (this.savedTableState === null) {
                this.savedTableState = {};
                window.sessionStorage.setItem('follow-up-contracts-table', JSON.stringify(this.savedTableState));
            }
            Object.assign(this.$data, this.savedTableState);
        },
        clearTableProperties() {
            this.savedTableState = {};
            window.sessionStorage.removeItem('follow-up-contracts-table');
        },
    }

});

