import { provide, inject } from '@vue/composition-api';

const createExpirationCalculator = () => {

    return {
        isDateMoreThanOneYearAgo(dateStr) {
            const date = new Date(dateStr);
            const dateInOneYear = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return today > dateInOneYear;
        },
        didEmploymentExpireInTheLastMonth(employmentExpirationDate) {
            if (!employmentExpirationDate) {
                return false;
            }
            const endDate = new Date(employmentExpirationDate);
            endDate.setHours(0, 0, 0, 0);
            const overdueDate = new Date(employmentExpirationDate);
            overdueDate.setHours(0,0,0,0);
            overdueDate.setDate(10);
            overdueDate.setMonth(overdueDate.getMonth() + 1);
            const todayMidnight = new Date();
            todayMidnight.setHours(0, 0, 0 ,0);
            return todayMidnight > endDate && todayMidnight <= overdueDate;
        },
        getEmploymentExpirationDate(asv) {
            if (!asv['end'] || !asv['contractSigned'] || asv['contractEnded'] !== null) {
                return null;
            }
            let employmentExpirationDate = new Date(asv['end']);
            const activeAsvExtensions = asv['asvExtensions'].filter((asvExtension) => asvExtension['contractSigned']
                && asvExtension['contractEnded'] === null);

            for (const activeAsvExtension of activeAsvExtensions) {
                const contractExtensionDate = new Date(activeAsvExtension['contractExtensionDate']);
                if (contractExtensionDate > employmentExpirationDate) {
                    employmentExpirationDate = contractExtensionDate;
                }
            }
            return employmentExpirationDate;
        },
        getAsvEnd(asv) {
            if (!asv['end']) {
                return null;
            }
            let employmentEnd = new Date(asv['end']);
            for (const asvExtension of asv['asvExtensions']) {
                const contractExtensionDate = new Date(asvExtension['contractExtensionDate']);
                if (contractExtensionDate > employmentEnd) {
                    employmentEnd = contractExtensionDate;
                }
            }
            return employmentEnd;
        },
        isNewEmploymentAllowed(employmentEndDate) {
            if (!employmentEndDate) {
                return true;
            }
            const newContractAllowedDate = new Date(employmentEndDate);
            newContractAllowedDate.setDate(newContractAllowedDate.getDate() + 250);
            newContractAllowedDate.setHours(0, 0, 0, 0);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return today >= newContractAllowedDate;
        }
    };
};

const expirationCalculatorSymbol = Symbol();

export function provideExpirationCalculator() {
    const expirationCalculator = createExpirationCalculator();
    provide(expirationCalculatorSymbol, expirationCalculator);
}

export function useExpirationCalculator() {
    const expirationCalculator = inject(expirationCalculatorSymbol);
    if (!expirationCalculator) throw new Error("No expiration calculator provided!!!");

    return expirationCalculator;
}
