





































































































































































































































































































































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';
import Vue2Filters from 'vue2-filters';
import Swal from 'sweetalert2';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import DeleteTimesheetEntry from '@/views/billing/DeleteTimesheetEntry.vue';
import EditTimesheetEntry from '@/views/billing/EditTimesheetEntry.vue';

function initialState() {
    return {
        holidayActive: false,
        datePickerMenu: null,
        formattedDate: null,
        isLoading: false,
        isLoadingTimesheet: false,
        isLoadingTimesheetEntries: false,
        timesheetNoServerError: null,
        timesheetNo: null,
        timesheet: {},
        timesheetEntries: [],
        holidays: [],
        selectedTimesheetEntry: 0,
        allTimesheetEntriesLoaded: true,
        loadedUntilDate: null,
        typeOfDayOptions: [
            {name: 'Ja', value: 1, disabled: true},
            {name: 'Urlaubstag', value: 2},
            {name: 'Krankheit', value: 3},
        ],
        typeOfWorkOptions: [
            {name: 'Bereitschaft', value: 2},
            {name: 'Rufdienst', value: 3},
        ],
        form: {
            timesheetNo: null,
        },
        form1: {
            date: null,
            startHours: null,
            startMinutes: '00',
            endHours: null,
            endMinutes: '00',
            typeOfDay: 1,
            typeOfWork: 1,
            note: null,
            break: null,
        },
        form2: {
            date: null,
            startHours: null,
            startMinutes: '00',
            endHours: null,
            endMinutes: '00',
            typeOfDay: 1,
            typeOfWork: 1,
            note: null,
            break: null,
        },
    }
}

declare const jQuery: any;

export default {
    name: 'HourRecording',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    components: {
        'delete-timesheet-entry': DeleteTimesheetEntry,
        'edit-timesheet-entry': EditTimesheetEntry,
    },
    mixins: [Vue2Filters.mixin],
    data() {
        return initialState();
    },
    mounted() {
        this.focusTimesheetNoControl();
        this.scroll();
    },
    validations() {
        return {
            form1: {
                date: {
                    required,
                    datePattern() {
                        const re = /^\d\d?\.\d\d?\.(\d\d\d\d|\d\d)$/;
                        return re.test(this.formattedDate);
                    },
                    isValidDate(v) {
                        return this.$moment(v, 'YYYY-MM-DD').isValid();
                    },
                    isInContractPeriod(v) {
                        const selectedDate = new Date(v);
                        const begin = new Date(this.timesheet['contractBegin']);
                        if (null === this.timesheet['contractEnd']) {
                            return selectedDate >= begin;
                        }
                        const end = new Date(this.timesheet['contractEnd']);
                        return selectedDate >= begin && selectedDate <= end;
                    }
                },
                startHours: {
                    required(v) {
                        return this.form1.typeOfDay !== 1  || this.form2.typeOfWork > 1 || required(v);
                    },
                    isHour(v) {
                        return v >= 0 && v <= 23;
                    }
                },
                startMinutes: {
                    required(v) {
                        return this.form1.typeOfDay !== 1  || this.form2.typeOfWork > 1 || required(v);
                    },
                    isMinute(v) {
                        return v >= 0 && v <= 59;
                    }
                },
                endHours: {
                    required(v) {
                        return this.form1.typeOfDay !== 1  || this.form2.typeOfWork > 1 || required(v);
                    },
                    isHour(v) {
                        return v >= 0 && v <= 23;
                    },
                },
                endMinutes: {
                    required(v) {
                        return this.form1.typeOfDay !== 1  || this.form2.typeOfWork > 1 || required(v);
                    },
                    isMinute(v) {
                        return v >= 0 && v <= 59;
                    }
                },
                typeOfDay: {required},
                typeOfWork: {required},
            },
            form2: {
                startHours: {
                    required(v) {
                        return this.form2.typeOfWork === 1 || required(v);
                    },
                    isHour(v) {
                        return v >= 0 && v <= 23;
                    }
                },
                startMinutes: {
                    required(v) {
                        return this.form2.typeOfWork === 1 || required(v);
                    },
                    isMinute(v) {
                        return v >= 0 && v <= 59;
                    }
                },
                endHours: {
                    required(v) {
                        return this.form2.typeOfWork === 1 || required(v);
                    },
                    isHour(v) {
                        return v >= 0 && v <= 23;
                    },
                },
                endMinutes: {
                    required(v) {
                        return this.form2.typeOfWork === 1 || required(v);
                    },
                    isMinute(v) {
                        return v >= 0 && v <= 59;
                    }
                },
                typeOfDay: {required},
                typeOfWork: {required},
            },
            form: {
                timesheetNo: {
                    required,
                    timesheetNoFormat(v) {
                        if (!v) return false;
                        const re = /^\s*\d+\s*(-\s*\d+\s*)*$/;
                        return re.test(v);
                    }
                },
            }
        }
    },
    computed: {
        timesheetEntrySelectedForEdit() {
            return this.timesheetEntries[this.selectedTimesheetEntry];
        },
    },
    watch: {
        'form.timesheetNo'() {
            this.timesheetNoServerError = null;
        },
        'form1.date'(v) {
            this.holidayActive = this.holidays.includes(v);
        },
    },
    methods: {
        open: function() {
            this.$v['form'].$touch();
            if (this.$v['form'].$error) return;

            const notifier = getNotifier();

            this.timesheetNo = null;
            this.timesheetNoServerError = null;
            this.allTimesheetEntriesLoaded = true;
            this.loadedUntilDate = null;

            this.$v.$reset();
            const data = initialState();
            this.form1 = data.form1;

            this.isLoadingTimesheet = true;
            this.http.get('/billing/timesheet/' + this.form.timesheetNo).then(({ data }) => {
                if (data['OK'] === 1) {
                    this.timesheetNo = this.form.timesheetNo;
                    this.timesheet = data['timesheet'];
                    this.timesheetEntries = this.timesheet.timesheetEntries;
                    this.holidays = data['holidays'];

                    if (this.timesheetEntries.length > 0) {
                        this.allTimesheetEntriesLoaded = false;
                        this.loadedUntilDate = this.timesheetEntries[this.timesheetEntries.length - 1].start;
                        this.form1.date = this.timesheetEntries[0]['date'];
                        this.incrementDate();
                    } else {
                        this.form1.date = this.timesheet['contractBegin'];
                        this.formattedDate = this.formatDate(this.form1.date);
                    }

                    if (null === this.timesheet['employer']) {
                        this.typeOfDayOptions[0].disabled = true;
                        this.form1.typeOfDay = 2;
                        this.$nextTick(() => this.focusTypeOfDayControl());
                    } else {
                        this.typeOfDayOptions[0].disabled = false;
                        this.$nextTick(() => this.focusDateControl());
                    }

                    this.loadMoreIfNeeded();
                } else if (data['OK'] === 0) {
                    this.timesheetNoServerError = data['validationError'];
                }
                this.isLoadingTimesheet = false;
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoadingTimesheet = false;
            });
        },
        async submit() {
            this.$v['form1'].$touch();
            this.$v['form2'].$touch();
            if (this.$v['form1'].$error || this.$v['form2'].$error) return;
            const notifier = getNotifier();

            const initialData = initialState();
            const lastDate = this.form1.date;
            const lastTypeOfDay = this.form1.typeOfDay;

            const forms = [];
            if (null !== this.form1.startHours && '' !== this.form1.startHours || this.form1.typeOfDay > 1) {
                forms.push(this.form1);
            }
            if (this.form2.typeOfWork > 1) {
                this.form2.date = this.form1.date;
                forms.push(this.form2);
            }

            this.isLoading = true;
            await this.http.post('/billing/timesheet-entry/add/' + this.timesheetNo, forms).then(({ data }) => {

                if (data['OK'] === 1) {
                    const ids = data['ids'];
                    forms.forEach((form, index) => {
                        form.id = ids[index];
                        form.timesheetNo = this.timesheetNo;
                        this.timesheetEntries.unshift(form);
                    });

                    this.$v.$reset();
                    this.form1 = initialData.form1;
                    this.form2 = initialData.form2;
                    this.form1.date = lastDate;
                    this.form1.typeOfDay = lastTypeOfDay;
                    this.incrementDate();
                    lastTypeOfDay === 1 ? this.focusDateControl() : this.focusTypeOfDayControl();

                } else if (data['OK'] === 0) {
                    Swal.fire({
                        title: 'Fehler',
                        text: data['error'],
                        icon: 'warning',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'm-xs btn btn-md btn-default',
                        }
                    });
                }
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
            });
            this.isLoading = false;
        },
        scroll() {
            window.onscroll = () => {
                this.loadMoreIfNeeded();
            };
        },
        loadMoreIfNeeded() {
            if (!this.allTimesheetEntriesLoaded && !this.isLoadingTimesheetEntries) {
                const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow) {
                    this.isLoadingTimesheetEntries = true;
                    const form = { 'dateOffset': this.loadedUntilDate };
                    this.http.post('/billing/timesheet/load-more/' + this.timesheetNo, form).then(({ data }) => {
                        for (const timesheetEntry of data['timesheetEntries']) {
                            this.timesheetEntries.push(timesheetEntry);
                        }
                        if (data['timesheetEntries'].length === 0) {
                            this.allTimesheetEntriesLoaded = true;
                        } else {
                            this.loadedUntilDate = data['timesheetEntries'][data['timesheetEntries'].length - 1].start;
                        }
                        this.isLoadingTimesheetEntries = false;
                    }).catch((error: any) => {
                        const notifier = getNotifier();
                        notifier.displayServerError(error.message);
                        this.isLoadingTimesheetEntries = false;
                    });
                }
            }
        },
        onDateControlKeypress(event) {
            if (event.key === 'ArrowUp') {
                this.incrementDate();
            } else if (event.key === 'ArrowDown') {
                this.decrementDate();
            }
        },
        incrementDate() {
            const tomorrow = new Date(this.form1.date);
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.form1.date = this.$moment(tomorrow).format('YYYY-MM-DD');
            this.formattedDate = this.formatDate(this.form1.date);
        },
        decrementDate() {
            const yesterday = new Date(this.form1.date);
            yesterday.setDate(yesterday.getDate() - 1);
            this.form1.date = this.$moment(yesterday).format('YYYY-MM-DD');
            this.formattedDate = this.formatDate(this.form1.date);
        },
        focusDateControl() {
            this.$refs.startHoursControl.$el.querySelector('input').focus();
        },
        focusTypeOfDayControl() {
            this.$refs.typeOfDayControl.$el.querySelector('input').focus();
        },
        focusTimesheetNoControl() {
            this.$refs.timesheetNoControl.focus();
        },
        openModal(id, index) {
            this.selectedTimesheetEntry = index;
            this.$nextTick(() => {
                jQuery('#' + id).modal('show');
            });
        },
        onTypeOfDayChanged(v) {
            if (v !== 1) {
                const data = initialState();
                this.form1.startHours = data.form1.startHours;
                this.form1.startMinutes = data.form1.startMinutes;
                this.form1.endHours = data.form1.endHours;
                this.form1.endMinutes = data.form1.endMinutes;
                this.form1.typeOfWork = data.form1.typeOfWork;
                this.form2 = data.form2;
            }
        },
        onFormattedDateChanged(v) {
            const parsedDate = this.$moment(v, 'DD.MM.YYYY');
            this.form1.date = parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : '2020-01-01';
        },
        onTimesheetEntryDeleted(rowIndex) {
            this.timesheetEntries.splice(rowIndex, 1);
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        requiredError(form, prop) {
            if (this.$v[form][prop].$dirty && !this.$v[form][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        timesheetError(form, prop) {
            if (!this.$v[form][prop].$dirty) return null;
            if (!this.$v[form][prop].required) return null;
            if (!this.$v[form][prop].timesheetNoFormat)
                return "Mögliche Eingaben:<br>Mitarbeiternummer - Vertragsnummer<br>Mitarbeiternummer";
            return null;
        },
        hoursError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].isHour && errors.push('Bitte eine Zahl zwischen 0 und 23 eingeben.');
            return errors;
        },
        minutesError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].isMinute && errors.push('Bitte eine Zahl zwischen 0 und 59 eingeben.');
            return errors;
        },
        dateError(form, prop) {
            const errors = [];
            if (!this.$v[form][prop].$dirty) return errors;
            !this.$v[form][prop].required && errors.push('Bitte ausfüllen');
            !this.$v[form][prop].datePattern && errors.push('Bitte ein Datum eingeben.');
            !this.$v[form][prop].isValidDate && errors.push('Bitte ein gültiges Datum eingeben.');
            !this.$v[form][prop].isInContractPeriod && errors.push('Datum muss innerhalb der Vertragszeit liegen.');
            return errors;
        },
    }
};
