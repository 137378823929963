

























































































































declare const jQuery: any;

import { defineComponent } from '@vue/composition-api';

import { smoothlyMenu } from '@/helpers';
import { useAuth } from '@/components/services/auth.service';
import { useOpenTasksService } from '@/components/services/open-tasks.service';
import { useOutstandingPaystubsService } from '@/components/services/outstanding-paystubs.service';
import { useOutstandingCustomerInvoicesService } from '@/components/services/outstanding-customer-invoices.service';

export default defineComponent({
    name: 'AppTopnavbar',
    setup() {
        const auth = useAuth();
        const openTasksService = useOpenTasksService();
        const oustandingPaystubsService = useOutstandingPaystubsService();
        const oustandingCustomerInvoicesService = useOutstandingCustomerInvoicesService();
        return { auth, openTasksService, oustandingPaystubsService, oustandingCustomerInvoicesService };
    },
    mounted() {

        this.openTasksSubscription = this.openTasksService.getOpenTasks().subscribe({
            next: (openTasks) => {
                this.numEmploymentEndTasks = openTasks['numEmploymentEndTasks'];
                this.numSignTasks = openTasks['numSignTasks'];
                this.numReturnTasks = openTasks['numReturnTasks'];

                this.numOpenTasks = this.numEmploymentEndTasks + this.numSignTasks + this.numReturnTasks;
            }
        });

        this.outstandingCustomerInvoicesSubscription = this.oustandingCustomerInvoicesService.getOutstandingCustomerInvoices().subscribe({
            next: (outstandingCustomerInvoices) => {
                this.outstandingCustomerInvoices = outstandingCustomerInvoices.data;
            }
        });

        this.outstandingPaystubsSubscription = this.oustandingPaystubsService.getOutstandingPaystubs().subscribe({
            next: (outstandingPaystubs) => {
                this.outstandingPaystubs = outstandingPaystubs.data;
            }
        });

        this.oustandingPaystubsService.pollOutstandingPaystubs();
        this.oustandingCustomerInvoicesService.pollOutstandingCustomerInvoices();
    },
    destroyed() {
        this.openTasksSubscription.unsubscribe();
        this.outstandingPaystubsSubscription.unsubscribe();
        this.outstandingCustomerInvoicesSubscription.unsubscribe();
    },
    data() {
        return {
            numOpenTasks: null,
            numEmploymentEndTasks: null,
            numSignTasks: null,
            numReturnTasks: null,
            openTasksSubscription: null,

            outstandingPaystubs: null,
            outstandingPaystubsSubscription: null,

            outstandingCustomerInvoices: null,
            outstandingCustomerInvoicesSubscription: null,

        }
    },
    methods: {
        toggleNavigation: function () {
            jQuery('body').toggleClass('mini-navbar');
            smoothlyMenu();
        },
        logout() {
            this.auth.logout();
        },
    },
    computed: {
        outstandingPaystubsCount(){
            if(!this.outstandingPaystubs || !Object.keys(this.outstandingPaystubs).length)
                return 0;
            return Object.values(this.outstandingPaystubs as any).reduce((a: (any), b: (any)) => (parseInt(a) + parseInt(b)));
        },
        outstandingCustomerInvoicesCount() {
            if (!this.outstandingCustomerInvoices || !Object.keys(this.outstandingCustomerInvoices).length)
                return 0;
            return Object.values(this.outstandingCustomerInvoices as any).reduce((a: (any), b: (any)) => (parseInt(a) + parseInt(b)));
        }
    }
});
