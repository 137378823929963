


































































































































































































































































































































































import { defineComponent, ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { useProfessionProvider } from '@/components/services/profession-provider.service';
import { useCountryProvider } from '@/components/services/country-provider.service';
import { useExpirationCalculator } from '@/components/services/expiration-calculator.service';
import { useContractRequirementsCheck, provideContractRequirementsCheck } from '@/components/services/contract-requirements-check.service';
import EditAddress from '@/views/users/EditAddress.vue';
import EditPersonalData from '@/views/users/EditPersonalData.vue';
import EditQualification from '@/views/users/EditQualification.vue';
import AddASV from '@/views/users/AddASV.vue';
import ContractRequirementsFailed from '@/views/users/ContractRequirementsFailed.vue';
import Contracts from '@/views/users/Contracts.vue';
import AddAvailability from '@/views/users/AddAvailabilty.vue';
import Availabilities from '@/views/users/Availabilities.vue';
import UploadJobApplication from '@/views/users/UploadJobApplication.vue';
import DownloadJobApplication from '@/views/users/DownloadJobApplication.vue';
import DeleteUser from '@/views/users/DeleteUser.vue';

declare const jQuery: any;

export default defineComponent({
    name: 'user',
    components: {
        'edit-address': EditAddress,
        'edit-personal-data': EditPersonalData,
        'edit-qualification': EditQualification,
        'add-asv': AddASV,
        'contracts': Contracts,
        'contract-requirements-failed': ContractRequirementsFailed,
        'add-availability': AddAvailability,
        'availabilities': Availabilities,
        'upload-job-application': UploadJobApplication,
        'download-job-application': DownloadJobApplication,
        'delete-user': DeleteUser,
    },
    setup() {
        provideContractRequirementsCheck();

        const http = ref();
        http.value = useHttp();
        const expirationCalculator = useExpirationCalculator();
        const professionProvider = useProfessionProvider();
        const countryProvider = useCountryProvider();
        const contractRequirementsCheck = useContractRequirementsCheck();
        return { professionProvider, countryProvider, http, contractRequirementsCheck, expirationCalculator };
    },
    data() {
        return {
            status: null,
            isLoading: false,
            createContracts: false,
            user: { address: {}, availabilities: [], asvs: []},
            address: {},
            professionCategoryMap: {},
            professionsMap: {},
            countryMap: {},
            specializationsMap: {},
            professionLevel: null,
            professionFullName: null,
            queryParams: null,
        }
    },
    mounted() {
        this.getUser().then(this.handleUrlQuery);
        this.getProfessionsMap();
        this.getSpecializationsMap();
        this.getCountryMap();
    },
    watch: {
        'user.professionCategoryId'(v) {
            this.setProfessionFullName(v, this.user.professionLevel);
        },
        'user.professionLevel'(v) {
            this.setProfessionFullName(this.user.professionCategoryId, v);
        },
    },
    computed: {
        isNewAsvNeeded() {
            if (this.user.employed) {
                return false;
            }
            for (const asv of this.user.asvs) {
                if (!asv.contractSigned) {
                    return false;
                }
            }
            return true;
        },
        employmentEnd() {
            let employmentEnd = null;
            for (const asv of this.user.asvs) {
                if (asv.contractEnded && new Date(asv.contractEnded) > employmentEnd) {
                    employmentEnd = new Date(asv.contractEnded);
                } else if (asv.end && new Date(asv.end) > employmentEnd) {
                    employmentEnd = new Date(asv.end);
                }

                for (const asvExtension of asv.asvExtensions) {
                    if (asvExtension.contractEnded && new Date(asvExtension.contractEnded) > employmentEnd) {
                        employmentEnd = new Date(asvExtension.contractEnded);
                    } else if (new Date(asvExtension.contractExtensionDate) > employmentEnd) {
                        employmentEnd = new Date(asvExtension.contractExtensionDate);
                    }
                }
            }
            return employmentEnd;
        }
    },
    methods: {
        handleUrlQuery(){
            if(!this.$route.query)
                return;

            const customerId = parseInt(this.$route.query.customer);
            const cpId = parseInt(this.$route.query.contactPerson);
            this.$router.push(this.$route.path);

            if(isNaN(customerId) || isNaN(cpId))
                return;

            if(this.isNewAsvNeeded){
                this.openAsvModal();
                return
            }

            this.queryParams = {
                customerId: customerId,
                contactPersonId: cpId
            };
        },
        async getUser() {
            this.isLoading = true;
            return await this.http.get('/user/' + this.$route.params.userId).then((response) => {
                this.status = 200;
                this.user = response.data['user'];
                this.address = this.user.address;
                this.contractRequirementsCheck.setUser(this.user);
                this.isLoading = false;

                setTimeout(() => {
                    this.createContracts = true;
                });
            }).catch((error: any) => {
                console.log(error);
                this.status = error.response.status;
                this.isLoading = false;
            });
        },
        onAsvAdded({ asv, employeeNumber }) {
            this.user.asvs.push(asv);
            this.user.employeeNumber = employeeNumber;
        },
        getProfessionsList(list) {
            const arr = [];
            for (const key of list) {
                this.professionsMap[key] ? arr.push(this.professionsMap[key]['name']) : arr.push(key);
            }
            return arr.join(', ');
        },
        getSpecializationsList(list) {
            const arr = [];
            for (const key of list) {
                this.specializationsMap[key] ? arr.push(this.specializationsMap[key]['name']) : arr.push(key);
            }
            return arr.join(', ');
        },
        onContractAdded({ asv, list, contract }) {
            for (const _asv of this.user.asvs) {
                if (_asv === asv) {
                    asv[list].push(contract);
                    break;
                }
            }
        },
        onContractDeleted({ asv, list, contract }) {
            for (const _asv of this.user.asvs) {
                if (_asv === asv) {
                    let index = 0;
                    for (const _contract of asv[list]) {
                        if (contract === _contract) {
                            asv[list].splice(index, 1);
                            break;
                        }
                        index++;
                    }
                }
            }
        },
        onAsvDeleted(asv) {
            let index = 0;
            for (const _asv of this.user.asvs) {
                if (_asv === asv) {
                    this.user.asvs.splice(index, 1);
                    break;
                }
                index++;
            }
        },
        onEmploymentPotentiallyChanged({ isEmployed }) {
            this.user.employed = isEmployed;
        },
        onAvailabilityAdded(availability) {
            this.user.availabilities.push(availability);
        },
        onJobApplicationUploaded(profilePdfDate) {
            this.user.profilePdfDate = profilePdfDate;
        },
        openAsvModal() {
            const modalId = this.contractRequirementsCheck.requirementsSatisfied() ?
                'modalAddASV' : 'modalFailedRequirements';
            jQuery('#' + modalId).modal('show');
        },
        async getProfessionCategoryMap() {
            this.professionCategoryMap = await this.professionProvider.getProfessionCategoryMap();
        },
        async getProfessionsMap() {
            this.professionsMap = await this.professionProvider.getProfessionsMap();
        },
        async getSpecializationsMap() {
            this.specializationsMap = await this.professionProvider.getSpecializationsMap();
        },
        async getCountryMap() {
            this.countryMap = await this.countryProvider.getCountryMap();
        },
        async setProfessionFullName(id, levelId) {
            const category = await this.professionProvider.getProfessionCategoryById(id);
            const level = await this.professionProvider.getProfessionLevelById(levelId);

            let professionFullName = category ? category['name'] : '';
            professionFullName+= level ? ' (' +  level['name'] + ')' : '';
            this.professionFullName = professionFullName;
        },
    },
});
