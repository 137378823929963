







































































import { ref } from '@vue/composition-api';

import { required, email, integer } from 'vuelidate/lib/validators';

import { useInputSuggestions } from '@/components/services/input-suggestions.service';
import { genderTitlesAsOptions } from '@/components/enums/gender_titles';
import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';

declare const jQuery: any;

function initialState() {
    return {
        form: {
            title: null,
            additionalTitle: null,
            firstName: null,
            lastName: null,
            responsibility: null,
            email: null,
            telephone: null,
            mobile: null,
        },
        honoraryTitles: [],
        genderTitles: genderTitlesAsOptions,
        isLoading: false,
    }
}

export default {
    name: 'AddContactPerson',
    setup() {
        const inputSuggestions = useInputSuggestions();
        const http = ref();
        http.value = useHttp();
        return { inputSuggestions, http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            const data = initialState();
            this.$data.form = data.form;
        });
        this.getHonoraryTitles();
    },
    props: {
        modalId: null,
        customerId: null,
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                title: { required },
                firstName: { required },
                lastName: { required },
                responsibility: { required },
                email: { required, email },
                telephone: { integer },
                mobile: { integer },
            }
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/customer/contact-person/add/' + this.customerId, this.$data.form).then((response) => {
                notifier.displaySuccess('Hinzugefügt', 'Kontaktperson wurde hinzugefügt.');
                this.modal.modal('hide');
                this.isLoading = false;

                const contactPerson = { id: response.data['id'], ... this.$data.form };
                this.$emit('added', contactPerson);
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        integerError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].integer) return ['Nur Zahlen erlaubt.'];
            return [];
        },
        emailAndRequiredError(prop) {
            const errors = [];
            if (!this.$v['form'][prop].$dirty) return errors;
            !this.$v['form'][prop].required && errors.push('Bitte ausfüllen.');
            !this.$v['form'][prop].email && errors.push('Keine E-Mail');
            return errors;
        },
        async getHonoraryTitles() {
            this.honoraryTitles = await this.inputSuggestions.getHonoraryTitles();
        },
    }
}

