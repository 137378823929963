














import { ref } from '@vue/composition-api';

import { getNotifier } from '@/helpers';

import { useHttp } from '@/components/services/http.service';

export default {
    name: 'DownloadJobApplication',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    props: {
        userId: null,
    },
    methods: {
        download: function() {

            const notifier = getNotifier();

            this.http.get('/user/job-application/download/' + this.userId + '?timestamp=' + new Date().getTime(),
                { responseType: 'blob' }).then((response) => {

                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);

                const headerLine = response.headers['content-disposition'].replaceAll('"', '');
                const filename = headerLine.substring(headerLine.indexOf('=') + 1, headerLine.length);

                const link = document.createElement('a');
                link.href = fileURL;
                link.download = filename;
                link.click();
                URL.revokeObjectURL(fileURL);

            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
};
