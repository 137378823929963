import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import {getNotifier} from '@/helpers';
import {getUser, logout} from '@/components/services/auth.service';
import BasicLayout from '@/components/common/layouts/BasicLayout.vue';
import BlankLayout from '@/components/common/layouts/BlankLayout.vue';
import Login from '@/views/starterviews/Login.vue';
import Dashboard from '@/views/starterviews/Dashboard.vue';
import PageNotFound from '@/views/errors/PageNotFound.vue';
import Customer from '@/views/customers/Customer.vue';
import CustomersOverview from '@/views/customers/CustomersOverview.vue';
import UsersOverview from '@/views/users/UsersOverview.vue';
import OffersOverview from "@/views/offers/OffersOverview.vue";
import Offer from "@/views/offers/Offer.vue";
import AddASV from '@/views/users/AddASV.vue';
import User from '@/views/users/User.vue';
import HourRecording from '@/views/billing/HourRecording.vue';
import PaystubsOverview from "@/views/billing/PaystubsOverview.vue";
import PaystubOverview from "@/views/billing/PaystubOverview.vue";
import CustomerInvoicesOverview from "@/views/billing/CustomerInvoicesOverview.vue";
import CustomerInvoiceOverview from "@/views/billing/CustomerInvoiceOverview.vue";
import FollowupContracts from "@/views/controlling/FollowupContracts.vue";

declare const jQuery: any;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: BasicLayout,
        children:  [
            {
                name: 'dashboard',
                path: '',
                component: Dashboard,
            }
        ]
    },
    {
        path: '/',
        component: BlankLayout,
        children:  [
            {
                name: 'login',
                path: 'login',
                component: Login,
            }
        ]
    },
    {
        path: '/customers',
        component: BasicLayout,
        children:  [
            {
                name: 'customers',
                path: '',
                component: CustomersOverview,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
            {
                name: 'customer',
                path: 'customer/:customerId',
                component: Customer,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
        ],
    },
    {
        path: '/users/:todo?',
        component: BasicLayout,
        children:  [
            {
                name: 'users',
                path: '',
                component: UsersOverview,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
            {
                name: 'user',
                path: 'user/:userId',
                component: User,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
            {
                name: 'addContract',
                path: 'user/:userId/add-asv',
                component: AddASV,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
        ],
    },
    {
        path: '/offers',
        component: BasicLayout,
        children:  [
            {
                name: 'offers',
                path: '',
                component: OffersOverview,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
            {
                name: 'offer',
                path: 'offer/:offerId',
                component: Offer,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
        ],
    },
    {
        path: '/monitoring',
        name: 'monitoring',
        meta: {
            expectedRole: 'ROLE_USER',
        },
        component: BasicLayout,
        children:  [
            {
                name: 'followUpContracts',
                path: 'follow-up-contracts',
                component: FollowupContracts,
            },
        ],
    },
    {
        path: '/billing',
        name: 'billing',
        meta: {
            expectedRole: 'ROLE_USER',
        },
        component: BasicLayout,
        children:  [
            {
                name: 'hourRecording',
                path: 'hour-recording',
                component: HourRecording,
            },
            {
                name: 'customerInvoices',
                path: 'customer-invoices',
                component: CustomerInvoicesOverview,
                props: (r) => r.query
            },
            {
                name: 'customerInvoice',
                path: 'customer-invoices/customer-invoice/:scId/:month/:year',
                component: CustomerInvoiceOverview,
                props: true
            },
            {
                name: 'paystubsOverview',
                path: 'paystubs',
                component: PaystubsOverview,
            },
            {
                name: 'paystub',
                path: 'paystubs/paystub/:employeeNumber/:month-:year',
                component: PaystubOverview,
                meta: {
                    expectedRole: 'ROLE_USER',
                },
            },
        ],
    },
    {
        path: '/',
        name: '404',
        component: BasicLayout,
        children:  [
            {
                name: 'notFound',
                path: '/:pathMatch(.*)*',
                component: PageNotFound,
            }
        ],
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    let isAuthenticated = false;

    const user = getUser();
    if (user !== null) {
        const expectedRole = to.meta['expectedRole'];
        isAuthenticated = !expectedRole || user.hasRole(expectedRole);
    }

    if (to.name !== 'login' && !isAuthenticated) {
        getNotifier().displayError('Zugriff verweigert', 'Keine Zugriffsrechte für Resource: ' + to.path);
        logout(to.path);
    }
    else next();
});

router.afterEach(() => {
    setTimeout(() => {
        jQuery('#side-menu > li:not(.active) > ul').collapse('hide');
        jQuery('#side-menu > li.active ul').collapse('show');
    }, 0);
    Vue.nextTick(() => {
        document.title = "Lumis Backoffice";
    });
});

export default router;
