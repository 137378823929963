













import { defineComponent, ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';

export default defineComponent({
    name: 'FilesystemLogs',

    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    props: {
        logContainerHeight: {
            type: Number,
            default: 300,
        }
    },
    data() {
        return {
            isLoading: false,
            logs: null
        }
    },
    mounted() {
        this.getFilesystemLogs();
    },
    methods: {
        getFilesystemLogs() {
            this.isLoading = true;
            const notifier = getNotifier();
            this.http.get('/filesystem-logs').then((response) => {
                this.logs = response.data['data'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.isLoading = false;
                notifier.displayServerError(error.message);
            });
        },
    },
    computed: {
        heightPx: function() {
            return this.logContainerHeight + 'px';
        }
    }
});
