














































import { ref, defineComponent } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';

import FilesystemLogs from "@/views/starterviews/FilesystemLogs.vue";

export default defineComponent({
    name: 'Dashboard',
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    components: {
        'filesystem-logs': FilesystemLogs
    },
    data() {
        return {
            asvStatistics: {
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                stepSize: 1
                            }
                        }]
                    }
                }
            },
            isLoading: true,
            swalDelete: ['Löschen?', 'Das kann nicht rückgängig gemacht werden.'],
        }
    },
    mounted(){
        this.notifier = getNotifier();
        this.getAsvStatistics();
    },
    methods: {
        async getAsvStatistics(){
            this.isLoading = true;
            return await this.http.get('/asv-statistics').then((response) => {
                const data = response.data['data'];

                this.asvStatistics.data.labels = this.$moment.months();

                const yearsToContractCountByMonth = {};
                let fdate, year, month, color;
                for (const [date, count] of Object.entries(data)){
                    fdate = this.$moment(date, 'YYYY-MM', true);
                    year = fdate.format('YYYY');
                    month = parseInt(fdate.format('M')) - 1;
                    if(!yearsToContractCountByMonth[year]){
                        yearsToContractCountByMonth[year] = new Array(12);
                    }
                    yearsToContractCountByMonth[year][month] = count;
                }

                const size = Object.keys(yearsToContractCountByMonth).length;
                this.asvStatistics.data.datasets = [];
                for(const [i, [year, countPerMonth]] of Object.entries(yearsToContractCountByMonth).entries()){
                    color = 'HSL('+ Math.round(360 - (360 * (i/size))).toString() +', 60%, 50%)';
                    this.asvStatistics.data.datasets.push({
                        label: year,
                        data: countPerMonth,
                        backgroundColor: color
                    });
                }

                this.isLoading = false;
            }).catch((error: any) => {
                this.isLoading = false;
                this.notifier.displayServerError(error.message);
            });
        },
    }
});
