var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar-default navbar-static-side",attrs:{"role":"navigation"}},[_c('div',{staticClass:"sidebar-collapse"},[_c('ul',{staticClass:"nav",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"nav-header"},[_c('div',{staticClass:"dropdown profile-element"},[_c('img',{staticClass:"img-circle",attrs:{"alt":"image","height":"50","width":"50","src":'/images/' + _vm.user.profileImage}}),_c('a',{staticClass:"dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"block m-t-xs font-bold"},[_vm._v(_vm._s(_vm.user.name))]),_vm._m(0)]),_c('ul',{staticClass:"dropdown-menu animated fadeInRight m-t-xs"},[_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_vm._v("Ausloggen")])])])]),_c('div',{staticClass:"logo-element"},[_vm._v(" IN+ ")])]),_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fa fa-bar-chart-o"}),_vm._v(" "),_c('span',{staticClass:"nav-label"},[_vm._v("Dashboard")])])])]}}])}),(_vm.hasRole('ROLE_USER'))?_c('router-link',{attrs:{"to":"/customers","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fa fa-hospital-o"}),_vm._v(" "),_c('span',{staticClass:"nav-label"},[_vm._v("Kunden")])])])]}}],null,false,3006957517)}):_vm._e(),(_vm.hasRole('ROLE_USER'))?_c('router-link',{attrs:{"to":"/users","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fa fa-user-md"}),_vm._v(" "),_c('span',{staticClass:"nav-label"},[_vm._v("Fachkräfte")])])])]}}],null,false,2508066263)}):_vm._e(),(_vm.hasRole('ROLE_USER'))?_c('router-link',{attrs:{"to":"/offers","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fa fa-handshake-o"}),_vm._v(" "),_c('span',{staticClass:"nav-label"},[_vm._v("Angebote")])])])]}}],null,false,3292954432)}):_vm._e(),(_vm.hasRole('ROLE_USER'))?_c('li',{class:[_vm.isActive('/monitoring') && 'active']},[_vm._m(1),_c('ul',{staticClass:"collapse nav nav-second-level",attrs:{"id":"collapse1"}},[_c('router-link',{attrs:{"to":"/monitoring/follow-up-contracts","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Folgeaufträge")])])]}}],null,false,790948425)})],1)]):_vm._e(),(_vm.hasRole('ROLE_USER'))?_c('li',{class:[_vm.isActive('/billing') && 'active']},[_vm._m(2),_c('ul',{staticClass:"collapse nav nav-second-level",attrs:{"id":"collapse2"}},[_c('router-link',{attrs:{"to":"/billing/hour-recording","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Stundenerfassung")])])]}}],null,false,2645013027)}),_c('router-link',{attrs:{"to":"/billing/customer-invoices","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Rechnung an Kunden")])])]}}],null,false,3909776418)}),_c('router-link',{attrs:{"to":"/billing/paystubs","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Lohnabrechnung")])])]}}],null,false,1759191540)})],1)]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"text-muted text-xs block"},[_vm._v("Menü "),_c('b',{staticClass:"caret"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#","onclick":"return false;","data-toggle":"collapse","data-target":"#collapse1"}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" "),_c('span',{staticClass:"nav-label"},[_vm._v("Controlling")]),_c('span',{staticClass:"fa arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#","onclick":"return false;","data-toggle":"collapse","data-target":"#collapse2"}},[_c('i',{staticClass:"fa fa-calculator"}),_vm._v(" "),_c('span',{staticClass:"nav-label"},[_vm._v("Abrechnung")]),_c('span',{staticClass:"fa arrow"})])}]

export { render, staticRenderFns }