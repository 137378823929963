





































































import { ref } from '@vue/composition-api';

import { required } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import { useOpenTasksService } from '@/components/services/open-tasks.service';

function initialState() {
    return {
        modal: null,
        isLoading: false,
        menuContractEndedDate: false,
        formattedContractEndedDate: null,
        form: {
            contractEnded: null,
            contractEndedReason: null,
        }
    }
}

declare const jQuery: any;

export default {
    name: 'TerminateContract',
    setup() {
        const http = ref();
        http.value = useHttp();
        const openTasksService = useOpenTasksService();
        return { http, openTasksService };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            const data = initialState();
            Object.assign(this.form, data.form);
            this.formattedContractEndedDate = this.formatDate(this.$data.form.contractExtensionDate);
        });
    },
    props: {
        modalId: null,
        contract: null,
        maxEndDate: null,
    },
    data() {
        return initialState();
    },
    validations () {
        return {
            form: {
                contractEnded: {
                    required,
                    maxEndDate(v) {
                        if (!this.maxEndDate || !required(v)) {
                            return true;
                        }
                        return new Date(v) <= new Date(this.maxEndDate);
                    }
                },
                contractEndedReason: { required },
            },
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/user/contract/terminate/' + this.contract['contractType'] + '/' + this.contract['id'], this.form).then((response) => {
                this.isLoading = false;
                notifier.displaySuccess('Gekündigt', 'Der Vertrag wurde als gekündigt markiert.');
                Object.assign(this.contract, this.form);
                if (this.contract['contractType'] === 'aul') {
                    Object.assign(this.contract.zv, this.form);
                }
                this.modal.modal('hide');
                this.$emit('terminated', response.data['isEmployed']);
                this.openTasksService.pollOpenTasks();
            }).catch((error: any) => {
                this.isLoading = false;
                notifier.displayServerError(error.message);
            });
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        contractEndedError(prop) {
            const errors = this.requiredError(prop);
            if (!this.$v['form'][prop].maxEndDate ) {
                const maxEndDateFormatted = this.$moment(this.maxEndDate).format('DD.MM.YYYY');
                errors.push(`Darf nicht nach dem Vertragsende (${maxEndDateFormatted}) liegen.`);
            }
            return errors
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
    }
};
