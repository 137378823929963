

























































































































































































import {defineComponent, ref} from '@vue/composition-api';

import {useHttp} from '@/components/services/http.service';
import {getNotifier} from '@/helpers';
import EditOfferCover from '@/views/offers/EditOfferCover.vue';
import EditOfferContent from '@/views/offers/EditOfferContent.vue';
import GroupedUsers from '@/views/offers/GroupedUsers.vue';
import MailingPoolTable from '@/views/offers/MailingPoolTable.vue';

export default defineComponent({
    name: 'offer',
    components: {
        'edit-offer-cover': EditOfferCover,
        'edit-offer-content': EditOfferContent,
        'grouped-users': GroupedUsers,
        'mailing-pool-table': MailingPoolTable,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return {http};
    },
    data() {
        return {
            isLoading: false,
            offer: {
                cover: {
                    application: null,
                    utilizationTimeShort: null,
                    professionCategory: {name: ""},
                    agentUser: {name: ""},
                    customer: {name: ""},
                    contactPerson: {name: ""},
                },
                content: {
                    tasksText: null,
                    qualificationText: null,
                    utilizationTimeLong: null,
                    termsText: null,
                    benefitsText: null,
                    specificsText: null
                },
                title: "",
                id: null,
            },
            status: null,
            hasApplicants: null,
            swalDelete: ['Angebot löschen?', 'Das kann nicht rückgängig gemacht werden.'],
        }
    },
    mounted() {
        this.getOffer();
    },
    methods: {
        getOffer() {
            this.isLoading = true;
            const notifier = getNotifier();
            this.http.get('/offers/' + this.$route.params.offerId).then((response) => {
                for (const key of Object.keys(this.offer.cover)) {
                    this.offer.cover[key] = response.data.offer[key];
                }
                for (const key of Object.keys(this.offer.content)) {
                    this.offer.content[key] = response.data.offer[key];
                }
                this.offer.title = response.data.offer.title;
                this.offer.id = response.data.offer.id;
                this.offer.cover.id = response.data.offer.id;
                this.offer.content.id = response.data.offer.id;
                this.hasApplicants = parseInt(response.data.openApplicationCount) > 0;
                this.isLoading = false;
                this.status = response.status;
            }).catch((error: any) => {
                this.isLoading = false;
                this.status = error.response.status;
                notifier.displayServerError(error.message);
            })
        },
        deleteOffer(){
            const notifier = getNotifier();
            this.isLoading = true;
            this.http.post('/offers/'+ this.$route.params.offerId +'/delete').then(() => {
                this.isLoading = false;
                this.$emit('deleted');
                this.status = -1;
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
        onGroupedTablesUpdated(tablesRows){
            this.hasApplicants = tablesRows['applicants'] > 0;
        }
    },
});
