import { provide, inject } from '@vue/composition-api';

import {interval} from 'rxjs';
import {filter, startWith, mergeMap} from 'rxjs/operators';

import {useAuth} from "@/components/services/auth.service";
import {useHttp} from "@/components/services/http.service";

import { Subject } from 'rxjs';

const createOutstandingPaystubsService = () => {

    const POLLING_FREQUENCY = 100000 ;

    const auth: any = useAuth();
    const http: any = useHttp();

    const outstandingPaystubs$ = new Subject();

    interval(POLLING_FREQUENCY).pipe(
        startWith(0),
        filter(() => auth.getUser() !== null),
        mergeMap(() => {
            return http.get('/outstanding-paystubs');
        }))
        .subscribe((response: any) => {
            outstandingPaystubs$.next(response.data);
        });

    return {
        getOutstandingPaystubs() {
            return outstandingPaystubs$;
        },
        pollOutstandingPaystubs() {
            http.get('/outstanding-paystubs').then((response) => {
                outstandingPaystubs$.next(response.data);
            });
        }
    }
};

const outstandingPaystubsSymbol = Symbol();

export function provideOutstandingPaystubsService() {
    const openOutstandingPaystubs = createOutstandingPaystubsService();
    provide(outstandingPaystubsSymbol, openOutstandingPaystubs);
}

export function useOutstandingPaystubsService() {
    const openOutstandingPaystubs = inject(outstandingPaystubsSymbol);
    if (!openOutstandingPaystubs) throw new Error("No outstanding paystubs service provided!!!");

    return openOutstandingPaystubs;
}