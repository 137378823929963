<template>
    <router-view></router-view>
</template>

<script>
    import Vue from 'vue';

    import { provideHttp } from './components/services/http.service';
    import { provideExpirationCalculator } from './components/services/expiration-calculator.service';
    import { provideOpenTasksService } from './components/services/open-tasks.service';
    import { provideOutstandingPaystubsService } from './components/services/outstanding-paystubs.service';
    import { provideOutstandingCustomerInvoicesService } from './components/services/outstanding-customer-invoices.service';
    import { provideAuth, useAuth } from './components/services/auth.service';
    import { provideProfessionProvider, useProfessionProvider } from '@/components/services/profession-provider.service';
    import { provideCountryProvider, useCountryProvider } from '@/components/services/country-provider.service';
    import { provideInputSuggestions, useInputSuggestions } from '@/components/services/input-suggestions.service.ts';

    export default Vue.extend({
        setup() {
            provideHttp();
            provideAuth();
            provideProfessionProvider();
            provideCountryProvider();
            provideInputSuggestions();
            provideExpirationCalculator();
            provideOpenTasksService();
            provideOutstandingPaystubsService();
            provideOutstandingCustomerInvoicesService();

            const auth = useAuth();
            if (auth.getUser()) {
                const professionProvider = useProfessionProvider();
                professionProvider.load();
                const countryProvider = useCountryProvider();
                countryProvider.load();
                const inputSuggestions = useInputSuggestions();
                inputSuggestions.load();
            }
        },
    });
</script>

