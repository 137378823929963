













import {ref} from '@vue/composition-api';

import {useHttp} from '@/components/services/http.service';
import {getNotifier} from '@/helpers';

export default {
    name: 'DeleteContactPerson',
    setup() {
        const http = ref();
        http.value = useHttp();
        return {http};
    },
    props: {
        contactPersonId: null,
    },
    data() {
        return {
            swalDelete: ['Löschen?', 'Das kann nicht rückgängig gemacht werden.'],
            isLoading: false,
        }
    },
    methods: {
        submit: function () {
            const notifier = getNotifier();
            this.isLoading = true;

            this.http.post('/customer/contact-person/delete/' + this.contactPersonId).then(() => {
                notifier.displaySuccess('Entfernt', 'Die Kontaktperson wurde entfernt.');
                this.isLoading = false;
                this.$emit('deleted');
            }).catch((error: any) => {
                notifier.displayServerError(error.message);
                this.isLoading = false;
            });
        },
    }
}

