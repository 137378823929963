























































































































































































































































import {useProfessionProvider} from '@/components/services/profession-provider.service';

export default {
    name: 'ContractDetails',
    setup() {
        const professionProvider = useProfessionProvider();
        return { professionProvider };
    },
    mounted() {
        this.getProfessionCategoryMap();
    },
    data() {
        return {
            professionCategoryMap: [],
        }
    },
    props: {
        contract: null,
    },
    methods: {
        async getProfessionCategoryMap() {
            this.professionCategoryMap = await this.professionProvider.getProfessionCategoryMap();
        },
    }
};
