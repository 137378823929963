






























































import { ref } from '@vue/composition-api';

import { required, integer } from 'vuelidate/lib/validators';

import { getNotifier } from '@/helpers';
import { useHttp } from '@/components/services/http.service';
import AdminPrivilegeNotification from '@/views/users/AdminPrivilegeNotification.vue';

function initialState() {
    return {
        modal: null,
        isLoading: false,
        menuBegin: false,
        formattedBegin: null,
        form: {
            begin: null,
            hoursPerWeek: null,
        }
    }
}

declare const jQuery: any;

export default {
    name: 'AddASVAmendment',
    components: {
        'admin-privilege-notification': AdminPrivilegeNotification,
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    mounted() {
        this.modal = jQuery('#' + this.modalId);
        this.modal.on('hidden.bs.modal', () => {
            this.$v.$reset();
        });
        this.modal.on('shown.bs.modal', () => {
            Object.assign(this.$data, initialState());
            if (this.asvAmendment) {
                this.form = { ... this.asvAmendment };
                this.formattedBegin = this.formatDate(this.form.begin);
            }
            this.modal = jQuery('#' + this.modalId);
        });
    },
    props: {
        modalId: null,
        asv: null,
        asvAmendment: null,
    },
    data() {
        return initialState();
    },
    computed: {
        curHoursPerWeek() {
            if (this.asv.asvAmendments && this.asv.asvAmendments.length > 0) {
                return this.asv.asvAmendments[this.asv.asvAmendments.length - 1].hoursPerWeek;
            }
            return this.asv.weeklyWorkingHours;
        }
    },
    validations () {
        return {
            form: {
                begin: { required },
                hoursPerWeek: { required, integer }
            },
        }
    },
    methods: {
        submit: function() {
            this.$v.$touch();
            if(this.$v.$error) return;

            const notifier = getNotifier();
            this.isLoading = true;

            if (this.asvAmendment) {
                this.http.post('/user/contract/edit/asv-amendment/' + this.asvAmendment.id, this.$data.form).then(() => {
                    Object.assign(this.asvAmendment, this.$data.form);
                    notifier.displaySuccess('Editiert', 'Änderungsvertrag zum ASV wurde editiert.');
                    this.modal.modal('hide');
                    this.isLoading = false;
                    this.$emit('edited');
                }).catch((error: any) => {
                    notifier.displayServerError(error.message);
                    this.isLoading = false;
                });
            } else {
                this.http.post('/user/contract/add/asv-amendment/' + this.asv.id, this.$data.form).then((response) => {
                    notifier.displaySuccess('Erstellt', 'Änderungsvertrag zum ASV wurde erstellt.');
                    this.modal.modal('hide');
                    this.isLoading = false;
                    this.$emit('added', response.data['asvAmendment']);
                }).catch((error: any) => {
                    notifier.displayServerError(error.message);
                    this.isLoading = false;
                });
            }
        },
        formatDate(value: any) {
            if (value) {
                value = value.split('-');
                return value[2] + '.' + value[1] + '.' + value[0];
            }
            return null;
        },
        requiredError(prop) {
            if (this.$v['form'][prop].$dirty && !this.$v['form'][prop].required) return ['Bitte ausfüllen.'];
            return [];
        },
        integerAndRequiredError(prop) {
            const errors = [];
            if (!this.$v['form'][prop].$dirty) return errors;
            !this.$v['form'][prop].required && errors.push('Bitte ausfüllen.');
            !this.$v['form'][prop].integer && errors.push('Bitte eine ganze Zahl eingeben.');
            return errors;
        },
    }
};
