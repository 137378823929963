





































































































































































































































import { defineComponent, ref } from '@vue/composition-api';

import { useHttp } from '@/components/services/http.service';
import { getNotifier } from '@/helpers';
import EditAddress from '@/views/users/EditAddress.vue';
import EditClinicData from '@/views/customers/EditClinicData.vue';
import AddContactPerson from '@/views/customers/AddContactPerson.vue';
import ContactPersons from '@/views/customers/ContactPersons.vue';
import BlacklistUsers from '@/views/customers/BlacklistUsers.vue';
import AddBlacklistUser from "@/views/customers/AddBlacklistUser.vue";

export default defineComponent({
    name: 'user',
    components: {
        'edit-address': EditAddress,
        'edit-clinic-data': EditClinicData,
        'add-contact-person': AddContactPerson,
        'contact-persons': ContactPersons,
        'blacklist-users': BlacklistUsers,
        'add-blacklist-user': AddBlacklistUser
    },
    setup() {
        const http = ref();
        http.value = useHttp();
        return { http };
    },
    data() {
        return {
            isLoading: false,
            customer: {
                address: {},
                invoiceRecipientAddress: {},
                contractRecipientAddress: {},
                contactPersons: [],
                blacklistUsers: []
            },
        }
    },
    mounted() {
        this.getCustomer();
    },
    methods: {
        getCustomer() {
            this.isLoading = true;
            const notifier = getNotifier();
            this.http.get('/customer/' + this.$route.params.customerId).then((response) => {
                this.customer = response.data['customer'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.isLoading = false;
                notifier.displayServerError(error.message);
            });
        },
        onContactPersonAdded(contactPerson: any) {
            this.customer.contactPersons.push(contactPerson);
        },
        onBlacklistUserAdded (blacklistUser: any) {
            this.customer.blacklistUsers.push(blacklistUser);
        },
        onUpdateContactPerson () {
            this.isLoading = true;
            const notifier = getNotifier();
            this.http.get('/customer/contact-persons/' + this.$route.params.customerId).then((response) => {
                this.customer.contactPersons = response.data['contactPersons'];
                this.isLoading = false;
            }).catch((error: any) => {
                this.isLoading = false;
                notifier.displayServerError(error.message);
            });
        }
    },
});
